import React, { useEffect } from "react";
import { TableChangeState } from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router";
import SearchListTable from "../SearchListTable";
import { useDispatch } from "react-redux";
import BackArrowSvgIcon from "../svg-icons/BackArrow";
import { Col, Modal, Row } from "react-bootstrap";
import SearchDetails from "../../views/SearchDetails";
import { setReRunSearch } from "../../../infrastructure/redux/actions/reRunSearch";
import useUserSearchListViewModel from "../../view-model/search/UserSearchListViewModel_new";


const UserSearchRequests: React.FC = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const search = useLocation().search;
    const navigate = useNavigate();

    const {
        pageSize,
        pageNumber,
        totalCount,
        pageData,
        isLoading,
        isShowError,
        errorMessages,
        showAddModal,
        name,
        searchDetails,

        openAddModal,
        closeAddModal,
        init,
        onSearchChanged,
        getPage,
        updateSorting,
        setPageSize,
        getSearchDetails,
        setIsShowError,
        setUserId,
        setPageNumber
    } = useUserSearchListViewModel();

    useEffect(() => {
        const urlPageNumber = new URLSearchParams(search).get("pg") || "";
        setUserId(id);
        init(id ?? '');
        let page = parseInt(urlPageNumber.toString()) || 1;
        getPage(--page, pageSize, id);
    }, []);

    useEffect(() => { dispatch(setReRunSearch(searchDetails as unknown as ReRunSearchState)) }, [searchDetails]);

    const onTableChange = (type: any, newState: TableChangeState<any>) => {
        if (type === "pagination") {
            setPageNumber(--newState.page);
            setPageSize(newState.sizePerPage);
            getPage(newState.page, newState.sizePerPage);
            let oldUrl =
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname;
            oldUrl += "?pg=" + ++newState.page;
            window.history.pushState({ path: oldUrl }, "", oldUrl);
        }
        else if (type === "sort") {
            updateSorting(newState.sortField, newState.sortOrder);
        }
    };

    const onPageSizeChange = (_pageSize: number, page: number) => {
        setPageSize(_pageSize);
        setPageNumber(--page);
        getPage(page, _pageSize);
    };
    
    const rerunSearch = async (id: number) => {
        await getSearchDetails(id);
        navigate('/');
    }

    return (
        <div className="container">
            <div className="admin-page-title"><a onClick={() => history.back()}> <BackArrowSvgIcon /></a> {name}&apos;s Search Requests</div>
            <SearchListTable
                resultsUrl={`/admin/user/${id}/search-request`}
                isLoading={isLoading}
                pageData={pageData}
                onOpenSearchDetails={(id) => openAddModal(id)}
                page={pageNumber + 1}
                sizePerPage={pageSize}
                totalSize={totalCount}
                onPageSizeChange={onPageSizeChange}
                onTableChange={onTableChange}
                onSearch={onSearchChanged}
                onRerunSearch={rerunSearch}
            />
            <Modal
                show={showAddModal}
                onHide={closeAddModal}
                size="lg"
                className="user-search-requests-details-modal">
                <SearchDetails
                    data={searchDetails}
                    onCloseModal={closeAddModal}
                    onRerunSearch={rerunSearch}
                />
            </Modal>
            <Modal show={isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setIsShowError(false)}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setIsShowError(false)} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UserSearchRequests;
