import { toast } from 'react-toastify';
import FormValidator from '../../utils/FormValidator';
import { useState } from 'react';
import AuthApi from '../../../data/auth/AuthApi';
import { HttpClient } from '../../../infrastructure/utils/fetchInterceptor';

function useForgetPasswordViewModel() {

    const [email, setEmail] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowError, setIsShowError] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [validation, setValidation] = useState<any>({});
    const [disable, setDisable] = useState<boolean>(true);

    const authRepository = new AuthApi(new HttpClient());

    const onEmailQueryChanged = (e: React.FormEvent): void => {
        const email = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        setEmail(email);
        validateForgetPasswordForm(email);
    }

    const onConfirm = async (): Promise<void> => {
        if (validation.email)
            return;

        try {
            setIsLoading(true);

            const result = await authRepository.forgetPassword(email);

            setIsLoading(false);
            setDisable(true);

            if (result.isSuccess)
                notifySuccess();
            else
                notifyError();
        } catch (e: any) {
            setIsLoading(false);
            setErrorMessages([...errorMessages, e.message]);
            setIsShowError(true);
        }
    };

    const notifySuccess = () => {
        toast.success("An email has been sent to you, please check your inbox to reset your password", {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            bodyClassName: "toast-message",
        });
    };

    const notifyError = () => {
        toast.error("We are unable to process your reset password request. Please contact your system administrator for help.", {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            bodyClassName: "toast-message",
        });
    };

    const validateForgetPasswordForm = (_email: string) => {
        setIsShowError(false);
        setErrorMessages([]);
        setValidation({});

        if (!_email?.replaceAll(' ', '')) {
            setValidation({ email: 'Email cannot be empty' });
            setDisable(true);
        }
        else if (!FormValidator.isValidEmail(_email)) {
            setValidation({ email: 'Email is not valid' });
            setDisable(true);
        }
        else {
            delete validation.email;
            setValidation(validation);
            setDisable(false);
        }
           
    }

    return {
        email,
        validation,
        isLoading,
        isSuccess,
        isShowError,
        errorMessages,
        disable,
        setIsShowError,
        onEmailQueryChanged,
        onConfirm
    };

}

export default useForgetPasswordViewModel;
