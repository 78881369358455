import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import Actions from "./Actions_new";
import BreadCrumb from "./BreadCrumb";
import PersonalInfo from './PersonalInfo_new';
import RequestDetails from './RequestDetails_new';
import { RootState } from "../../../../infrastructure/redux/reducer";
import { useSelector } from "react-redux";
import SearchResultModel from "../../../../domain/entities/searchoutput/SearchResultModel";


const Header: React.FC = () => {
    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    const HeaderModel = searchResult.headerModel;

    return (
        <Fragment>
            <Row className="p-0">
                <Col className="p-0"><BreadCrumb caseId={HeaderModel?.caseId} caseName={HeaderModel?.caseName} name={HeaderModel?.name} /></Col>
                <Col className="p-0 order-social-canvas-wrapper">
                    <a href="https://forms.intertelinc.com/" rel="noreferrer" target="_blank" className="submit-btn btn order-social-canvas">Order Social Canvass</a>
                </Col>
            </Row>
            <Row className="header bg-white box-shadow">
                <Col xs="auto">
                    <PersonalInfo />
                </Col>
                <Col>
                    <RequestDetails />
                </Col>
                <Col xs="auto">
                    <Actions />
                </Col>
            </Row>
        </Fragment>
    );
};

export default Header;
