import React, { useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import useVisibility from "../../../infrastructure/hooks/useVisibility";
import PhoneSvgIcon from "../svg-icons/PhoneSvgIcon";
import HideTabToggle from "./HideTabToggle";
import ResultCount from "./ResultCount";
import SearchResultModel from "../../../domain/entities/searchoutput/SearchResultModel";
import { RootState } from "../../../infrastructure/redux/reducer";


const Phones: React.FC = () => {

    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    const phones = searchResult?.phones?.map(p => p.phoneNumber);
    const dispatch = useDispatch();
    const { visibilityFlags, actions } = useVisibility();

    const handleToggle = () => {
        dispatch(actions.setPhonesVisibility(!visibilityFlags.isPhonesHidden));
    };

    useEffect(() => {
        if (searchResult?.dataFetched && searchResult?.phones?.length === 0)
            dispatch(actions.setPhonesVisibility(true));
    }, [searchResult?.phones]);

    return (
        <>
            <Row className="my-3 mx-2 result-header">
                <Col>
                    <ResultCount count={phones?.length ?? 0} text={searchResult?.isPrintView ? "Phones" : null} />
                </Col>
                <Col md="auto">
                    {!searchResult?.isPrintView && <HideTabToggle isHidden={phones?.length == 0 ? true : visibilityFlags.isPhonesHidden} onToggle={handleToggle} />}
                </Col>
            </Row>
            <hr />
            <Row className="phones p-0">
                {phones?.map(p =>
                    <Col className="col-4" key={Math.random()}>
                        <Row className="phone-card box-shadow bg-white">
                            <Col className="col-auto img-box mt-2">
                                <PhoneSvgIcon />
                            </Col>
                            <Col className="p-0 col-auto">{p}</Col>
                            <Col className="p-0 google-search">
                                <img src="/images/google.svg" className="m-0" />
                                <span className="title">Google Search:&nbsp;</span>
                                <a className="view p-0" target="_blank" rel="noreferrer" href={'https://www.google.com/search?q="' + p.replaceAll(/[()]/g, '').replace(' ', '-') + '"'}>View</a>
                            </Col>
                        </Row>
                    </Col>)}
            </Row>
        </>
    );
};

export default Phones;
