import Result from "../../../domain/common/Result";
import SearchListFiltrationModel from "../../../domain/entities/search/SearchListFiltrationModel";
import SearchListPaginationQuery from "../../../domain/entities/search/SearchListPaginationQuery";
import SearchDetailsModel from "../../../domain/entities/search/SearchDetailsModel";
import SearchStatus from "../../../domain/entities/search/enum/SearchStatus";
import React, { useEffect, useState } from "react";
import SearchApi from "../../../data/search/SearchApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import ListCaseModel from "../../../domain/entities/cases/ListCaseModel";
import SearchListItemModel from "../../../domain/entities/search/SearchListItemModel";
import PaginationModel from "../../../domain/entities/generic/PaginationModel";

function useSearchListViewModel() {

    const searchRepository = new SearchApi(new HttpClient());

    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [currentCaseId, setCurrentCaseId] = useState("");
    const [caseDetailsName, setCaseDetailsName] = useState("");
    const [pageData, setPageData] = useState<SearchListItemModel[]>([]);
    const [searchDetails, setSearchDetails] = useState<SearchDetailsModel>({} as SearchDetailsModel);
    const [sortDirection, setSortDirection] = useState("");
    const [sort, setSort] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isShowError, setIsShowError] = useState(false);
    const [errorMessages, setErrorMessages] = useState("");
    const [showAddModal, setShowAddModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(0);
    const [searchQuery, setSearchQuery] = useState<SearchListFiltrationModel>({});

    useEffect(() => { getCurrentPage(0) }, [searchQuery]);
    useEffect(() => { getCurrentPage(pageNumber) }, [sort, sortDirection]);

    const openAddModal = (id: string | null) => {
        setShowAddModal(true);
        const selectedItemId = id === null ? 0 : +id;
        setSelectedItemId(selectedItemId);
        getSearchDetails(selectedItemId);
    };

    const closeAddModal = () => setShowAddModal(false);

    const onCaseIdQueryChanged = (caseId: string) => {
        setCurrentCaseId(caseId);
    }

    const onSearchChanged = (model: SearchListFiltrationModel) => setSearchQuery(model);

    const getSearchDetails = async (id: number) => {
        try {
            setIsLoading(true);
            const result = await searchRepository.getSearchDetail(id);
            setIsShowError(!result.isSuccess);
            setIsLoading(false);
            if (result.isSuccess)
                setSearchDetails(result.value as SearchDetailsModel);
            else
                setErrorMessages(result.error);
        }
        catch (e: any) {
            setIsShowError(true);
            setErrorMessages(e.message);
        }
    }

    const getCaseDetails = async (caseId: string): Promise<Result<ListCaseModel>> => {
        try {
            setIsLoading(true);
            const result = await searchRepository.caseDetails(caseId ?? 0);
            setIsLoading(false);
            if (result.isSuccess)
                setCaseDetailsName(result?.value?.name || '');
            setIsShowError(!result.isSuccess);
            setErrorMessages(result.error);
            return result;
        } catch (e: any) {
            setIsLoading(false);
            setErrorMessages(e.message);
            setIsShowError(true);
            return Result.Fail(e.message, 500);
        }
    };

    const anyInprogressRequestExists = (): boolean => {
        for (let i = 0; i < pageData.length; i++) {
            const status = (pageData[i] as { status: string }).status;
            if (status == SearchStatus[SearchStatus.Inprogress] || status == SearchStatus[SearchStatus.Created])
                return true;
        }
        return false;
    }

    const getCurrentPage = async (_pageNumber: number, _pageSize: number = pageSize): Promise<Result<PaginationModel<SearchListItemModel>>> => {
        try {
            const model = {} as SearchListPaginationQuery;
            model.PageNum = _pageNumber;
            model.PageSize = _pageSize;
            model.CaseId = currentCaseId;
            model.SearchText = searchQuery.SearchText;
            model.Status = searchQuery.Status;
            model.CreationDateFrom = searchQuery.CreationDateFrom;
            model.CreationDateTo = searchQuery.CreationDateTo
            model.SortField = sort;
            model.SortDirection = sortDirection;
            setIsLoading(true);
            const result = await searchRepository.list(model);
            if (result.isSuccess) {
                setPageData(result?.value?.pageData || []);
                setTotalCount(result?.value?.totalCount || 0);
            }
            setIsLoading(false);
            setIsShowError(!result.isSuccess);
            setErrorMessages(result.error);
            return result;
        } catch (e: any) {
            setIsLoading(false);
            setErrorMessages(e.message);
            setIsShowError(true);
            return Result.Fail(e.message, 500);
        }
    };

    const updateSorting = (sortField: string, sortDirection: string) => {
        setSort(sortField);
        setSortDirection(sortDirection);
    }

    return {
        pageSize,
        pageNumber,
        totalCount,
        pageData,
        isLoading,
        isShowError,
        errorMessages,
        showAddModal,
        searchDetails,
        currentCaseId,
        caseDetailsName,

        openAddModal,
        closeAddModal,
        onCaseIdQueryChanged,
        onSearchChanged,
        getSearchDetails,
        getCaseDetails,
        anyInprogressRequestExists,
        getCurrentPage,
        updateSorting,
        setPageSize,
        setPageNumber
    };
}

export default useSearchListViewModel;
