import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import UserSettingApi from "../../../data/usersettings/UserSettingApi";
import UserAccountModel from "../../../domain/entities/usersettings/UserProfileModel";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import { RootState } from "../../../infrastructure/redux/reducer";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import CommunicationViewModel from "../../view-model/usersettings/CommunicationViewModel";


const UserInfo: React.FC = () => {

    const { viewModel, subscription } = useViewModel(CommunicationViewModel, [
        new UserSettingApi(new HttpClient()),
    ]);

    const [state, setState] = useState({ isLoading: false, isShowError: false, disabled: true, sendSearchRequestsCompletionEmail: false });
    const userProfile = useSelector((s: RootState) => s.userProfile as UserAccountModel);

    useEffect(() => {
        const subscriber = subscription.subscribe((d) => setState({ ...state, ...d.data }));
        viewModel.setValue(userProfile?.sendSearchRequestsCompletionEmail || false);
        return () => { subscriber.unsubscribe()};
    }, []);

    return (
        <>
            <Row className="container communication">
                <Row>
                    <Col className="p-0" md="auto"><Form.Switch className="email-toggle" checked={state.sendSearchRequestsCompletionEmail} onChange={viewModel.onChange} /></Col>
                    <Col><span>Send an email each time a new search is completed</span></Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col></Col>
                    <Col md="auto"><button disabled={state.disabled} onClick={viewModel.onSave} className="btn next-btn">Save</button></Col>
                </Row>
            </Row>
            <TailSpin
                wrapperClass="cover-spin"
                visible={state.isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
            <Modal show={state.isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setState({ ...state, isShowError: false })}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setState({ ...state, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UserInfo;
