import React from "react";
import { Col, Form, Row } from 'react-bootstrap';


type Props = {
    isHidden: boolean;
    onToggle: (hidden: boolean) => void;
};

const HideTabToggle: React.FC<Props> = ({ isHidden, onToggle }: Props) => {

    return (
        <Row>
            <Col md="auto" className="form-result-title mx-1">
                <span className="">Hide tab</span> 
            </Col>
            <Col md="auto" className="p-0 align-self-center  mx-1">
                <Form.Switch
                    className="form-result-toggle"
                    checked={isHidden}
                    onChange={(e) => onToggle(e.target.checked)}
                />
            </Col>
        </Row>

    );
};

export default HideTabToggle;


