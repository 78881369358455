import UserSettingRepository from '../../../domain/repositories/usersettings/IUserSettingRepository';
import SocialConnectionModel from '../../../domain/entities/usersettings/SocialConnectionModel';
import Result from '../../../domain/common/Result';
import IBaseViewModel from '../IBaseViewModel';
import { Subject } from 'rxjs';
import SocialConnectionTypeModel from '../../../domain/entities/usersettings/SocialConnectionTypeModel';

export default class SocialConnectionViewModel implements IBaseViewModel {

    //#region props
    public id: string | null;
    public socialConnectionTypeId: number;
    public username: string;
    public password: string;
    public socialConnectionList: any[];
    public mediaTypesList: SocialConnectionTypeModel[];
    //#endregion

    public validation:any;
    public isLoading: boolean;
    public isShowError: boolean;
    public errorMessages: string[];
    public isSuccess: boolean;

    private topic?: string;
    private subject?: Subject<any>;
    private userSettingRepository: UserSettingRepository;

    public constructor(userSettingRepository: UserSettingRepository) {
        this.id = null;
        this.password = '';
        this.username = '';
        this.socialConnectionTypeId = -1;
        this.socialConnectionList = [];
        this.mediaTypesList = [];

        this.validation = {};
        this.isLoading = false;
        this.isShowError = false;
        this.errorMessages = [];
        this.isSuccess = false;

        this.userSettingRepository = userSettingRepository;
    }
    public onQueryChanged = (e: React.FormEvent): void => {
        if (e.currentTarget.id == 'password') {
            const input = e as React.FormEvent<HTMLInputElement>;
            this.password = input.currentTarget.value;
        }
        if (e.currentTarget.id == 'username') {
            const input = e as React.FormEvent<HTMLInputElement>;
            this.username = input.currentTarget.value;
        }
        this.notifyViewAboutChanges();
    }
  
    onMediaNameQueryChanged(socialConnectionTypeId: string): void {
        this.socialConnectionTypeId = parseInt(socialConnectionTypeId);
        this.notifyViewAboutChanges();
    }
    onIdQueryChanged(id: string): void {
        this.id = id;
        if (id) {
            this.getDetails();
        }
    }

    public listSocialTypes = async (): Promise<void> => {
        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();

            const result = await this.userSettingRepository.listSocialTypes();
            this.mediaTypesList = result.value ?? [];
            if (!this.id && this.mediaTypesList && this.mediaTypesList.length > 0) {
                this.socialConnectionTypeId = this.mediaTypesList[0].id;
            } 
            this.isLoading = false;
            this.isShowError = !result.isSuccess;
            if (!result.isSuccess)
                this.errorMessages.push(result.error);
            this.notifyViewAboutChanges();
        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
            this.notifyViewAboutChanges();
        }

    };

    public onClickAdd = async (): Promise<void> => {
        if (!this.validateUserSettingForm()) {
            this.notifyViewAboutChanges();
            return;
        }

        try {
            const model = {} as SocialConnectionModel;
            model.Id = this.id;
            model.SocialConnectionTypeId = this.socialConnectionTypeId;
            model.Username = this.username;
            model.Password = this.password;

            this.isLoading = true;
            this.notifyViewAboutChanges();
            let result = Result.Ok();

            if (!this.id) {
                result = await this.userSettingRepository.addSocialConnection(model);
            }
            else {
                result = await this.userSettingRepository.updateSocialConnection(model);
            }

            this.isLoading = false;
            this.isShowError = !result.isSuccess;
            if (!result.isSuccess)
                this.errorMessages.push(result.error);
            if (result.isSuccess)
                window.location.href = '/settings/social-connection';
            this.isSuccess = result.isSuccess;
            this.notifyViewAboutChanges();
        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
            this.notifyViewAboutChanges();
        }

    };

    public getDetails = async (): Promise<void> => {
        try {
            const model = {} as SocialConnectionModel;
            model.Id = this.id;
            model.SocialConnectionTypeId = this.socialConnectionTypeId;
            model.Username = this.username;
            model.Password = this.password;

            this.isLoading = true;
            this.notifyViewAboutChanges();

            const result = await this.userSettingRepository.getSocialConnectionDetails(this.id ?? '');
            this.socialConnectionTypeId = result.value?.socialConnectionTypeId ?? -1 ;
            this.username = result.value?.username ?? "";
            this.password =  "";
            this.isLoading = false;
            this.isShowError = !result.isSuccess;
            if (!result.isSuccess)
                this.errorMessages.push(result.error);
            this.notifyViewAboutChanges();
        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
            this.notifyViewAboutChanges();
        }

    };

    private validateUserSettingForm = (): boolean => {
        let isShowError = false;
        this.validation = {};
        if (!this.socialConnectionTypeId) {
            isShowError = true;
            this.validation.socialConnectionTypeId = 'Social media type cannot be empty';
        }
        if (!this.username) {
            isShowError = true;
            this.validation.username = 'username cannot be empty';
        }
        if (!this.password) {
            isShowError = true;
            this.validation.password = 'password cannot be empty';
        }

        return !isShowError;
    }

    public notifyViewAboutChanges = (): void => {
        const data = {
            id: this.id,
            mediaName: this.socialConnectionTypeId,
            username: this.username,
            password: this.password,

            validation:this.validation,
            isLoading: this.isLoading,
            isSuccess: this.isSuccess,
            isShowError: this.isShowError,
            errorMessages: this.errorMessages
        }
        this.subject?.next({ topic: this.topic, data });
    };

    public attachSubject = (subject: Subject<any>, topicName: string): void => {
        this.topic = topicName;
        this.subject = subject
    }
}
