import React, { useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { RootState } from "../../../../infrastructure/redux/reducer";
import { useSelector } from "react-redux";
import SearchResultModel from "../../../../domain/entities/searchoutput/SearchResultModel";


const PersonalInfo: React.FC = () => {
    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    const capitalizeFirstLetter = (value: string | undefined) => { return value ? value[0].toUpperCase() + value.substring(1) : '' };
    const [imageUrl, setImageUrl] = useState("/images/person.png");
    const name = capitalizeFirstLetter(searchResult?.headerModel?.name?.firstName) + ' ' + capitalizeFirstLetter(searchResult?.headerModel?.name?.lastName);

    useEffect(() => {
        if (searchResult?.pictureUrl)
            setImageUrl(searchResult.pictureUrl);
    }, [searchResult]);

    return (
        <Row className="personal-info">
            <Col md="auto" className="p-0"><img src={imageUrl} onError={() => { setImageUrl("/images/person.png") }} alt="img" width="86px" height="84px" className="person-img" /></Col>
            <Col className="p-0">
                <div className="name">{name}</div>
                <div className="age">{searchResult?.headerModel?.age} years old</div>
            </Col>
        </Row>
    );
};

export default PersonalInfo;
