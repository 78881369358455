import React, { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { useParams } from "react-router";
import BackArrowSvgIcon from "../../components/svg-icons/BackArrow";
import useAddUpdateCompanyViewModel, { CompanyDetailsState } from "../../view-model/admin/AddUpdateCompanyViewModel_new";
import MainInfo from "../../components/add-update-company/MainInfo_new";
import AccountPointOfContact from "../../components/add-update-company/AccountPointOfContact_new";
import BillingPointOfContact from "../../components/add-update-company/BillingPointOfContact_new";
import BillingInfo from "../../components/add-update-company/BillingInfo_new";
import SalesRepresentative from "../../components/add-update-company/SalesRepresentative_new";
import { Control, UseFormGetValues, useForm } from "react-hook-form";

export type CompanyChildComponentProps = {
    state?: CompanyDetailsState,
    control: Control<CompanyDetailsState, any>,
    getValues?: UseFormGetValues<CompanyDetailsState>,
    displayNetsuiteExternalId?: boolean
};

const AddUpdateCompany: React.FC = () => {

    const { id } = useParams();

    const {
        state,
        setStateIsReady,
        stateIsReady,
        isLoading,
        isShowError,
        setIsShowError,
        getData,
        saveChanges,
        initializeState,
        createdSuccessfully
    } = useAddUpdateCompanyViewModel();

    useEffect(() => {
        initializeState();
        if (id)
            getData(+id);
        setStateIsReady(true);
    }, []);

    useEffect(() => { reset(state) }, [state]);

    const { control, handleSubmit, reset, formState: { isValid, errors }, getValues, watch, setValue } = useForm({ defaultValues: state, mode: "onTouched" });

    const billingPocSameAsAccountPoc = watch("billingPocSameAsAccountPoc");
    const accountPocFirstName = watch("accountPointOfContact.firstName");
    const accountPocLastName = watch("accountPointOfContact.lastName");
    const accountPocPhone = watch("accountPointOfContact.phone");
    const accountPocEmail = watch("accountPointOfContact.email");

    useEffect(() => { if (billingPocSameAsAccountPoc) setValue("billingPointOfContact.firstName", accountPocFirstName) }, [accountPocFirstName]);
    useEffect(() => { if (billingPocSameAsAccountPoc) setValue("billingPointOfContact.lastName", accountPocLastName) }, [accountPocLastName]);
    useEffect(() => { if (billingPocSameAsAccountPoc) setValue("billingPointOfContact.phone", accountPocPhone) }, [accountPocPhone]);
    useEffect(() => { if (billingPocSameAsAccountPoc) setValue("billingPointOfContact.email", accountPocEmail) }, [accountPocEmail]);

    useEffect(() => {
        if (stateIsReady) {
            setValue("billingPointOfContact.firstName", billingPocSameAsAccountPoc ? accountPocFirstName : "");
            setValue("billingPointOfContact.lastName", billingPocSameAsAccountPoc ? accountPocLastName : "");
            setValue("billingPointOfContact.phone", billingPocSameAsAccountPoc ? accountPocPhone : "");
            setValue("billingPointOfContact.email", billingPocSameAsAccountPoc ? accountPocEmail : "");
        }
    }, [billingPocSameAsAccountPoc]);

    return (
        <div className="container">
            <form className="add-update-company-container" onSubmit={handleSubmit(saveChanges)}>
                <Row>
                    <Col className="p-0" md="auto"><a href="/admin/company"> <BackArrowSvgIcon /></a></Col>
                    {id && <Col><div className="admin-page-title">Update Company</div></Col>}
                    {!id && <Col><div className="admin-page-title">Create Company</div></Col>}
                </Row>
                <Row className="add-update-company-form">
                    <MainInfo state={state} control={control} getValues={getValues} displayNetsuiteExternalId={id as unknown as boolean} />
                    <AccountPointOfContact control={control} />
                    <BillingPointOfContact control={control} />
                    <BillingInfo state={state} control={control} />
                    <SalesRepresentative control={control} />
                </Row>
                <Row className="save-btn-row"><button disabled={!isValid || createdSuccessfully} className="btn next-btn">Save</button></Row>
            </form>
            <TailSpin
                wrapperClass="cover-spin"
                visible={isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
            <Modal show={isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setIsShowError(false)}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setIsShowError(false)} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default AddUpdateCompany;