import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import GlbaDppaOptions from "../../components/GlbaDppaOptions";

type Props = {
    introStatement: React.ReactNode;
    GLBAOptionId: number;
    DPPAOptionId: number;
    showGlbaDppaWarningModal: boolean;
    glbaDppaSelectedOptionWarning: string;
    onGlbaChanged: (id: number) => void;
    onDppaChanged: (id: number) => void;
    hideGlbaDppaWarningModal: () => void;
    onPrevious: () => void;
    onCancel: () => void;
}

const GlbaDppa: React.FC<Props> = (props: Props) => {

    return <>
        <div className="registration glba-dppa main-page">
            <div className="section-wrapper">
                <Row className="ml-n-25px">{props.introStatement}</Row>
                <Row className="current-step"><img className="p-0" src="/images/glba_dppa.svg" /></Row>
                <Row>
                    <GlbaDppaOptions
                        glbaSelectedOption={props.GLBAOptionId}
                        dppaSelectedOption={props.DPPAOptionId}
                        onChangeGlba={(id: number) => props.onGlbaChanged(id)}
                        onChangeDppa={(id: number) => props.onDppaChanged(id)}
                    />
                </Row>
                <Row><hr /></Row>
                <Row>
                    <Col><a onClick={props.onCancel} className="btn cancel-btn">Cancel</a></Col>
                    <Col md="auto">
                        <a onClick={props.onPrevious} className="btn previous-btn"><span>&larr;</span> &nbsp;&nbsp;&nbsp; Previous</a>
                        <button name="registerBtn" className="btn next-btn">Register</button>
                    </Col>
                </Row>
            </div>
            <Modal show={props.showGlbaDppaWarningModal}>
                <Modal.Body className="modal-card warning-modal-card">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Warning</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={props.hideGlbaDppaWarningModal}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">You have selected{props.glbaDppaSelectedOptionWarning}that won&apos;t let you benefit from the search requests featue. Are you sure you want to continue?</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col md="auto" className="p-0"><a onClick={props.hideGlbaDppaWarningModal} className="btn previous-btn">No, I will change my selection</a></Col>
                            <Col className="p-0"><button form="registration-form" name="continueBtn" className="btn next-btn">Yes, I want to continue</button></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    </>
};

export default GlbaDppa;
