import React, { Fragment } from "react";
import { Col, Row } from 'react-bootstrap';
import NameModel from '../../../../domain/entities/generic/NameModel';
import PDFPersonalInfo from "./PDFPersonalInfo_new";
import PDFRequestDetails from "./PDFRequestDetails_new";

export type HeaderInfo = {
    id?: number | undefined;
    caseId?: number | undefined;
    caseName?: string | undefined;
    imageUrl?: string | undefined;
    name?: NameModel | undefined;
    age?: string | undefined;
    address?: string | undefined;
    phone?: string | undefined;
    email?: string | undefined;
};

const PDFHeader: React.FC = () => {

    return (
        <Fragment>
            <Row className="header bg-white box-shadow">
                <Col xs="auto">
                    <PDFPersonalInfo />
                </Col>
                <Col>
                    <PDFRequestDetails />
                </Col>
            </Row>
        </Fragment>
    );
};

export default PDFHeader;
