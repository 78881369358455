import { Helmet } from "react-helmet";
import React from "react";
import { TailSpin } from "react-loader-spinner";
import { Col, Modal, Row } from "react-bootstrap";
import useForgetPasswordViewModel from "../view-model/auth/ForgetPasswordViewModel_new";

const ForgetPassword: React.FC = () => {

    const {
        email,
        validation,
        isLoading,
        isSuccess,
        isShowError,
        errorMessages,
        disable,
        setIsShowError,
        onEmailQueryChanged,
        onConfirm
    } = useForgetPasswordViewModel();

    return (
        <div className="login">
            <Helmet>
                <title>Social E-Profiler - Forget Password</title>
                <meta name="description" content="Social E-Profiler" />
            </Helmet>
            <div className="container body-container">
                <div className="login-container forget-password-container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="panel panel-default well">
                                <div className="panel-body">
                                    <form id="contact-form" className="main-form forget-password-updates" onSubmit={(e: any) => e.preventDefault()}>
                                        <h2 className="forget-password-title">Forget Password</h2>
                                        <p className="span-2">
                                            Please enter your email
                                        </p>
                                        <div>
                                            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                                                <input autoComplete="new-password" type="text" placeholder="Email" name="email" id="email" onChange={onEmailQueryChanged} value={email} className={`form-control ${validation.email ? "is-invalid" : ""}`} />
                                                <label className="form-label">Email<span className="mandatory-symbol">*</span></label>
                                                {validation.email && (<div className="invalid-feedback">{validation.email}</div>)}
                                            </Col>
                                            <button disabled={disable} className="col-12 btn next-btn" name="register" onClick={onConfirm}>Confirm</button>
                                            <div className="form-group"><a className="forget-password" href="/login">Login</a></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div>
                    <a href="https://intertelinc.com/privacy-policy/">Privacy Policy</a>
                    &nbsp;&nbsp;&nbsp;
                    <a href="https://intertelinc.com/terms-conditions/">Terms and Conditions</a>
                </div>
            </div>
            <TailSpin wrapperClass="cover-spin" visible={isLoading} height={50} width="50" color="#FF5733" ariaLabel="loading" />
            <Modal show={isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setIsShowError(false)}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setIsShowError(false)} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ForgetPassword;
