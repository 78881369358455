import React, { useEffect, useState } from "react";
import { Col, Row, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SearchDetails from "../../../views/SearchDetails";
import EmailSvgIcon from "../../svg-icons/EmailSvgIcon";
import HomeSvgIcon from "../../svg-icons/HomeSvgIcon";
import PhoneSvgIcon from "../../svg-icons/PhoneSvgIcon";
import RequestDetail from "./RequestDetail";
import { RootState } from "../../../../infrastructure/redux/reducer";
import { useDispatch, useSelector } from "react-redux";
import SearchResultModel from "../../../../domain/entities/searchoutput/SearchResultModel";
import { useNavigate } from "react-router";
import { setReRunSearch } from "../../../../infrastructure/redux/actions/reRunSearch";


const RequestDetails: React.FC = () => {
    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    const HeaderModel = searchResult.headerModel;
    const [isShowModal, showModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const renderTooltip = (p: any) => (<Tooltip id="button-tooltip" {...p}>View</Tooltip>);

    const onRerunSearch = async () => {
        dispatch(setReRunSearch(HeaderModel?.searchDetails as unknown as ReRunSearchState));
        navigate('/');
    }

    return (
        <Row className="request-details-info">
            <Row className="title">
                <Col md={"auto"} className="request-details p-0">Request Details</Col>
                <Col md={"auto"} onClick={() => showModal(true)} className="view-details p-0">
                    <OverlayTrigger placement="top" delay={{ show: 0, hide: 200 }} overlay={renderTooltip}>
                        <div><span className="view-details-title" >View request details</span><img className="details-img" src="/images/details.svg" /></div>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row className="details p-0">
                <RequestDetail title="Address" value={HeaderModel?.address}><HomeSvgIcon /></RequestDetail>
                <RequestDetail title="Phone" value={HeaderModel?.phone}><PhoneSvgIcon /></RequestDetail>
                <RequestDetail title="Email" value={HeaderModel?.email}><EmailSvgIcon /></RequestDetail>
            </Row>
            <Modal show={isShowModal} onHide={() => showModal(false)} size="lg">
                <div className="searc-details-modal">
                    <Modal.Body>
                        <SearchDetails data={HeaderModel?.searchDetails || null} onCloseModal={() => showModal(false)} onRerunSearch={onRerunSearch} />
                    </Modal.Body>
                </div>
            </Modal>
        </Row>
    );
};

export default RequestDetails;