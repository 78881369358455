import React from "react";
import useVisibility from "../../../infrastructure/hooks/useVisibility";
import PDFAddresses from "./PDFAddresses_new";
import PDFEducation from "./PDFEducation_new";
import PDFInternetSearches from "./PDFInternetSearches_new";
import PDFPhones from "./PDFPhones_new";
import PDFRelatives from "./PDFRelatives_new";
import PDFWork from "./PDFWork_new";
import PDFSocialResults from "./SocialResults/PDFSocialResults_new";
import PDFEmails from "./emails/PDFEmails_new";
import PDFHeader from "./header/PDFHeader_new";

const PDFSearchResults: React.FC = () => {
    const { visibilityFlags } = useVisibility();

    return (
        <div className="search-result pdf">
            <PDFHeader />
            {!visibilityFlags.isAddressHidden && < PDFAddresses />}
            {!visibilityFlags.isWorkHidden && <PDFWork />}
            {!visibilityFlags.isEducationHidden && <PDFEducation />}
            {!visibilityFlags.isRelativesHidden && <PDFRelatives />}
            {!visibilityFlags.isPhonesHidden && <PDFPhones />}
            {!visibilityFlags.isEmailsHidden && <PDFEmails />}
            {!visibilityFlags.isInternetSearchesHidden && <PDFInternetSearches />}
            {!visibilityFlags.isSocialResultsHidden && <PDFSocialResults />}
        </div>
    );
};

export default PDFSearchResults;
