import React from "react";
import { Button, Col, Row } from 'react-bootstrap';
import SocialResultModel from "../../../../domain/entities/searchoutput/SocialResultModel";
import SocialResultsModal from "./SocialResultsModal_new";

export type Props = {
    result: SocialResultModel;
    platformIconUrl: string;
    platformName: string;
    isDeleted: boolean;

}

const numberPropsMaxCount = 5;
const numberPropMaxLength = 6;
const SocialResultsWithData: React.FC<Props> = ({ result, platformIconUrl, platformName, isDeleted }: Props) => {

    function formatKeyText(key: string) {
        const replaceUnderscore = key.split("_");
        for (let i = 0; i < replaceUnderscore.length; i++) {
            replaceUnderscore[i] =
                replaceUnderscore[i][0].toUpperCase() + replaceUnderscore[i].substr(1);
        }

        const enhancedKey = replaceUnderscore.join(" ");

        return enhancedKey;
    }

    const parsedObject = JSON.parse(result.resultData);

    const imgUrl = parsedObject["image"] ?? "/images/person.png";
    delete parsedObject["image"];
    const name = parsedObject["fullname"];
    delete parsedObject["fullname"];
    let username = parsedObject["username"];
    delete parsedObject["username"];
    if (!username && result.username)
        username = result.username;
    const bio = parsedObject["bio"];
    delete parsedObject["bio"];
    const createdAt = parsedObject["created_at"];
    delete parsedObject["created_at"];
    const address = parsedObject["address"];
    delete parsedObject["address"];
    const images = parsedObject["images"]?.replaceAll('\'', '"');
    const imagesList = images ? JSON.parse(images) as Array<string> : undefined;
    delete parsedObject["images"];

    let numberPropsCount = 0;
    const miscPropsArray: any[] = [];
    const numericalPropsArray: any[] = [];

    if (username && username.charAt(0) === '@') {
        username = username.substring(1);
    }

    for (const prop in parsedObject) {
        if (!isNaN(parsedObject[prop]) && parsedObject[prop].length <= numberPropMaxLength && numberPropsCount < numberPropsMaxCount) {
            numericalPropsArray.push({ key: prop, value: parsedObject[prop] });
            numberPropsCount++;
        } else {
            miscPropsArray.push({ key: prop, value: parsedObject[prop] });
        }
    }

    return (
        <Row className="social-result-card box-shadow bg-white">
            <Row className="m-0 p-0 align-items-center">
                {result.rulesBasedValidation && (
                    <Col className="p-0">
                        <span className="social-identifiers-header col-auto p-0 me-1">Identifiers:</span>
                        <span className="social-identifiers col-auto p-0">{result.rulesBasedValidation}</span>
                    </Col>
                )}
                <Col xs={"auto"} className="header-text gaia p-0">
                    <SocialResultsModal isDeleteAction={!isDeleted} result={result} />
                </Col>
            </Row>

            <Row className="my-2">
                <Col className={`platform col-auto ${platformName?.replaceAll(" ", "-")}`}>
                    <img
                        className="platform-icon p-0 me-1"
                        src={platformIconUrl}
                        alt="img"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/icons/default-social-icon.svg";
                        }}
                    />
                    {platformName ?? result.url.split('.com')[0]}
                </Col>
            </Row>

            <Row className="my-2">
                <Col className="col-3">
                    <img className="profile-pic"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = "/images/person.png";
                        }}
                        src={imgUrl}
                        alt="img"
                    />
                </Col>
                <div className="col-9 d-flex flex-column pt-2">
                    {name && <div className="social-result-name my-1"> {name} </div>}
                    {username && <div className="social-result-username my-1"> @{username} </div>}
                </div>
            </Row>

            {createdAt && createdAt.trim().length > 0 && (
                <Row className="my-1">
                    <span className="social-med-header col-auto p-0 me-1">Created At:</span>
                    <span className="social-med-value col-auto p-0">{createdAt}</span>
                </Row>
            )}

            {address && address.trim().length > 0 && (
                <Row className="my-1">
                    <span className="social-med-header col-auto p-0 me-1">Address:</span>
                    <span className="social-med-value col-auto p-0">{address}</span>
                </Row>
            )}

            {bio && bio.trim().length > 0 && (
                <>
                    <Row className="social-large-header my-1"> Bio</Row>
                    <Row className="social-large-value my-1"> {bio}</Row>
                </>
            )}

            {numericalPropsArray && numericalPropsArray.length > 0 && (
                <div className="d-flex flex-row justify-content-around my-2">
                    {numericalPropsArray.map(({ key, value }) => (
                        <div key={Math.random()}>
                            <div className="social-num-header text-center">{formatKeyText(key)}</div>
                            <div className="social-num-value text-center">{value}</div>
                        </div>
                    ))}
                </div>
            )}

            {miscPropsArray && miscPropsArray.length > 0 && (
                <Row className="my-2">
                    {miscPropsArray.map(({ key, value }) => {
                        const values = value.includes("http") ? [...value.replace(/[ '[\]]/g, '').split(',')] : [];
                        return (
                            <Row className="justify-content-between" key={Math.random()}>
                                <Col xs="auto" className="p-0 social-short-header"> {formatKeyText(key)}:</Col>
                                <Col xs="auto" className="p-0 social-short-value url-text">
                                    {values.length === 0 ? value : values.map(val => <a key={Math.random()} href={val} target="_blank" rel="noreferrer">{val}</a>)}
                                </Col>
                            </Row>
                        );
                    })}
                </Row>
            )}

            {imagesList && imagesList.length > 0 && (
                <>
                    <Row className="social-large-header my-1"> Images</Row>
                    <Row className="social-large-value my-1">
                        {imagesList.map(i => (
                            <a href={i} key={Math.random()}>
                                <img className="social-small-result-img"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = "/images/person.png";
                                    }}
                                    src={i}
                                    alt="img"
                                />
                            </a>
                        ))}
                    </Row>
                </>
            )}

            <Row className="col-9 my-1">
                <Button href={result.url.startsWith("http") ? result.url : "https://" + result.url} target="_blank" className="social-view-button py-1">View</Button>
            </Row>
        </Row>
    );
}

export default SocialResultsWithData;
