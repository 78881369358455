import React from "react";
import { Col, Row } from "react-bootstrap";
import PersonalInformation from "../person-info-edit/PersonalInformation_new";
import AlternativeContact from "../person-info-edit/AlternativeContact_new";
import AccountPasswordRecovery from "../person-info-edit/AccountPasswordRecovery_new";
import CompanyInformation from "../person-info-edit/CompanyInformation_new";
import { Control, UseFormGetValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import PersonInfoEditState from "../../models/PersonInfoEditState";

type Props = {
    introStatement: React.ReactNode;
    formIsValid: boolean;
    state: PersonInfoEditState;
    control: Control<PersonInfoEditState, any>;
    watch: UseFormWatch<PersonInfoEditState>,
    setValue: UseFormSetValue<PersonInfoEditState>,
    getValues: UseFormGetValues<PersonInfoEditState>,
    onEmailChange: (email: string) => void,
    onPrevious: () => void;
    onNext: () => void;
    onCancel: () => void;
}

const AccountInfo: React.FC<Props> = (props: Props) => {

    return <>
        <div className="registration account-info main-page">
            <div className="section-wrapper px-5">
                <Row className="ml-n-25px">{props.introStatement}</Row>
                <Row className="current-step ml-n-25px"><img className="p-0" src="/images/account_info.svg" /></Row>
                <PersonalInformation
                    editableEmail={true}
                    isAuthorizedManagerEnabled={false}
                    isAdminEnabled={false}
                    control={props.control}
                    onEmailChange={props.onEmailChange}
                    watch={props.watch}
                />
                <AlternativeContact
                    control={props.control}
                />
                <AccountPasswordRecovery
                    control={props.control}
                    state={props.state}
                    watch={props.watch}
                    setValue={props.setValue}
                />
                <CompanyInformation
                    control={props.control}
                    state={props.state}
                    getValues={props.getValues}
                />
                <Row><hr /></Row>
                <Row>
                    <Col><a onClick={props.onCancel} className="btn cancel-btn">Cancel</a></Col>
                    <Col md="auto">
                        <a onClick={props.onPrevious} className="btn previous-btn"><span>&larr;</span> &nbsp;&nbsp;&nbsp; Previous</a>
                        <button type="button" onClick={props.onNext} disabled={!props.formIsValid} className="btn next-btn">Next &nbsp;&nbsp;&nbsp; <span>&rarr;</span></button>
                    </Col>
                </Row>
            </div >
        </div>
    </>
};

export default AccountInfo;