import React, { useEffect, useState } from "react";
import BaseView from "../BaseView";
import ChangePasswordViewModel from "../../view-model/auth/ChangePasswordViewModel";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { Helmet } from "react-helmet";
import { Alert } from "react-bootstrap";
import SuccessAlert from "../../components/SuccessAlert";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import AuthApi from "../../../data/auth/AuthApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import FloatingLabelControl from "../../components/FloatingLabelControl";
import ErrorAlert from "../../components/ErrorAlert";
import { toast } from "react-toastify";
import PasswordChecklist from "react-password-checklist";

type ChangePasswordComponentState = {
  oldPassword: string;
  password: string;
  confirmPassword: string;

  validation: any;
  isLoading: boolean;
  isShowError: boolean;
  isSuccess: boolean;
  errorMessages: string[];
  startPasswordType: boolean;
};

const ChangePassword: React.FC = () => {
  const { viewModel, subscription } = useViewModel(ChangePasswordViewModel, [
    new AuthApi(new HttpClient()),
  ]);
  const [state, setState] = useState<ChangePasswordComponentState>({
    oldPassword: "",
    confirmPassword: "",
    validation: {},
    isSuccess: false,
    password: "",
    isLoading: false,
    isShowError: false,
    errorMessages: [],
    startPasswordType: false,
  });


  useEffect(() => {
    const subscriber = subscription.subscribe((d) =>
      setState({ ...state, ...d.data })
    );

    return () => {
      subscriber.unsubscribe();
    };
  }, []);

  const notify = () => {
    toast.success(
      "Password changed successfully, you can now use your new password now.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        closeButton: false,
        theme: "dark",
        progress: undefined,
        className: "avoid-nav-bar",
        bodyClassName: "toast-message",
      }
    );
  };
  if (viewModel.isSuccess) {
    notify();
    viewModel.isSuccess = false;
  }

  return (
    <>
      {state.isShowError && (
        <div className="row g-3">
          <ErrorAlert
            headerMessage="GenericErrorHeaderMessage"
            bodyMessage="ChangePasswordErrorrBodyMessage"
          />
        </div>
      )}
      <div className="user-security-div">
        <div className="form-style-one">
          <div className="row gx-xxl-5">
            <div className="col-lg-8  col-md-12 col-sm-8 order1">
              <h2 className="change-password-title">Change Password</h2>
              <form
                id="contact-form"
                className="main-form"
                onSubmit={(e: any) => e.preventDefault()}
              >
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="input-group-meta form-group mb-20 form-floating">
                      <input
                        type="password"
                        placeholder="Old Password"
                        name="oldPassword"
                        id="oldPassword"
                        onChange={viewModel.onQueryChanged}
                        value={state.oldPassword}
                        className={` form-control ${state.validation.oldPassword ? "is-invalid" : ""
                          }`}
                      />
                      <label className="form-label">Old Password</label>

                      {state.validation.oldPassword && (
                        <div className="invalid-feedback">
                          {state.validation.oldPassword}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <div className="input-group-meta form-group mb-30 form-floating">
                      <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        id="password"
                        onChange={viewModel.onQueryChanged}
                        value={state.password}
                        className={`form-control ${state.validation.password ? "is-invalid" : ""
                          }`}
                      />
                      <label className="form-label">Password</label>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="input-group-meta form-group mb-30 form-floating">
                      <input
                        type="password"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        id="confirmPassword"
                        onChange={viewModel.onQueryChanged}
                        value={state.confirmPassword}
                        className={`form-control ${state.validation.confirmPassword ? "is-invalid" : ""
                          }`}
                      />
                      <label className="form-label">Confirm Password</label>
                    </div>
                  </div>
                </div>
                {state.startPasswordType && (
                  <div className="col-12 ms-2 password-checklist">
                    <PasswordChecklist
                      rules={[
                        "minLength",
                        "lowercase",
                        "capital",
                        "specialChar",
                        "number",
                        "match",
                      ]}
                      value={state.password}
                      valueAgain={state.confirmPassword}
                      minLength={8}
                    />
                  </div>
                )}
                <TailSpin
                  wrapperClass="cover-spin"
                  visible={state.isLoading}
                  height={50}
                  width="50"
                  color="#FF5733"
                  ariaLabel="loading"
                />

                <br />

                {state.isShowError &&
                  state.errorMessages.map((error, i) => {
                    return (
                      <div
                        key={i}
                        className="row my-3 text-danger justify-content-center"
                      >
                        {error}
                      </div>
                    );
                  })}

                <div className="col-sm-12 col-md-6 btn-row ">
                  <button
                    name="register"
                    className="btn next-btn"
                    onClick={(): Promise<void> => viewModel.onClick()}
                  >
                    Change Password
                  </button>
                </div>
              </form>
            </div>
            <div className="col-lg-4">
              <div className="hot-news-div">
                <div className="hot-news-title-div">
                  <img
                    className="hot-news-icon"
                    src="/images/hot-news.svg"
                    alt=""
                  />
                  <p className="hot-news-title">Useful Tips</p>
                </div>
                <div className="hot-news-content">
                  <p className="hot-news-content-header">
                    Strong passwords must be between 8-12 characters
                  </p>
                  <p className="hot-news-content-text">
                    When creating a password, please make sure to use something
                    unique, that has not been previously used by you, and is
                    unlikely to be duplicated. Strong passwords are best when
                    8-12 characters, and include special characters, numbers,
                    uppercase, and lowercase letters.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row g-3">
                <h2 className="title">Change password</h2>
                <div className='row g-3'>
                    <FloatingLabelControl disabled={false} type='password' label='Old Password' id='oldPassword' onChange={viewModel.onQueryChanged} value={state.oldPassword} />

                    <FloatingLabelControl disabled={false} type='password' label='Password' id='password' onChange={viewModel.onQueryChanged} value={state.password} />

                    <FloatingLabelControl disabled={false} type='password' label='Confirm Password' id='confirmPassword' onChange={viewModel.onQueryChanged} value={state.confirmPassword} />

                    <TailSpin
                        visible={state.isLoading}
                        height={50}
                        width="50"
                        color='#FF5733'
                        ariaLabel='loading'
                    />

                    <br />

                    <div className="p-t-15">
                        <button
                            type="button"
                            className="btn btn--radius-2 btn--blue"
                            onClick={(): Promise<void> => viewModel.onClick()}
                        >
                            Change password
                        </button>
                    </div>
                </div>
                <br />
                <br />
            </div> */}
    </>
  );
};
export default ChangePassword;
