import React, { useEffect, useState } from "react";
import { Row, Col } from 'react-bootstrap';
import ResultCount from "./ResultCount";
import { RootState } from "../../../infrastructure/redux/reducer";
import { useSelector } from "react-redux";
import SearchResultModel from "../../../domain/entities/searchoutput/SearchResultModel";


const PDFEducation: React.FC = () => {
    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    
    return (
        <>
            <Row className="d-flex">
                <ResultCount count={searchResult?.educations?.length ?? 0} text={"Education"} />
            </Row>
            <Row className="p-0 education">
                {searchResult?.educations?.map(e =>
                    <Col className="col-4" key={Math.random()}>
                        <Row className="education-card box-shadow bg-white m-0">
                            <Row className="education-title">
                                <Col className="col-auto img-box px-2">
                                    <img width='30px' height='18px' src="/images/search-results/education.png" />
                                </Col>

                                {e.schoolName}
                            </Row>
                            <hr />
                            <Row className="education-data">
                                <Col className="col-auto img-box-clear px-2">
                                    <img width='30px' height='18px' src="/images/search-results/address.png" />
                                </Col>
                                <Col xs="auto" className="education-data-header p-0">
                                    Location:
                                </Col>
                                <Col xs={8} className="px-1">
                                    {e.location ?? <span className="not-available">Not Available</span>}
                                </Col>

                            </Row>
                            <Row className="education-data">
                                <Col className="col-auto img-box-clear px-2">
                                    <img width='30px' height='18px' src="/images/search-results/time.png" />
                                </Col>
                                <Col xs="auto" className="education-data-header p-0">From:</Col>
                                <Col className="px-1">{e.startYear ?? <span className="not-available">Not Available</span>} </Col>
                                <Col xs="auto" className="education-data-header p-0">To:</Col>
                                <Col className="px-1">{e.endYear ?? <span className="not-available">Not Available</span>} </Col>
                            </Row>

                        </Row>
                    </Col>)}
            </Row>
        </>
    );
};

export default PDFEducation;
