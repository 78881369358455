import React from "react";
import { Col, Row } from "react-bootstrap";
import CustomInput from "../CustomInput";
import { Control, UseFormGetValues } from "react-hook-form";
import CustomSelect from "../CustomSelect";
import PersonInfoEditState from "../../models/PersonInfoEditState";

type Props = {
    state: PersonInfoEditState,
    control: Control<PersonInfoEditState, any>,
    getValues?: UseFormGetValues<PersonInfoEditState>
}

const CompanyInformation: React.FC<Props> = ({ control, state, getValues }: Props) => {

    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Company Information</Col>
            <Col><hr /></Col>
        </Row>

        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="managerName"
                    placeholder="Manager Name"
                    control={control}
                />
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating">
                <CustomInput
                    name="employerName"
                    placeholder="Employer Name"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Employer Name can not be empty"
                />
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="employerAddress"
                    placeholder="Employer Address"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Employer Address can not be empty"
                />
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating">
                <CustomInput
                    name="employerCity"
                    placeholder="Employer City"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Employer City can not be empty"
                />
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomSelect
                    name="selectedState"
                    control={control}
                    placeholder=""
                    label="Employer State"
                    options={state?.stateList ?? []}
                    required
                    hasRequiredSymbol
                    requiredMessage="Employer State can not be empty"
                />
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating">
                <CustomInput
                    name="employerZIPCode"
                    placeholder="Employer Address Zip Code"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Employer ZIP Code can not be empty"
                    maxLength={5}
                />
            </Col>
        </Row>
        <Row className="row-style">
            <Col md="6" className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="claimOffice"
                    placeholder="Claim Office"
                    control={control}
                    required={getValues && getValues("isClaimOfficeRequired")}
                    hasRequiredSymbol={getValues && getValues("isClaimOfficeRequired")}
                    requiredMessage="Claim Office cannot be empty"
                />
            </Col>
        </Row>
    </>
};

export default CompanyInformation;
