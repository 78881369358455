import React from "react";
import { Col } from "react-bootstrap";
import { Control } from "react-hook-form";
import CustomInput from "../CustomInput";
import PersonInfoEditState from "../../models/PersonInfoEditState";

type Props = {
    control: Control<PersonInfoEditState, any>
}

const AdminCheckBox: React.FC<Props> = ({ control }: Props) => {

    return (
        <Col className="authorized-manager checkbox-wrapper">
            <Col md="auto" className="p-0">
                <CustomInput
                    type="checkbox"
                    name="isSuperAdmin"
                    control={control}
                    label="Admin"
                />
            </Col>
        </Col>
    );
}

export default AdminCheckBox;