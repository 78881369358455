import React from "react";
import { Col, Row } from "react-bootstrap";
import CustomSelect from "../CustomSelect";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import CustomInput from "../CustomInput";
import { emailRegex } from "../../../infrastructure/lookups/RegEx";
import PersonInfoEditState from "../../models/PersonInfoEditState";


type Props = {
    state: PersonInfoEditState,
    control: Control<PersonInfoEditState, any>,
    watch: UseFormWatch<PersonInfoEditState>,
    setValue: UseFormSetValue<PersonInfoEditState>
}

const AccountPasswordRecovery: React.FC<Props> = ({ control, state, watch, setValue }: Props) => {

    const selectedQuestion = watch("selectedQuestion");
    const emailNotEqualSecondaryEmail = watch("email") != watch("secondaryEmail");
    const onSelectedQuestionChange = () => setValue("securityQuestionAnswer", '');

    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Account/Password Recovery</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomSelect
                    name="selectedQuestion"
                    control={control}
                    placeholder=""
                    label="Security Question"
                    options={state?.questionList ?? []}
                    required
                    hasRequiredSymbol
                    requiredMessage="Security Question can not be empty"
                    onChange={onSelectedQuestionChange}
                />
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="securityQuestionAnswer"
                    placeholder="Answer"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Answer cannot be empty"
                    disabled={!selectedQuestion}
                />
            </Col>
        </Row>
        <Row className="row-style">
            <Col md="6">
                <div className="input-group-meta form-group mb-30 form-floating first-col-input">
                    <CustomInput
                        name="secondaryEmail"
                        placeholder="Secondary Email"
                        control={control}
                        pattern={{ value: emailRegex, message: "Secondary Email is not valid" }}
                        validate={_ => emailNotEqualSecondaryEmail || "Secondary Email Can not be equal to Email"}
                    />
                </div>
            </Col>
        </Row>
    </>
};

export default AccountPasswordRecovery;
