import type SearchRepository from "../../domain/repositories/search/ISearchRepository";
import Result from "../../domain/common/Result";
import SearchModel from "../../domain/entities/search/SearchModel";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";
import SearchDetailsModel from "../../domain/entities/search/SearchDetailsModel";
import HelperMethods from "../../presentation/utils/HelperMethods";
import SearchListPaginationQuery from "../../domain/entities/search/SearchListPaginationQuery";
import PaginationModel from "../../domain/entities/generic/PaginationModel";
import SearchListItemModel from "../../domain/entities/search/SearchListItemModel";
import ListCaseModel from "../../domain/entities/cases/ListCaseModel";

export default class SearchApi implements SearchRepository {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }
  getSearchDetail(searchId: number): Promise<Result<SearchDetailsModel>> {
    const requestOptions = {
      method: "GET",
    };

    return this.httpClient
      .httpFetch(`/api/Search/${searchId}`, requestOptions)
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<SearchDetailsModel>(await res.json());
        return Result.FailWithValue<SearchDetailsModel>(
          "Server Error",
          {} as SearchDetailsModel,
          res.status
        );
      })
      .catch((e) => {
        return Result.FailWithValue<SearchDetailsModel>(
          e.message,
          {} as SearchDetailsModel,
          500
        );
      });
  }
  add(object: SearchModel): Promise<Result<string>> {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    return this.httpClient
      .httpFetch(`/api/search/add`, requestOptions)
      .then((res) => {
        if (res.status === 200) return Result.Ok();
        return Result.Fail("Server Error", res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
    }

    addFakeRequest(object: SearchModel): Promise<Result<string>> {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    return this.httpClient
      .httpFetch(`/api/search/addFakeRequest`, requestOptions)
      .then((res) => {
        if (res.status === 200) return Result.Ok();
        return Result.Fail("Server Error", res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
  }

  list(object: SearchListPaginationQuery): Promise<Result<PaginationModel<SearchListItemModel>>> {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    return this.httpClient
      .httpFetch(`/api/search`  + HelperMethods.toQueryString(object), requestOptions)
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<PaginationModel<SearchListItemModel>>(await res.json());
        return Result.Fail<PaginationModel<SearchListItemModel>>("Server Error", res.status);
      })
      .catch((e) => {
        return Result.Fail<PaginationModel<SearchListItemModel>>(e.message, 500);
      });
  }

  get(): Promise<Result<ListCaseModel[]>> {
    const requestOptions = {
      method: "GET",
    };

    return this.httpClient
      .httpFetch(`/api/case/List`, requestOptions)
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<ListCaseModel[]>(await res.json());
        return Result.Fail<ListCaseModel[]>("Server Error", res.status);
      })
      .catch((e) => {
        return Result.Fail<ListCaseModel[]>(e.message, 500);
      });
  }

  caseDetails(caseId: string): Promise<Result<ListCaseModel>> {
    const requestOptions = {
      method: "GET",
    };

    return this.httpClient
      .httpFetch(`/api/case/get?caseId=${caseId}`, requestOptions)
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<ListCaseModel>(await res.json());
        return Result.Fail<ListCaseModel>("Server Error", res.status);
      })
      .catch((e) => {
        return Result.Fail<ListCaseModel>(e.message, 500);
      });
    }
    getStates(): { id: number, name: string }[] {
        return [
            { id: 1, name: 'Alabama' },
            { id: 2, name: 'Alaska' },
            { id: 3, name: 'Arizona' },
            { id: 4, name: 'Arkansas' },
            { id: 5, name: 'California' },
            { id: 6, name: 'Colorado' },
            { id: 7, name: 'Connecticut' },
            { id: 8, name: 'Delaware' },
            { id: 9, name: 'Washington DC' },
            { id: 10, name: 'Florida' },
            { id: 11, name: 'Georgia' },
            { id: 12, name: 'Hawaii' },
            { id: 13, name: 'Idaho' },
            { id: 14, name: 'Illinois' },
            { id: 15, name: 'Indiana' },
            { id: 16, name: 'Iowa' },
            { id: 17, name: 'Kansas' },
            { id: 18, name: 'Kentucky' },
            { id: 19, name: 'Louisiana' },
            { id: 20, name: 'Maine' },
            { id: 21, name: 'Maryland' },
            { id: 22, name: 'Massachusetts' },
            { id: 23, name: 'Michigan' },
            { id: 24, name: 'Minnesota' },
            { id: 25, name: 'Mississippi' },
            { id: 26, name: 'Missouri' },
            { id: 27, name: 'Montana' },
            { id: 28, name: 'Nebraska' },
            { id: 29, name: 'Nevada' },
            { id: 30, name: 'New Hampshire' },
            { id: 31, name: 'New Jersey' },
            { id: 32, name: 'New Mexico' },
            { id: 33, name: 'New York' },
            { id: 34, name: 'North Carolina' },
            { id: 35, name: 'North Dakota' },
            { id: 36, name: 'Ohio' },
            { id: 37, name: 'Oklahoma' },
            { id: 38, name: 'Oregon' },
            { id: 39, name: 'Pennsylvania' },
            { id: 40, name: 'Rhode Island' },
            { id: 41, name: 'South Carolina' },
            { id: 42, name: 'South Dakota' },
            { id: 43, name: 'Tennessee' },
            { id: 44, name: 'Texas' },
            { id: 45, name: 'Utah' },
            { id: 46, name: 'Vermont' },
            { id: 47, name: 'Virginia' },
            { id: 48, name: 'Washington' },
            { id: 49, name: 'West Virginia' },
            { id: 50, name: 'Wisconsin' },
            { id: 51, name: 'Wyoming' }
        ].sort();
    }

    GetUserSearchRequests(model: SearchListPaginationQuery): Promise<Result<PaginationModel<SearchListItemModel>>> {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/search/GetUserSearchRequests` + HelperMethods.toQueryString(model), requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<PaginationModel<SearchListItemModel>>(await res.json());
            return Result.Fail<PaginationModel<SearchListItemModel>>('Server Error', res.status);
        }).catch(e => {
            return Result.Fail<PaginationModel<SearchListItemModel>>(e.message, 500);
        });
    }
}
