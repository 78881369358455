import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TailSpin } from "react-loader-spinner";
import SuccessAlert from "../../components/SuccessAlert";
import { useLocation } from "react-router";
import useUser from "../../../infrastructure/hooks/useUser";
import CreateSearchErrorModal from "./CreateSearchErrorModal";
import useSearchViewModel, { ExistCase, NewCase } from "../../view-model/search/SearchViewModel_new";
import CustomInput from "../../components/CustomInput";
import { Controller, useForm } from "react-hook-form";
import { emailRegex, phoneRegex } from "../../../infrastructure/lookups/RegEx";
import CustomSelect from "../../components/CustomSelect";
import CustomInputArray from "../../components/CustomInputArray";
import { Col } from "react-bootstrap";


const CreateSearch: React.FC = () => {

    const { user } = useUser();
    const search = useLocation().search;
    const caseId = new URLSearchParams(search).get("caseid");
    const today = new Date();
    const two_years_ago = new Date(today.setFullYear(today.getFullYear() - 2));
    const two_years_ago_str = two_years_ago.toISOString().slice(0, 10);
    const max_length_name_feilds = 25;

    const {
        state,
        isLoading,
        isSuccess,
        isShowError,
        errorMessages,
        onClickSearch,
        prepareData,
        oneOfRequiredFieldsIsEntered,
        dateOfBirthOrAgeRangeIsEntered,
        ageRangeIsValid
    } = useSearchViewModel();

    const { control, handleSubmit, reset, formState: { errors }, getValues, watch } = useForm({ defaultValues: state, mode: "onTouched" });
    const caseOption = watch("caseOption");

    useEffect(() => { prepareData(caseId ?? '') }, []);
    useEffect(() => { reset(state) }, [state]);

    return (
        <div className="container">
            {isSuccess && (
                <div className="mt-5">
                    <SuccessAlert
                        headerMessage="SearchHeaderMessage"
                        bodyMessage="SearchBodyMessage"
                    />
                </div>
            )}
            {!isSuccess && (
                <div className="">
                    <div className="search-header">
                        {state.caseName && (<div className="breadcrumb-div">
                                <a href="/case" className="breadcrumb-item active">Cases</a>
                                <p className="breadcrumb-seperator-item">/</p>
                                <a href={"/search?caseid=" + state.currentCaseId} className="breadcrumb-item active">{state.caseName}</a>
                                <p className="breadcrumb-seperator-item">/</p>
                                <p className="breadcrumb-item">New Search</p>
                            </div>
                        )}
                        <div className="search-title"><h2 className="search-title-text">Create Search Request</h2></div>
                    </div>
                    <div className="create-search-div">
                        <div className="form-style-one">
                            <div className="row gx-xxl-5">
                                <div className="col-lg-8 order1">
                                    <form id="contact-form" className="main-form" onSubmit={handleSubmit(onClickSearch)}>
                                        <div className="section-div">
                                            <p className="section-title text-center">Select Case</p>
                                            <div className="case-selection">
                                                <Controller
                                                    name="caseOption"
                                                    control={control}
                                                    defaultValue={NewCase}
                                                    render={({ field }) => (
                                                        <>
                                                            <input id="newCase" type="radio" value={NewCase} checked={field.value === NewCase} onChange={field.onChange} disabled={caseId ? true : false} />
                                                            <label htmlFor="newCase">New Case</label>
                                                            <input id="existCase" type="radio" value={ExistCase} checked={field.value === ExistCase} onChange={field.onChange} disabled={caseId ? true : false} />
                                                            <label htmlFor="existCase">Existing Case</label>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div>
                                                {caseOption == NewCase &&
                                                    <div className="col-md-6">
                                                        <div className="input-group-meta form-group mb-30 form-floating">
                                                            <CustomInput
                                                                name="newCaseName"
                                                                placeholder="Enter Case Name"
                                                                control={control}
                                                                required
                                                                requiredMessage="You must select an existing case, or create a new one."
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {caseOption == ExistCase &&
                                                    <div className="col-md-6">
                                                        <div className="input-group-meta form-group mb-30 form-floating case-option-select">
                                                            <CustomSelect
                                                                name="selectedCase"
                                                                control={control}
                                                                options={state.caseList ?? []}
                                                                placeholder="Select Case"
                                                                label="Case"
                                                                required
                                                                requiredMessage="You must select an existing case, or create a new one."
                                                                isClearable
                                                                isDisabled={caseId ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <hr className="search-divider mb-30 mt-30"></hr>

                                        <div className="section-div">
                                            <h3 className="section-title text-center">
                                                Personal Information
                                            </h3>

                                            <div className="row input-row">
                                                <div className="col-md-6">
                                                    <div className="input-group-meta form-group mb-30 form-floating">
                                                        <CustomInput
                                                            name="firstName"
                                                            placeholder="First name"
                                                            control={control}
                                                            required
                                                            requiredMessage="First Name cannot be empty"
                                                            maxLength={max_length_name_feilds}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="input-group-meta form-group mb-30 form-floating">
                                                        <CustomInput
                                                            name="lastName"
                                                            placeholder="Last name"
                                                            control={control}
                                                            required
                                                            requiredMessage="Last Name cannot be empty"
                                                            maxLength={max_length_name_feilds}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <CustomInputArray
                                                        arrayName="emails"
                                                        placeholder="Email"
                                                        control={control}
                                                        getValues={getValues}
                                                        regExp={emailRegex}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <CustomInputArray
                                                        arrayName="phones"
                                                        placeholder="Phone"
                                                        control={control}
                                                        getValues={getValues}
                                                        regExp={phoneRegex}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <CustomInputArray
                                                        arrayName="usernames"
                                                        placeholder="Username"
                                                        control={control}
                                                        getValues={getValues}
                                                    />
                                                </div>

                                                <div className="col-md-6"></div>

                                                <div className="col-md-5">
                                                    <div className="input-group-meta form-group mb-30 form-floating">
                                                        <CustomInput
                                                            type="date"
                                                            placeholder="Date of Birth"
                                                            name="dateOfBirth"
                                                            control={control}
                                                            maxValue={two_years_ago_str}
                                                            validate={_ => dateOfBirthOrAgeRangeIsEntered(getValues) || "You must enter date of birth or age range"}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-1">
                                                    <p style={{ textAlign: 'center', paddingTop: '7px' }}>or</p>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="input-group-meta form-group mb-30 form-floating">
                                                        <CustomInput
                                                            type="number"
                                                            placeholder="Age From"
                                                            name="ageRangeFrom"
                                                            control={control}
                                                            minValue={{ value: 2, message: "The starting age must be at least 2" }}
                                                            validate={(value) => ageRangeIsValid(getValues, value) || "Age range is not valid"}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="input-group-meta form-group mb-30 form-floating">
                                                        <CustomInput
                                                            type="number"
                                                            placeholder="Age To"
                                                            name="ageRangeTo"
                                                            control={control}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="search-divider mb-30 mt-30"></hr>

                                        <div className="section-div">
                                            <h3 className="section-title text-center">
                                                Address Information
                                            </h3>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-group-meta form-group mb-30 form-floating">
                                                        <CustomInput
                                                            name="address"
                                                            placeholder="Street Address"
                                                            control={control}
                                                            showError={false}
                                                            validate={_ => oneOfRequiredFieldsIsEntered(getValues) || "you must enter at least email, phone, Username, or street address and zip code."}
                                                        />
                                                    </div>                   
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="input-group-meta form-group mb-30 form-floating">
                                                        <CustomInput
                                                            placeholder="City"
                                                            name="city"
                                                            control={control}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-group-meta form-group modal-select-input form-floating">
                                                        <CustomSelect
                                                            name="selectedState"
                                                            control={control}
                                                            options={state.stateList ?? []}
                                                            placeholder="Select State"
                                                            label="State"
                                                            isClearable
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-group-meta form-group mb-30 form-floating">
                                                        <CustomInput
                                                            placeholder="Zip Code"
                                                            name="zipCode"
                                                            control={control}
                                                            maxLength={5}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="input-group-meta form-group mb-30 form-floating">
                                                        <CustomInput
                                                            type="date"
                                                            placeholder="Date of Loss"
                                                            name="dateOfLoss"
                                                            control={control}
                                                            maxValue={new Date().toISOString().slice(0, 10)}
                                                            required={state.isClaimOfficeRequired}
                                                            requiredMessage="Date of Loss cannot be empty"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-group-meta form-group mb-30 form-floating">
                                                        <CustomInput
                                                            placeholder="Employer"
                                                            name="employer"
                                                            control={control}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <TailSpin
                                            wrapperClass="cover-spin"
                                            visible={isLoading}
                                            height={50}
                                            width="50"
                                            color="#FF5733"
                                            ariaLabel="loading"
                                        />

                                        {isShowError && errorMessages?.map((error, i) => { return (<div key={i} className="row my-3 text-danger justify-content-center"> {error}</div>) })}

                                        <div className="invalid-form-data">{errors?.address?.message}&nbsp;</div>

                                        <div className="row search-form-btn-row">
                                            <Col md="auto">
                                                <button disabled={!user.canCreateSearchRequests} name="formDataOnly" className="submit-btn btn run-form-only" >Run Form Data Only</button>
                                            </Col>
                                            <Col md="auto">
                                                <button disabled={!user.canCreateSearchRequests || state.mustFillClaimOffice} name="enhancedSearch" className="submit-btn btn" >Run Enhanced Search</button>
                                            </Col>
                                        </div>

                                    </form>
                                </div>

                                <div className="col-lg-4">
                                    <div className="hot-news-div">
                                        <div className="hot-news-title-div">
                                            <img
                                                className="hot-news-icon"
                                                src="/images/hot-news.svg"
                                                alt=""
                                            />
                                            <p className="hot-news-title">Useful Tips</p>
                                        </div>
                                        <div className="hot-news-content">
                                            <p className="hot-news-content-header">
                                                You must enter either a Date of Birth or an age range.
                                            </p>
                                            <p className="hot-news-content-text">
                                                Verification starts with age. Please help us identify
                                                the proper person by adding in a date of birth. In cases
                                                where you do not have this information, please provide
                                                us with an age range to help narrow results.
                                            </p>
                                        </div>
                                        <div className="hot-news-content">
                                            <p className="hot-news-content-header">
                                                You must enter an Email Address, Username, Phone number
                                                or Physical Address.
                                            </p>
                                            <p className="hot-news-content-text">
                                                Certain fields are required to help ensure the accuracy
                                                of the results. The more information you provide, such
                                                as email addresses, phone numbers, usernames, and
                                                physical addresses, the more robust your results will
                                                be..{" "}
                                            </p>
                                        </div>
                                        <div className="hot-news-content">
                                            <p className="hot-news-content-header">
                                                You must include zip code when entering an address
                                            </p>
                                            <p className="hot-news-content-text">
                                                Please don&rsquo;t forget the zip code when entering an
                                                address. This is important, especially when dealing with
                                                commonly named people or searching people in large urban
                                                areas.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <CreateSearchErrorModal canCreateSearchRequests={user.canCreateSearchRequests} mustFillClaimOffice={state.mustFillClaimOffice} />

        </div>
    );
};

export default CreateSearch;
