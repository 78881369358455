import AccountStatusModel from "../../../domain/entities/admin/models/AccountStatusModel";
import UserAccountModel from "../../../domain/entities/admin/models/UserAccountModel";
import PageResult from "../../../domain/common/PageResult";
import AdminUsersPaginationQuery from "../../../domain/entities/admin/models/AdminUsersPaginationQuery";
import { useState } from "react";
import UserAccountApi from "../../../data/admin/UserAccountApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";

function useAllUsersViewModel() {

    const [statusList, setStatusList] = useState<AccountStatusModel[]>([]);
    const [currentPageData, setCurrentPageData] = useState<UserAccountModel[]>([]);
    const [searchByUsername, setSearchByUsername] = useState<string>('');
    const [searchByName, setSearchByName] = useState<string>('');
    const [searchByCompanyName, setSearchByCompanyName] = useState<string>('');
    const [searchByStatus, setSearchByStatus] = useState<string>('');
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isShowError, setIsShowError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedUserId, setSelectedUserId] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<string | null>(null);
    const [sortField, setSortField] = useState<string | null>(null);
    const [paginationModel, setPaginationModel] = useState<AdminUsersPaginationQuery>({} as AdminUsersPaginationQuery);

    const userAccountRepo = new UserAccountApi(new HttpClient());

    const init = async () => {
        setIsLoading(true);
        const result = await userAccountRepo.ListAccountStatus();

        if (result.isSuccess)
                setStatusList(result.value ?? []);
        else
            setStatusList([]);

        paginationModel.PageNum = pageIndex;
        paginationModel.PageSize = pageSize;
        setPaginationModel(paginationModel);

        await updateCurrentPageData();
    }

    const onSearchByUserNameChanged = (e: React.FormEvent<HTMLInputElement>): void => setSearchByUsername(e.currentTarget.value);
    const onSearchByNameChanged = (e: React.FormEvent<HTMLInputElement>): void => setSearchByName(e.currentTarget.value);
    const onSearchByCompanyNamyChanged = (e: React.FormEvent<HTMLInputElement>): void => setSearchByCompanyName(e.currentTarget.value);
    const onStatusQueryChanged = (e: React.FormEvent): void => setSearchByStatus((e as React.FormEvent<HTMLInputElement>).currentTarget.value);

    const updateSearchData = async () => {
        paginationModel.Email = searchByUsername;
        paginationModel.Name = searchByName;
        paginationModel.EmployerName = searchByCompanyName;
        paginationModel.Status = searchByStatus;
        paginationModel.PageNum = 0;
        setPaginationModel({ ...paginationModel });
        setPageIndex(0);
        await updateCurrentPageData();
    }

    const updateSorting = (sortField: string | null, sortDirection: string | null) => {
        setSortField(sortField);
        setSortDirection(sortDirection);
        paginationModel.SortDirection = sortDirection ?? '';
        paginationModel.SortField = sortField ?? '';
        setPaginationModel(paginationModel);
    }

    const updatePagination = (pageIndex: number, pageSize: number) => {
        setPageIndex(pageIndex);
        setPageSize(pageSize);
        paginationModel.PageNum = pageIndex;
        paginationModel.PageSize = pageSize;
        setPaginationModel(paginationModel);
    }

    const updateCurrentPageData = async (getManagerData = false) => {
        try {
            setIsLoading(true);

            const result = await userAccountRepo.ListUsersAccounts(paginationModel);

            if (result.isSuccess) {
                const page = result.value as PageResult<UserAccountModel> ?? new PageResult<UserAccountModel>();
                setCurrentPageData(page.pageData);
                setTotalCount(page.totalCount);
            } else {
                setCurrentPageData([]);
                setTotalCount(0);
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setIsShowError(true);
        }
    }

    return {
        statusList,
        currentPageData,
        searchByUsername,
        searchByName,
        searchByStatus,
        searchByCompanyName,
        pageIndex,
        pageSize,
        totalCount,
        isLoading,
        isShowError,

        init,
        updateSorting,
        updatePagination,
        updateCurrentPageData,
        updateSearchData,
        onStatusQueryChanged,
        setIsShowError,
        onSearchByUserNameChanged,
        onSearchByNameChanged,
        onSearchByCompanyNamyChanged
    }

}

export default useAllUsersViewModel;
