import React from "react";
import { Col, Row } from "react-bootstrap";
import PrivacyPolicy from "../../views/PrivacyPolicy";
import TermsOfService from "../../views/TermsAndConditions";
import { Control, UseFormWatch } from "react-hook-form";
import CustomInput from "../CustomInput";
import PersonInfoEditState from "../../models/PersonInfoEditState";

type Props = {
    introStatement: React.ReactNode;
    control: Control<PersonInfoEditState, any>;
    watch: UseFormWatch<PersonInfoEditState>;
    onNext: () => void;
    onCancel: () => void;
}

const AcceptTerms: React.FC<Props> = ({ introStatement, control, watch, onNext, onCancel }) => {

    const isTermsOfServiceChecked = watch("isTermsOfServiceChecked");
    const isPrivacyPolicyChecked = watch("isPrivacyPolicyChecked");

    return <>
        <div className="registration accept-terms main-page">
            <div className="section-wrapper">
                {introStatement}
                <Row className="current-step"><img className="p-0" src="/images/accept_terms.svg" /></Row>
                <Row>
                    <Col md="auto" className="p-0 section-title">Terms of Service</Col>
                    <Col><hr /></Col>
                </Row>
                <Row><TermsOfService collapsed={true} /></Row>
                <br />
                <Row className="input-mb">
                    <Col md="auto" className="p-0">
                        <CustomInput
                            type="checkbox"
                            name="isTermsOfServiceChecked"
                            control={control}
                            label="I have read and agree to the Terms of Service"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="auto" className="p-0 section-title">Privacy Policy</Col>
                    <Col><hr /></Col>
                </Row>
                <Row><PrivacyPolicy collapsed={true} /></Row>
                <br />
                <Row>
                    <Col md="auto" className="p-0">
                        <CustomInput
                            type="checkbox"
                            name="isPrivacyPolicyChecked"
                            control={control}
                            label="I have read and agree to the Privacy Policy"
                        />
                    </Col>
                </Row>
                <Row><hr /></Row>
                <Row>
                    <Col><a onClick={onCancel} className="btn cancel-btn">Cancel</a></Col>
                    <Col md="auto"><button type="button" disabled={!isTermsOfServiceChecked || !isPrivacyPolicyChecked} onClick={onNext} className="btn next-btn">Next &nbsp;&nbsp;&nbsp; <span>&rarr;</span></button></Col>
                </Row>
            </div >
        </div>
    </>
};

export default AcceptTerms;
