import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import App from "./App";
import rootReducer from "./lib/infrastructure/redux/reducer";
import Layout from "./lib/presentation/components/Layout";
import "./assets/main.scss";
import "react-toastify/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

const store = createStore(rootReducer);
ReactDOM.render(
  <Provider store={store}>
    <ToastContainer
      position="top-center"
      autoClose={1000}
      hideProgressBar
      newestOnTop={false}
      closeButton={false}
      limit={1}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
      className="avoid-nav-bar"
      bodyClassName="toast-message"
    />
    <Layout>
      <App />
    </Layout>
  </Provider>,
  document.getElementById("root")
);
