import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import UserSettingApi from "../../../data/usersettings/UserSettingApi";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import { RootState } from "../../../infrastructure/redux/reducer";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import UserProfileViewModel from "../../view-model/usersettings/UserProfileViewModel";
import TermsAndConditionsAndPrivacyPolicy from "../../components/person-info/TermsAndConditionsAndPrivacyPolicy";
import AuthorizedManagerRequestApi from "../../../data/authorizedManagerRequest/AuthorizedManagerRequestApi";
import AuthorizedManagerRequestButton from "../../components/user-settings/AuthorizedManagerRequestButton";
import AuthorizedManagerRequestModal from "../../components/user-settings/AuthorizedManagerRequestModal";

type UserInfoComponentState = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;

    alternativeFirstName: string;
    alternativeLastName: string;
    alternativePhoneNumber: string;
    alternativeEmail: string;
    secondaryEmail: string;

    isAuthorizedManager: boolean;
    managerName: string;
    employerName: string;
    employerAddress: string;
    employerCity: string;
    employerState: string;
    employerZIPCode: string;
    claimOffice: string;

    lastTosAcceptedDate: Date | null;

    validation: any;
    isLoading: boolean;
    isSuccess: boolean;
    isShowError: boolean;
    errorMessages: string[];
    isValidForm: boolean;
    requiresClaimOffice: boolean;
    showAuthorizedManagerRequestModal: boolean;
    isAuthorizedManagerRequestPending: boolean;
};

type Props = {
    onIsAuthorizedManagerChanged: (isAuthorizedManager: boolean) => void;
}

const UserInfo: React.FC<Props> = (props: Props) => {

  const { viewModel, subscription } = useViewModel(UserProfileViewModel, [
      new UserSettingApi(new HttpClient()),
      new AuthorizedManagerRequestApi(new HttpClient())
  ]);

  const [state, setState] = useState<UserInfoComponentState>({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',

      alternativeFirstName: '',
      alternativeLastName: '',
      alternativePhoneNumber: '',
      alternativeEmail: '',
      secondaryEmail: '',

      isAuthorizedManager: false,
      managerName: '',
      employerName: '',
      employerAddress: '',
      employerCity: '',
      employerState: '',
      employerZIPCode: '',
      claimOffice: '',

      lastTosAcceptedDate: null,

      validation: {},
      isSuccess: false,
      isLoading: false,
      isShowError: false,
      errorMessages: [],
      isValidForm: false,
      requiresClaimOffice: false,
      showAuthorizedManagerRequestModal: false,
      isAuthorizedManagerRequestPending: false
  });

    const userProfile = useSelector((s: RootState) => s.userProfile);

  useEffect(() => {
    const subscriber = subscription.subscribe((d) =>
      setState({ ...state, ...d.data })
    );
    
      viewModel.setData(userProfile);
      viewModel.isAuthorizedManagerPendingRequestExist();

    return () => {
      subscriber.unsubscribe();
    };
  }, []);

  const notify = () => {
    toast.success("Profile updated successfully.", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      theme: "dark",
      progress: undefined,
      className: "avoid-nav-bar",
      bodyClassName: "toast-message",
    });
  };
  if (viewModel.isSaveSuccess) {
    notify();
    viewModel.isSaveSuccess = false;
    }

  return (
    <>
      <div className="user-info-div">
        <div className="form-style-one">
          <div className="row gx-xxl-5">
            <div className="col-lg-8 order1">
              <form
                id="contact-form"
                className="main-form"
                onSubmit={(e: any) => e.preventDefault()}
              >
                <div className="row">
                <Row className="header-section">
                    <Col md="auto" className="p-0 section-title">Personal Information</Col>
                    <Col><hr /></Col>
                  </Row>
                  <div className="col-md-6">
                    <b className="email-p">Email: {state.email}</b>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                      <input autoComplete="new-password"
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        id="firstName"
                        onChange={viewModel.onFirstNameChanged}
                        value={state.firstName}
                        className={` form-control ${state.validation.firstName ? "is-invalid" : ""
                          }`}
                      />
                      <label className="form-label">First Name<span className="mandatory-symbol">*</span></label>

                      {state.validation.firstName && (
                        <div className="invalid-feedback">
                          {state.validation.firstName}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                      <input autoComplete="new-password"
                        type="text"
                        placeholder="Last name"
                        name="lastName"
                        id="lastName"
                        onChange={viewModel.onLastNameChanged}
                        value={state.lastName}
                        className={` form-control ${state.validation.lastName ? "is-invalid" : ""
                          }`}
                      />
                      <label className="form-label">Last name<span className="mandatory-symbol">*</span></label>

                      {state.validation.lastName && (
                        <div className="invalid-feedback">
                          {state.validation.lastName}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                      <input autoComplete="new-password"
                        type="text"
                        placeholder="phone"
                        name="phoneNumber"
                        id="phoneNumber"
                        onChange={viewModel.onPhoneNumberChanged}
                        value={state.phoneNumber}
                        className={` form-control ${state.validation.phone ? "is-invalid" : ""
                          }`}
                      />
                      <label className="form-label">phone<span className="mandatory-symbol">*</span></label>

                      {state.validation.phone && (
                        <div className="invalid-feedback">
                          {state.validation.phone}
                        </div>
                      )}
                    </div>
                  </div>

                  <Row className="authorized-manager">
                    <Col md="auto" className="d-flex"><input disabled className="p-0 m-0" id="isAuthorizedManager" type="checkbox" checked={state.isAuthorizedManager} /></Col>
                    <Col className="p-0 col-md-auto d-ruby"><label htmlFor="isAuthorizedManager"><span className="auth-manager-txt">Authorized Manager</span></label></Col>
                    <AuthorizedManagerRequestButton isChecked={state.isAuthorizedManager} isPendingRequestExist={state.isAuthorizedManagerRequestPending} onClick={viewModel.openAuthorizedManagerRequestModal} />
                  </Row>

                  <Row className="header-section">
                    <Col md="auto" className="p-0 section-title">Alternative Contact</Col>
                    <Col><hr /></Col>
                  </Row>

                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                      <input autoComplete="new-password"
                      type="text"
                      placeholder="Alternative First Name"
                      name="alternativeFirstName"
                      id="alternativeFirstName"
                      onChange={viewModel.onQueryChanged}
                      value={state.alternativeFirstName}
                      className="form-control"
                      />
                      <label className="form-label">Alternative First Name</label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                       <input autoComplete="new-password"
                       type="text"
                       placeholder="Alternative Last Name"
                       name="alternativeLastName"
                       id="alternativeLastName"
                       onChange={viewModel.onQueryChanged}
                       value={state.alternativeLastName}
                       className="form-control"
                       />
                       <label className="form-label">Alternative Last Name</label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                       <input autoComplete="new-password"
                       type="text"
                       placeholder="Alternative Phone Number"
                       name="alternativePhoneNumber"
                       id="alternativePhoneNumber"
                       onChange={viewModel.onAlternativePhoneNumberChanged}
                       value={state.alternativePhoneNumber}
                       className={` form-control ${state.validation.alternativePhoneNumber ? "is-invalid" : ""}`}
                       />
                       <label className="form-label">Alternative Phone Number</label>
                       {state.validation.alternativePhoneNumber && (
                          <div className="invalid-feedback">
                             {state.validation.alternativePhoneNumber}
                          </div>
                       )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                       <input autoComplete="new-password"
                       type="text"
                       placeholder="Alternative Email"
                       name="alternativeEmail"
                       id="alternativeEmail"
                       onChange={viewModel.onAlternativeEmailChanged}
                       value={state.alternativeEmail}
                       className={` form-control ${state.validation.alternativeEmail ? "is-invalid" : ""}`}
                       />
                       <label className="form-label">Alternative Email</label>
                       {state.validation.alternativeEmail && (
                          <div className="invalid-feedback">
                             {state.validation.alternativeEmail}
                          </div>
                       )}
                    </div>
                  </div>

                  <Row className="header-section">
                    <Col md="auto" className="p-0 section-title">Account/Password Recovery</Col>
                    <Col><hr /></Col>
                  </Row>

                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                       <input autoComplete="new-password"
                       type="text"
                       placeholder="Secondary Email"
                       name="secondaryEmail"
                       id="secondaryEmail"
                       onChange={viewModel.onSecondaryEmailChanged}
                       value={state.secondaryEmail}
                       className={` form-control ${state.validation.secondaryEmail ? "is-invalid" : ""}`}
                       />
                       <label className="form-label">Secondary Email</label>
                       {state.validation.secondaryEmail && (
                          <div className="invalid-feedback">
                             {state.validation.secondaryEmail}
                          </div>
                       )}
                    </div>
                  </div>

                  <Row className="header-section">
                    <Col md="auto" className="p-0 section-title">Company Information</Col>
                    <Col><hr /></Col>
                  </Row>

                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                      <input autoComplete="new-password"
                        type="text"
                        placeholder="Manager Name"
                        name="managerName"
                        id="managerName"
                        onChange={viewModel.onManagerNameChanged}
                        value={state.managerName}
                        className={` form-control ${state.validation.managerName ? "is-invalid" : ""}`}
                      />
                      <label className="form-label">Manager Name</label>
                      {state.validation.managerName && (
                          <div className="invalid-feedback">
                             {state.validation.managerName}
                          </div>
                       )}
                    </div>
                  </div>
                  
                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                      <input autoComplete="new-password"
                        type="text"
                        placeholder="Employer name"
                        name="employerName"
                        id="employerName"
                        className={` form-control ${state.validation.employerName ? "is-invalid" : ""}`}
                        onChange={viewModel.onEmployerNameChanged}
                        value={state.employerName}
                      />
                      <label className="form-label">Employer name<span className="mandatory-symbol">*</span></label>
                      {state.validation.employerName && (
                          <div className="invalid-feedback">
                             {state.validation.employerName}
                          </div>
                       )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                      <input autoComplete="new-password"
                        type="text"
                        placeholder="Employer address"
                        className={` form-control ${state.validation.employerAddress ? "is-invalid" : ""}`}
                        name="employerAddress"
                        id="employerAddress"
                        onChange={viewModel.onEmployerAddressChanged}
                        value={state.employerAddress}
                      />
                      <label className="form-label">Employer address<span className="mandatory-symbol">*</span></label>
                      {state.validation.employerAddress && (
                          <div className="invalid-feedback">
                             {state.validation.employerAddress}
                          </div>
                       )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                      <input autoComplete="new-password"
                        type="text"
                        placeholder="Employer city"
                        className={` form-control ${state.validation.employerCity ? "is-invalid" : ""}`}
                        name="employerCity"
                        id="employerCity"
                        onChange={viewModel.onEmployerCityChanged}
                        value={state.employerCity}
                      />
                      <label className="form-label">
                        Employer city<span className="mandatory-symbol">*</span>
                      </label>
                      {state.validation.employerCity && (
                          <div className="invalid-feedback">
                             {state.validation.employerCity}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                      <input autoComplete="new-password"
                        type="text"
                        placeholder="Employer state"
                        className={` form-control ${state.validation.employerState ? "is-invalid" : ""}`}
                        name="employerState"
                        id="employerState"
                        onChange={viewModel.onEmployerStateChanged}
                        value={state.employerState}
                      />
                      <label className="form-label">
                        Employer state<span className="mandatory-symbol">*</span>
                      </label>
                      {state.validation.employerState && (
                          <div className="invalid-feedback">
                             {state.validation.employerState}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                      <input autoComplete="new-password"
                        type="text"
                        placeholder="Employer zipCode"
                        className={` form-control ${state.validation.employerZIPCode ? "is-invalid" : ""}`}
                        name="employerZIPCode"
                        id="employerZIPCode"
                        onChange={viewModel.onEmployerZIPCodeChanged}
                        value={state.employerZIPCode}
                      />
                      <label className="form-label">
                        Employer zipCode<span className="mandatory-symbol">*</span>
                      </label>
                      {state.validation.employerZIPCode && (
                          <div className="invalid-feedback">
                             {state.validation.employerZIPCode}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group-meta form-group   form-floating">
                      <input autoComplete="new-password"
                        type="text"
                        placeholder="Claim Office"
                        className={` form-control ${state.validation.claimOffice ? "is-invalid" : ""}`}
                        name="claimOffice"
                        id="claimOffice"
                        maxLength={100}
                        onChange={viewModel.onClaimOfficeChanged}
                        value={state.claimOffice}
                      />
                      <label className="form-label">
                        Claim Office{state.requiresClaimOffice && (<span className="mandatory-symbol">*</span>)}
                      </label>
                      {state.validation.claimOffice && (
                          <div className="invalid-feedback">
                             {state.validation.claimOffice}
                          </div>
                        )}
                    </div>
                  </div>

                  <TermsAndConditionsAndPrivacyPolicy DateOfAcceptance={state.lastTosAcceptedDate}/>

                  <TailSpin
                    wrapperClass="cover-spin"
                    visible={state.isLoading}
                    height={50}
                    width="50"
                    color="#FF5733"
                    ariaLabel="loading"
                  />

                  <br />

                  <div className="btn-row">
                    <button
                      disabled={!state.isValidForm}
                      name="register"
                      className="btn next-btn"
                      onClick={viewModel.onSave}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
          </div>
          <AuthorizedManagerRequestModal isShow={state.showAuthorizedManagerRequestModal} onClose={viewModel.CloseAuthorizedManagerRequestModal} onAddingRequest={viewModel.onAddingAuthorizedManagerRequest} />
          <Modal show={state.isShowError}>
              <Modal.Body className="modal-card warning-modal-card error-modal">
                  <div>
                      <Row className="warning-modal-header">
                          <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                          <Col ><span className="cancel-modal-title">Server Error</span></Col>
                          <Col md="auto" className="close-modal-icon"><span onClick={() => setState({ ...state, isShowError: false })}>x</span></Col>
                      </Row>
                      <Row>
                          <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                      </Row>
                      <Row className="warning-modal-btn-row">
                          <Col className="p-0"><a onClick={() => setState({ ...state, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
                      </Row>
                  </div>
              </Modal.Body>
          </Modal>
    </>
  );
};

export default UserInfo;
