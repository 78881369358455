import Result from "../../../domain/common/Result";
import SearchDetailsModel from "../../../domain/entities/search/SearchDetailsModel";
import SocialPlatformProfileModel from "../../../domain/entities/searchoutput/SocialPlatformProfileModel";
import VisibilityFlagsModel from "../../../domain/entities/searchoutput/VisibilityFlagsModel";
import SearchOutputApi from "../../../data/searchoutput/SearchOutputApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import SocialResultApi from "../../../data/searchoutput/SocialResultApi";
import SearchApi from "../../../data/search/SearchApi";
import { useDispatch, useSelector } from "react-redux";
import { setSearchResult } from "../../../infrastructure/redux/actions/searchResult";
import { RootState } from "../../../infrastructure/redux/reducer";
import { setSearchDetails } from "../../../infrastructure/redux/actions/searchDetails";
import SearchResultModel from "../../../domain/entities/searchoutput/SearchResultModel";
import UserSocialProfileModel from "../../../domain/entities/search/UserSocialProfileModel";

function useHomeViewModel() {

    const searchoutputRepository = new SearchOutputApi(new HttpClient());
    const socialResultRepository = new SocialResultApi(new HttpClient());
    const searchRepository = new SearchApi(new HttpClient());

    const dispatch = useDispatch();
    const searchResult = useSelector((state: RootState) => state.searchResult) as SearchResultModel;

    const scrollHandler = () => window.scrollY > 200 ? dispatch(setSearchResult({ displayScrollView: true })) : dispatch(setSearchResult({ displayScrollView: false }));
    const scrollToTop = () => window.scrollTo(0, 0);

    const openAddModal = (id: string | undefined) => dispatch(setSearchResult({ showAddModal: true, selectedItemId: id === undefined ? 0 : +id }));
    const closeAddModal = () => dispatch(setSearchResult({ showAddModal: false }));
    const togglePrintView = () => dispatch(setSearchResult({ isPrintView: !searchResult.isPrintView }));

    const loadSearchResult = async (id: number) => {
        try {
            dispatch(setSearchResult({ searchRequestId: id, isLoading: true }));

            const result = await searchoutputRepository.get(id);
            const details = await searchRepository.getSearchDetail(id);

            if (!result.isSuccess && result.statusCode == 423)
                dispatch(setSearchResult({ isLoading: false, isExpiredSearchResult: true }));
            else if (!result.isSuccess || !details.isSuccess)
                dispatch(setSearchResult({ isLoading: false, isShowError: true }));
            else {
                const headerModel = {
                    id: id,
                    caseId: result?.value?.caseId,
                    caseName: result?.value?.caseName,
                    name: result?.value?.name,
                    age: result?.value?.age,
                    imageUrl: result?.value?.pictureUrl,
                    address: result?.value?.addresses?.at(0)?.addressLine,
                    email: result?.value?.emails?.at(0)?.email,
                    phone: result?.value?.phones?.at(0)?.phoneNumber,
                    searchDetails: details.isSuccess ? details.value as SearchDetailsModel : {} as SearchDetailsModel
                };
                const socialResults = setUserNameOfResultData(flattenThenOrderByData(groupByScore(result?.value?.socialResults ?? [])));
                dispatch(setSearchResult({ ...result.value, headerModel: headerModel, socialResults: socialResults, dataFetched: true, isLoading: false }));
                dispatch(setSearchDetails(details?.value ?? {} as SearchDetailsModel));
            }
        } catch (e: any) {
            dispatch(setSearchResult({ isLoading: false, isShowError: true, errorMessages: [e.message] }));
            return Result.Fail(e.message, 500);
        }
    };

    const groupByScore = (socialMediaList: SocialPlatformProfileModel[]): { score: number | undefined, media: SocialPlatformProfileModel[] }[] => {

        const groupedMedia: { score: number | undefined, media: SocialPlatformProfileModel[] }[] = [];

        socialMediaList.forEach(medium => {
            const sameScoreMediaIndex = groupedMedia.length > 0 ? groupedMedia.findIndex(m => m.score == medium.score) : -1;
            sameScoreMediaIndex >= 0 ? groupedMedia[sameScoreMediaIndex].media.push(medium) : groupedMedia.push({ score: medium.score, media: [medium] });
        })
        return groupedMedia;
    };

    const flattenThenOrderByData = (groupedMedia: { score: number | undefined, media: SocialPlatformProfileModel[] }[]): SocialPlatformProfileModel[] => {

        let orderedMedia: SocialPlatformProfileModel[] = [];

        groupedMedia.forEach(group => {
            const withData = group.media.filter(m => m.resultsWithData.length > 0).map(m => { return { resultsWithData: m.resultsWithData, resultsWithNoData: [], socialPlatform: m.socialPlatform, score: m.score } as SocialPlatformProfileModel });
            const withNoData = group.media.filter(m => m.resultsWithNoData.length > 0).map(m => { return { resultsWithNoData: m.resultsWithNoData, resultsWithData: [], socialPlatform: m.socialPlatform, score: m.score } as SocialPlatformProfileModel });
            orderedMedia = orderedMedia.concat(withData).concat(withNoData);
        });
        return orderedMedia;
    };

    const setUserNameOfResultData = (media: SocialPlatformProfileModel[]) => {
        media.forEach(m => {
            m.resultsWithData.forEach(d => {
                if (!d.username && d.resultData) {
                    const parsedResultData = JSON.parse(d.resultData);
                    let username = parsedResultData['username'];
                    username = username?.includes('@') ? username?.split('@')?.at(1) : username;
                    d.username = username;
                }
            })
        })
        return media;
    }

    const downloadExcelReport = async (id: number, visibilityFlagsModel: VisibilityFlagsModel): Promise<Result<Blob | string>> => {
        try {
            dispatch(setSearchResult({ isDownloading: true }));
            const result = await searchoutputRepository.downloadExcelReport(id, visibilityFlagsModel);

            if (!result.isSuccess)
                dispatch(setSearchResult({ isDownloading: false, isShowError: true, errorMessages: [result.error] }));
            else
                dispatch(setSearchResult({ isDownloading: false }));

            if (result.isSuccess && result.value) {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([result.value]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Social E-Profiler Result.xlsx");

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                if (link.parentNode) link.parentNode.removeChild(link);
            }
            return result;
        } catch (e: any) {
            dispatch(setSearchResult({ isDownloading: false, isShowError: true, errorMessages: [e.message] }));
            return Result.Fail(e.message, 500);
        }
    };

    const setShowPDF = (isShowPDF: boolean) => dispatch(setSearchResult({ isShowPDF: isShowPDF, isDownloading: isShowPDF }));

    const fetchDeletedSocialResults = async (searchId: number): Promise<Result<SocialPlatformProfileModel[] | null>> => {
        try {
            const result = await socialResultRepository.fetchDeleted(searchId);
            return result;
        } catch (e: any) {
            dispatch(setSearchResult({ isShowError: true, errorMessages: [e.message] }));
            return Result.Fail(e.message, 500);
        }
    };

    const removeEmail = async (id: number) => {
        dispatch(setSearchResult({ isLoading: true }));
        const result = await searchoutputRepository.softDeleteEmail(id);
        dispatch(setSearchResult({ isShowError: !result.isSuccess }));
        await loadSearchResult(searchResult.searchRequestId ?? 0);
    }

    const removeSocialResult = async (id: number) => {
        dispatch(setSearchResult({ isLoading: true }));
        const result = await socialResultRepository.softDelete(id);
        dispatch(setSearchResult({ isShowError: !result.isSuccess }));
        await loadSearchResult(searchResult.searchRequestId ?? 0);
    }

    const restoreSocialResult = async (id: number) => {
        dispatch(setSearchResult({ isLoading: true }));
        const result = await socialResultRepository.restore(id);
        dispatch(setSearchResult({ isShowError: !result.isSuccess }));
        await loadSearchResult(searchResult.searchRequestId ?? 0);
    }

    const addUserSocialProfile = async (url: string) => {
        dispatch(setSearchResult({ isLoading: true }));
        try {

            const result = await socialResultRepository.AddUserSocialProfile({ SearchId: searchResult.searchRequestId ?? 0, Url: url } as UserSocialProfileModel);
            if (result.isSuccess && result.value) {
                searchResult.socialResults?.unshift(result.value)
                dispatch(setSearchResult({ isLoading: false, socialResults: searchResult.socialResults}));
            } else {
                dispatch(setSearchResult({ isLoading: false, isShowError: true, errorMessages: ["Error in adding User Social Profile"] }));
            }
        } catch (e: any) {
            dispatch(setSearchResult({ isLoading: false, isShowError: true, errorMessages: ["Error in adding User Social Profile"] }));
        }
    }

    return {
        scrollHandler,
        scrollToTop,
        openAddModal,
        closeAddModal,
        togglePrintView,
        loadSearchResult,
        downloadExcelReport,
        setShowPDF,
        fetchDeletedSocialResults,
        removeEmail,
        removeSocialResult,
        restoreSocialResult,
        addUserSocialProfile
    };
}

export default useHomeViewModel;