import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import ResultCount from "./ResultCount";
import { RootState } from "../../../infrastructure/redux/reducer";
import { useSelector } from "react-redux";
import SearchResultModel from "../../../domain/entities/searchoutput/SearchResultModel";


const Relatives: React.FC = () => {
    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    
    return (
        <Fragment>
            <Row><ResultCount count={searchResult?.relatives?.length ?? 0} text={"Relatives"} /></Row>
            <Row className="relatives p-0">
                {searchResult?.relatives?.map(relative =>
                    <Col md={4} key={Math.random()}>
                        <Col className="relative-card box-shadow">
                            <Row className="m-0 align-items-center">
                                <Col className="col-auto img-box px-2">
                                    <img width='30px' height='18px' src="/images/search-results/relatives.png" />
                                </Col>
                                <Col md="auto" className="relative-name">{relative.name.firstName} {relative.name.middleName} {relative.name.lastName}</Col>
                            </Row>
                            <hr />
                            <Row>
                                <Row className="data-row" >
                                    <Col className="col-auto img-box-clear px-2"><img width='30px' height='18px' src="/images/search-results/age.png" /></Col>
                                    <Col md="auto" className="relative-text">Age:&nbsp;</Col>
                                    <Col md="auto" className="relative-text val">{relative.age ? relative.age : <span className="not-available">Not Available</span>}</Col>
                                </Row>
                                <Row className="data-row" >
                                    <Col className="col-auto img-box-clear px-2"><img width='30px' height='18px' src="/images/search-results/dateofbirth.png" /></Col>
                                    <Col md="auto" className="relative-text">Date Of Birth:&nbsp;</Col>
                                    <Col md="auto" className="relative-text val">{relative.dateOfBirth ? relative.dateOfBirth : <span className="not-available">Not Available</span>}</Col>
                                </Row>
                                <Row className="data-row" >
                                    <Col className="col-auto img-box-clear px-2"><img width='30px' height='18px' src="/images/search-results/dateofdeath.png" /></Col>
                                    <Col md="auto" className="relative-text">Date Of Death:&nbsp;</Col>
                                    <Col md="auto" className="relative-text val">{relative.dateOfDeath ? relative.dateOfDeath : <span className="not-available">Not Available</span>}</Col>
                                </Row>
                            </Row>
                        </Col>
                    </ Col >
                )}
            </Row>
        </Fragment>
    );
};

export default Relatives;
