import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import ResultCount from "./ResultCount";
import MasonryView from "../MasonryView";
import { RootState } from "../../../infrastructure/redux/reducer";
import { useSelector } from "react-redux";
import SearchResultModel from "../../../domain/entities/searchoutput/SearchResultModel";


const PDFInternetSearches: React.FC = () => {
    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    
    return (
        <Fragment>
            <Row><ResultCount count={searchResult?.internetSearches?.length ?? 0} text={"Internet Searches"} /></Row>
            <div className="searches p-0">
                {searchResult?.internetSearches && < MasonryView columnCount={3}>
                    {searchResult?.internetSearches?.map(search =>
                        <div key={Math.random()}>
                            <Col className="search-card box-shadow">
                                <Row>
                                    <Col className="col-auto img-box px-2">
                                        <img width='30px' height='18px' src="/images/search-results/search.png" />
                                    </Col>
                                    <Col md="auto" className="val url">{search.url}</Col>
                                </Row>
                                <hr />
                                <Row className="data-row">
                                    <Col md="auto" className="title">Title:</Col>
                                    <Col md="auto" className="val">{search.searchTitle}</Col>
                                </Row>
                                <Row className="data-row">
                                    <Col md="auto" className="title">Brief:</Col>
                                    <Col md="auto" className="val">{search.searchContent ?? <span className="not-available">Not Available</span>}</Col>
                                </Row>
                            </Col>
                        </div >
                    )}
                </MasonryView>}
            </div>
        </Fragment>
    );
};

export default PDFInternetSearches;
