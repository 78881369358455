import React from "react";
import { Col, Row } from "react-bootstrap";
import PersonInfoEditState from "../../models/PersonInfoEditState";
import { Control } from "react-hook-form";
import CustomSelect from "../CustomSelect";

type Props = {
    disabled?: boolean,
    state: PersonInfoEditState,
    control: Control<PersonInfoEditState, any>
}

const LinkedToCompany: React.FC<Props> = ({ state, control, disabled = false }: Props) => {

    return (
        <Row className="account-request-section account-request-section--company">
            <Row className="header-section">
                <Col md="auto" className="p-0 section-title">Linked to Company</Col>
                <Col><hr /></Col>
            </Row>
            <Row className="info-section">
                <Row className="titile-row">
                    <Col md="6"><span className="title">Company Name</span><span className="mandatory-symbol">*</span></Col>
                </Row>
                <Row className="data-row">
                    <Col md="6" className="input-group-meta form-group mb-30 form-floating first-col-input">
                        <CustomSelect
                            name="selectedCompany"
                            control={control}
                            placeholder=""
                            label="Name"
                            options={state?.companyList ?? []}
                            required
                            hasRequiredSymbol
                            requiredMessage="Company can not be empty"
                            isDisabled={disabled}
                        />
                    </Col>
                </Row>
            </Row>
        </Row>
    );
}

export default LinkedToCompany;