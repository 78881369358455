import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import useVisibility from "../../../infrastructure/hooks/useVisibility";
import RelativesSvgIcon from "../svg-icons/RelativesSvgIcon";
import HideTabToggle from "./HideTabToggle";
import ResultCount from "./ResultCount";
import SearchResultModel from "../../../domain/entities/searchoutput/SearchResultModel";
import { RootState } from "../../../infrastructure/redux/reducer";


const Relatives: React.FC = () => {

    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    const dispatch = useDispatch();
    const { visibilityFlags, actions } = useVisibility();

    const handleToggle = () => {
        dispatch(actions.setRelativesVisibility(!visibilityFlags.isRelativesHidden));
    };

    useEffect(() => {
        if (searchResult?.dataFetched && searchResult?.relatives?.length === 0) {
            dispatch(actions.setRelativesVisibility(true));
        }
    }, [searchResult?.relatives]);

    return (
        <Fragment>
            <Row className="my-3 mx-2 result-header">
                <Col>
                    <ResultCount count={searchResult?.relatives?.length ?? 0} text={searchResult?.isPrintView ? "Relatives" : null} />
                </Col>
                <Col md="auto">
                    {!searchResult?.isPrintView && <HideTabToggle isHidden={searchResult?.relatives?.length == 0 ? true : visibilityFlags.isRelativesHidden} onToggle={handleToggle} />}
                </Col>
            </Row>
            <hr />
            <Row className="relatives p-0">
                {searchResult?.relatives?.map(relative =>
                    <Col md={4} key={Math.random()}>
                        <Col className="relative-card box-shadow">
                            <Row className="m-0 align-items-center">
                                <Col md="auto" className="img-box ml-3"><RelativesSvgIcon /></Col>
                                <Col md="auto" className="relative-name">{relative.name.firstName} {relative.name.middleName} {relative.name.lastName}</Col>
                            </Row>
                            <hr />
                            <Row>
                                <Row className="data-row" >
                                    <Col md="auto"><img src="/images/age.svg" /></Col>
                                    <Col md="auto" className="relative-text">Age:&nbsp;</Col>
                                    <Col md="auto" className="relative-text val">{relative.age ? relative.age : <span className="not-available">Not Available</span>}</Col>
                                </Row>
                                <Row className="data-row" >
                                    <Col md="auto"><img src="/images/date of Birth.svg" /></Col>
                                    <Col md="auto" className="relative-text">Date Of Birth:&nbsp;</Col>
                                    <Col md="auto" className="relative-text val">{relative.dateOfBirth ? relative.dateOfBirth : <span className="not-available">Not Available</span>}</Col>
                                </Row>
                                <Row className="data-row" >
                                    <Col md="auto"><img src="/images/date of Death.svg" /></Col>
                                    <Col md="auto" className="relative-text">Date Of Death:&nbsp;</Col>
                                    <Col md="auto" className="relative-text val">{relative.dateOfDeath ? relative.dateOfDeath : <span className="not-available">Not Available</span>}</Col>
                                </Row>
                            </Row>
                        </Col>
                    </ Col >
                )}
            </Row>
        </Fragment>
    );
};

export default Relatives;
