import React, { useEffect, useState } from "react";
import BootstrapTable, { ColumnDescription, TableChangeState } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { Helmet } from "react-helmet";
import useAllUsersViewModel from "../../view-model/admin/AllUsersViewModel_new";

const AllUsers: React.FC = () => {

    const {
        statusList,
        currentPageData,
        searchByUsername,
        searchByName,
        searchByStatus,
        searchByCompanyName,
        pageIndex,
        pageSize,
        totalCount,
        isLoading,
        isShowError,

        init,
        updateSorting,
        updatePagination,
        updateCurrentPageData,
        updateSearchData,
        onStatusQueryChanged,
        setIsShowError,
        onSearchByUserNameChanged,
        onSearchByNameChanged,
        onSearchByCompanyNamyChanged
    } = useAllUsersViewModel();

    useEffect(() => {
        init();
    }, []);


    const columns: ColumnDescription[] = [
        {
            dataField: "username",
            text: "Email",
            formatter: (cell, row) => (<a className="username-link" href={`/admin/user/${row.userId}`}>{cell}</a >),
            sort: true,
        },
        {
            dataField: "name",
            text: "Name",
            formatter: (_, row) => `${row.firstName} ${row.lastName}`,
            sort: true,
        },
        {
            dataField: "companyName",
            text: "Employer Name",
            sort: true,
        },
        {
            dataField: "lastLogin",
            text: "Last Login Date",
            formatter: (cell, row) => row.lastLogin ? new Date(row.lastLogin).toLocaleDateString() : '',
            sort: true,
        },
        {
            dataField: "userId",
            text: "Actions",
            formatter: (c) => (<span>
                <a href={`/admin/user/${c}/search-requests`} className="btn next-btn show-btn">Show Search Requests</a>
                <a href={`/admin/user/${c}/view-permissions`} className="btn next-btn show-btn">Show View Permissions</a>
            </span>),
        }
    ];

    const onTableChange = (type: any, newState: TableChangeState<any>) => {
        if (type === "sort")
            updateSorting(newState.sortField, newState.sortOrder);
        else if (type === "pagination")
            updatePagination(newState.page - 1, newState.sizePerPage);
        updateCurrentPageData();
    };

    const onPageSizeChange = (pageSize: number, page: number) => updatePagination(page - 1, pageSize);
    const search = () => updateSearchData();

    return (
        <div className="container">
            <Helmet>
                <title>Social E-Profiler - All Users</title>
                <meta name="description" content="Social E-Profiler" />
            </Helmet>
            <div>
                <div className="admin-page-title">All Users</div>
                <Row className="all-users-filters">
                    <Row>
                        <Col className=""> <input autoComplete="new-password" id="searchByUsername" placeholder="Email" className="form-control" type="text" value={searchByUsername} onChange={onSearchByUserNameChanged} /> </Col>
                        <Col className=""> <input autoComplete="new-password" id="searchByName" placeholder="Name" className="form-control" type="text" value={searchByName} onChange={onSearchByNameChanged} /> </Col>
                        <Col className=""> <input autoComplete="new-password" id="searchByCompanyName" placeholder="Employer Name" className="form-control" type="text" value={searchByCompanyName} onChange={onSearchByCompanyNamyChanged} /> </Col>
                        <Col className=""> <select placeholder="User Account Status" className="form-control" value={searchByStatus} onChange={onStatusQueryChanged} > <option value={""}>Status</option> {statusList.map(s => (<option key={s.id} value={s.name}>{s.name}</option>))} </select> </Col>
                    </Row>
                    <Row>
                        <Col><button className="btn next-btn" onClick={search}>Search</button></Col>
                    </Row>
                </Row>
                <div className="table-style mt-4">
                    <BootstrapTable
                        keyField="caseId"
                        bordered={false}
                        data={currentPageData}
                        columns={columns}
                        headerClasses="header-tr"
                        remote
                        pagination={paginationFactory({
                            pageStartIndex: 1,
                            page: pageIndex + 1,
                            sizePerPage: pageSize,
                            totalSize: totalCount,
                            firstPageText: "First",
                            prePageText: "Back",
                            nextPageText: "Next",
                            lastPageText: "Last",
                            nextPageTitle: "First page",
                            prePageTitle: "Pre page",
                            firstPageTitle: "Next page",
                            lastPageTitle: "Last page",
                            onSizePerPageChange: onPageSizeChange,
                            sizePerPageList: [
                                { text: "10", value: 10 },
                                { text: "25", value: 25 },
                                { text: "50", value: 50 },
                                { text: "100", value: 100 },
                            ]
                        })}
                        onTableChange={onTableChange}
                    />
                </div>
            </div>
            <TailSpin
                wrapperClass="cover-spin"
                visible={isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
            <Modal show={isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setIsShowError(false)}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setIsShowError(false)} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AllUsers;
