import React, { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { Helmet } from "react-helmet";
import useAppSettingsViewModel from "../../view-model/admin/AppSettingsViewModel_new";


const AppSettings: React.FC = () => {

    const {
        lastVersionDate,
        isShowNewVersionModal,
        isLoading,
        isShowError,

        getData,
        hideNewVersionModal,
        showNewVersionModal,
        addNewVersion,
        setIsShowError
    } = useAppSettingsViewModel();

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="container">
            <Helmet>
                <title>Social E-Profiler - App Settings</title>
                <meta name="description" content="Social E-Profiler" />
            </Helmet>
            <Row className="title p-2">Terms and Conditions and Privacy Policy Version</Row>
            <Row>
                <Col className="version-last-date">Last Version Date: {new Date(lastVersionDate).toLocaleDateString()}</Col>
            </Row>
            <Row>
                <Col></Col>
                <Col md="auto"><button className="btn next-btn" onClick={showNewVersionModal}>New Version</button></Col>
            </Row>
            <Modal show={isShowNewVersionModal}>
                <Modal.Body className="new-version-modal">
                    <div className="">
                        <Row className="version-modal-header">
                            <Col><span className="cancel-modal-title">New Version</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={hideNewVersionModal}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="">Are you sure there is terms and conditions and privacy policy new version?</p>
                        </Row>
                        <Row>
                            <p className="">By clicking&nbsp;&apos;Yes&apos;&nbsp;all users have to accept the new version to be able to use the website after their next login.</p>
                        </Row>
                        <Row className="modal-btn-row">
                            <Col></Col>
                            <Col md="auto" className="p-0"><a onClick={hideNewVersionModal} className="btn previous-btn">Cancel</a></Col>
                            <Col md="auto" className="p-0"><a onClick={addNewVersion} className="btn next-btn">Yes</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
            <TailSpin
                wrapperClass="cover-spin"
                visible={isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
            <Modal show={isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setIsShowError(false)}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setIsShowError(false)} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default AppSettings;