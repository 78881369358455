import { TableChangeState } from "react-bootstrap-table-next";
import PaginationQuery from "../../../domain/common/PaginationQuery";
import CompanyModel from "../../../domain/entities/admin/models/CompanyModel";
import PageResult from "../../../domain/common/PageResult";
import { useEffect, useState } from "react";
import CompanyApi from "../../../data/admin/CompanyApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";

function useCompaniesViewModel() {

    const [companies, setCompanies] = useState<CompanyModel[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowError, setIsShowError] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const companyRepo = new CompanyApi(new HttpClient());

    const getData = async () => {
        try {
            setIsLoading(true);
            const model = {} as PaginationQuery;
            model.PageNum = pageIndex;
            model.PageSize = pageSize;
            const result = await companyRepo.getCompanies(model);
            if (result.isSuccess) {
                const res = result.value as PageResult<CompanyModel[]>
                setCompanies(res?.pageData as [] ?? []);
                setTotalCount(res?.totalCount ?? 0);
            }
            setIsLoading(false);
            setIsShowError(!result.isSuccess);
            setErrorMessages([...errorMessages, result.error]);
        } catch (e: any) {
            setIsLoading(false);
            setIsShowError(true);
            setErrorMessages([...errorMessages, e.message]);
        }
    };

    const onTableChange = (type: any, newState: TableChangeState<any>) => updateCurrentPageData(newState.page, newState.sizePerPage);

    const onPageSizeChange = (pageSize: number, page: number) => updateCurrentPageData(page, pageSize);

    const updateCurrentPageData = (pageNumber: number, pageSize: number) => {
        setPageIndex(pageNumber - 1);
        setPageSize(pageSize);
    }

    return {
        companies,
        pageIndex,
        pageSize,
        totalCount,
        isLoading,
        isShowError,
        errorMessages,

        getData,
        onPageSizeChange,
        onTableChange,
        setIsShowError
    }
}

export default useCompaniesViewModel;
