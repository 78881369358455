import React, { useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import ResultCount from "./ResultCount";
import { RootState } from "../../../infrastructure/redux/reducer";
import { useSelector } from "react-redux";
import SearchResultModel from "../../../domain/entities/searchoutput/SearchResultModel";

const PDFAddresses: React.FC = () => {
    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    
    return (
        <>
            <ResultCount count={searchResult?.addresses?.length ?? 0} text={"Addresses"} />
            <Row className="addresses">
                <Col className="address-list">
                    {searchResult?.addresses?.map(a =>
                        <Row className="address-card" key={Math.random()}>
                            <Col className="col-auto img-box px-2">
                                <img width='30px' height='18px' src="/images/search-results/address.png" />
                            </Col>
                            <Col className="p-0 text">
                                {a.addressLine}
                            </Col>
                        </Row>)}
                </Col>
                <Col xs={8}>
                    <img className="col-12 my-1" src={`https://maps.googleapis.com/maps/api/staticmap?
                zoom=15&size=600x300&maptype=roadmap&${searchResult?.addresses
                            ?.map((a) => "markers=color:orange|size:small|" + encodeURI(a.addressLine))
                            .join("&").replaceAll("#", "")}&key=AIzaSyDQRfEFtUUxMHTbo2oOj6GIN6mh1_syEK8`}
                    />
                </Col>
            </Row>
        </>
    );
};

export default PDFAddresses;
