import React from "react";
import { Col, Row } from "react-bootstrap";
import { emailRegex } from "../../../infrastructure/lookups/RegEx";
import CustomInput from "../CustomInput";
import { CompanyChildComponentProps } from "../../views/admin/AddUpdateCompany_new";
import CustomSelect from "../CustomSelect";


const BillingInfo: React.FC<CompanyChildComponentProps> = ({ state, control }) => {

    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Billing Information</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="billingEmail"
                    placeholder="Email"
                    control={control}
                    pattern={{ value: emailRegex, message: "Email is not valid" }}
                />
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating">
                <CustomInput
                    name="billingAddress"
                    placeholder="Address"
                    control={control}
                />
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="billingCity"
                    placeholder="City"
                    control={control}
                />
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomSelect
                    name="billingSelectedState"
                    control={control}
                    placeholder="Select State"
                    label="State"
                    options={state?.stateList ?? []}
                />
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input" md="6">
                <CustomInput
                    name="billingZipCode"
                    placeholder="Address Zip Code"
                    control={control}
                />
            </Col>
        </Row>
    </>
};

export default BillingInfo;
