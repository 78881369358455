import { toast } from "react-toastify";
import CompanyDetailsModel, { PointOfContact } from "../../../domain/entities/admin/models/CompanyDetailsModel";
import { useState } from "react";
import CompanyApi from "../../../data/admin/CompanyApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import UserAccountApi from "../../../data/admin/UserAccountApi";
import InvoiceType from "../../../domain/entities/admin/enums/InvoiceType";
import SearchApi from "../../../data/search/SearchApi";

export const INVOICE = 'Invoice';
export const NONE = 'None';

const ACTIVE_STATUS_ID = 1;
const NONE_INVOICABLE_INVOICE_TYPE_ID = 1;

type option = { value: number, label: string };

export type CompanyDetailsState = {
    id: number,
    name: string,
    address: string,
    city: string,
    zipCode: string,
    domains: string[],
    netsuiteExternalId?: string,
    employerIdentificationNumber?: string
    signedContract?: boolean,
    pricePerSearch: number,

    billingAddress?: string,
    billingCity?: string,
    billingZipCode?: string,
    billingEmail?: string,
    
    billingPointOfContact: PointOfContact,
    accountPointOfContact: PointOfContact,

    salesRepresentitaveName: string,
    salesRepresentitaveEmail: string,
    
    statusList: option[],
    stateList: option[],
    invoiceTypeList: option[],
    
    selectedStatus?: option,
    companySelectedState?: option,
    billingSelectedState?: option,
    selectedInvoiceType?: option,
    selectedBillingState?: option,

    billingPocSameAsAccountPoc: boolean
}

function useAddUpdateCompanyViewModel() {

    const [state, setState] = useState<CompanyDetailsState>({ billingPocSameAsAccountPoc: false, domains: [''] } as CompanyDetailsState);
    const [stateIsReady, setStateIsReady] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowError, setIsShowError] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [createdSuccessfully, setCreatedSuccessfully] = useState<boolean>(false);

    const searchApi = new SearchApi(new HttpClient());
    const companyRepository = new CompanyApi(new HttpClient());
    const userAccountRepo = new UserAccountApi(new HttpClient());

    const initializeState = async () => {

        const invoiceTypeList = Object.keys(InvoiceType).filter(k => !isNaN(Number(k))).map(name => ({ value: +name, label: InvoiceType[+name] }));
        const stateList = searchApi.getStates().map(state => ({ value: state.id, label: state.name }));
        let statusList = [] as option[];

        const result = await userAccountRepo.ListAccountStatus();
        if (result.isSuccess)
            statusList = result?.value?.map(s => ({ value: s.id, label: s.name })) ?? [];
        else
            setIsShowError(true);

        setState(prev => ({
            ...prev,
            selectedStatus: statusList?.find((s: option) => s.value == ACTIVE_STATUS_ID),
            selectedInvoiceType: invoiceTypeList?.find((l: option) => l.value == NONE_INVOICABLE_INVOICE_TYPE_ID),
            invoiceTypeList: invoiceTypeList,
            stateList: stateList,
            statusList: statusList,
        } as CompanyDetailsState));
    }

    const getData = (companyId: number) => {
        try {
            setIsLoading(true);
            companyRepository.getCompany(companyId).then(result => {

                if (result.isSuccess) {

                    const resultModel = result?.value as CompanyDetailsModel;

                    setState(prevState => ({
                        ...prevState,
                        ...resultModel as unknown as CompanyDetailsState,
                        companySelectedState: prevState?.stateList?.find(s => s.label == resultModel.state),
                        billingSelectedState: prevState?.stateList?.find(s => s.label == resultModel.billingState),
                        selectedInvoiceType: prevState?.invoiceTypeList?.find(i => i.value == resultModel.invoiceTypeId),
                        selectedStatus: prevState?.statusList?.find(s => s.value == resultModel.statusId),
                        domains: resultModel.domains.split(',') ?? []
                    }));
                }
                setIsLoading(false);
                setIsShowError(!result.isSuccess);
                setErrorMessages([...errorMessages, result.error]);
            });
        } catch (e: any) {
            setIsLoading(false);
            setIsShowError(true);
            setErrorMessages([...errorMessages, e.message]);
        }
    };

    const saveChanges = async (data: CompanyDetailsState) => {

        try {
            setIsLoading(true);
            let result;
            const company = {
                ...data,
                domains: data.domains.join(','),
                invoiceTypeId: data.selectedInvoiceType?.value,
                statusId: data.selectedStatus?.value,
                state: data.companySelectedState?.label,
                billingState: data.billingSelectedState?.label
            } as unknown as CompanyDetailsModel;
            
            if (data?.id)
                result = await companyRepository.updateCompany(company);
            else
                result = await companyRepository.addCompany(company);

            setIsLoading(false);
            
            if (result.isSuccess) {
                
                if (!data?.id) {
                    notify("Created");
                    setCreatedSuccessfully(true);
                }
                else
                    notify("Updated");
                    
            }
            else {
                setIsShowError(true);
                setErrorMessages([...errorMessages, result.error]);
            } 
        }
        catch (e: any) {
            setIsLoading(false);
            setIsShowError(true);
            setErrorMessages([...errorMessages, e.message]);
        }
    }

    const notify = (verb: string) => {
        toast.success(`${verb} Successfully`, {
            position: "top-center",
            autoClose: verb == "Updated" ? 1000 : false,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            className: "avoid-nav-bar",
            bodyClassName: "toast-message",
        });
    };

    return {
        state,
        stateIsReady,
        setStateIsReady,
        isLoading,
        isShowError,
        setIsShowError,
        getData,
        saveChanges,
        initializeState,
        createdSuccessfully
    };
}

export default useAddUpdateCompanyViewModel;
