import React, { useState } from 'react';
import BootstrapTable, { ColumnDescription, TableChangeState } from "react-bootstrap-table-next";
import { TailSpin } from "react-loader-spinner";
import StatusProgressBar from "./StatusProgressBar";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import SearchListFiltrationModel from '../../domain/entities/search/SearchListFiltrationModel';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import SearchStatus from '../../domain/entities/search/enum/SearchStatus';
import useUser from '../../infrastructure/hooks/useUser';


type Props =
  {
    page: number,
    sizePerPage: number,
    totalSize: number,
    isLoading: boolean,
    pageData: Array<any>,
    resultsUrl: string,
    searchText?: string,
    status?: number,
    creationDateFrom?: Date;
    creationDateTo?: Date;
    onPageSizeChange: (pageSize: number, page: number) => void,
    onTableChange: (type: any, newState: TableChangeState<any>) => void,
    onOpenSearchDetails: (id: string) => void
    onSearch: (filterModel:SearchListFiltrationModel) => void,
    onRerunSearch: (id: number) => void
  };

type SearchTableComponentState =
  {
    SearchText?: string;
    Status?: number;
    CreationDateFrom?: Date;
    CreationDateTo?: Date;
  };


const SearchListTable: React.FC<Props> = (props: Props) => {

  const { user } = useUser();

  const [state, setState] = useState<SearchTableComponentState>({
    SearchText: props.searchText,
    Status: props.status,
    CreationDateFrom: props.creationDateFrom,
    CreationDateTo: props.creationDateTo
  });

    const [dateValidation, setDateValidation] = useState({ isValid: true, message: "" });

    const isValidDate = (date: Date) => {
        return date && date > new Date(1900, 0, 1) && date < new Date();
    }

    const isValidDateRange = (from: Date | undefined, to: Date | undefined) => {
        const isValidFrom = !from || from && isValidDate(from);
        const isValidTo = !to || to && isValidDate(to);
        const isValidFromAndTo = !from || !to || from < to;

        return isValidFrom && isValidTo && isValidFromAndTo;
    }

    const onSearchClick = () => {
        if (!isValidDateRange(state.CreationDateFrom, state.CreationDateTo))
            return;
        const model = {} as SearchListFiltrationModel;
        model.SearchText = state.SearchText;
        model.Status = state.Status;
        model.CreationDateFrom = state.CreationDateFrom;
        model.CreationDateTo = state.CreationDateTo;
        props.onSearch(model);
    }

    const onDateChange = (value: any) => {
        const from = value?.at(0) ? new Date(value[0]) : undefined;
        const to = value?.at(1) ? new Date(value[1]) : undefined;
        setState({ ...state, CreationDateFrom: from, CreationDateTo: to });
        if ((from || to) && !isValidDateRange(from, to))
            setDateValidation({ isValid: false, message: "Invalid date range" });
        else
            setDateValidation({ isValid: true, message: "" });
    }

    const renderSearchDetailsTooltip = (p: any) => (<Tooltip id="button-tooltip" {...p}>Search Details</Tooltip>);
    const renderSearchResultsTooltip = (p: any) => (<Tooltip id="button-tooltip" {...p}>Search Result</Tooltip>);
    const renderRerunSearchTooltip = (p: any) => (<Tooltip id="button-tooltip" {...p}>Rerun Search</Tooltip>);
    const renderExpiredResultTooltip = (p: any) => (<Tooltip id="button-tooltip" {...p}>Search result is expired</Tooltip>);

  const columns: ColumnDescription[] = [
    {
      dataField: "caseName",
      text: "Case Name",
      sort: true
    },
    {
      dataField: "name",
      text: "Name",
      sort: true
    },
    {
      dataField: "creationDate",
      text: "Date of Search",
      formatter: (cell, row) => new Date(row.creationDate).toLocaleDateString(),
      sort: true
    },
    {
      dataField: "expirationDate",
      text: "Expiration Date",
      formatter: (cell, row) => new Date(row.expirationDate).toLocaleDateString()
    },
    {
      dataField: "enhancedSearchEnabled",
        text: "Enhanced Search",
        formatter: (cell, row) => (<> {row.enhancedSearchEnabled ? <span className="correct-symbol">✔</span> : <span className="wrong-symbol">✘</span>} </>)
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <>
          <StatusProgressBar completePercentage={Math.ceil(row.completePercentage)} creationDate={new Date(row.creationDate)} />
        </>
      ),
    },
    {
      dataField: "searchId",
      text: "Actions",
      formatter: (cell, row) => (
          <div className="actions-td">
          <OverlayTrigger placement="top" delay={{ show: 0, hide: 200 }} overlay={renderSearchDetailsTooltip}>
            <button onClick={() => props.onOpenSearchDetails(row.searchId)}><i className="bi bi-eye"></i></button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" delay={{ show: 0, hide: 200 }} overlay={renderRerunSearchTooltip}>
            <button onClick={() => props.onRerunSearch(row.searchId)}><span>&#8635;</span></button>
          </OverlayTrigger>
          {!user.isAdmin && row.isExpired
            ?
          <OverlayTrigger placement="bottom" delay={{ show: 0, hide: 200 }} overlay={renderExpiredResultTooltip}>
           <img className="search-input-icon disabled-result-icon" src="/images/search-output.svg" alt="" /> 
          </OverlayTrigger>
           :
          <OverlayTrigger placement="top" delay={{ show: 0, hide: 200 }} overlay={renderSearchResultsTooltip}>
            <a href={`${props.resultsUrl}/${row.searchId}`}><img className="search-input-icon" src="/images/search-output.svg" alt="" /></a>
          </OverlayTrigger>
          }
        </div>
      ),
    },
    ];

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  return <div>
    <div className="filter-div mb-5">
      <div className="search-input-div">
        <input autoComplete="new-password"
          placeholder="Search for person’s name"
          className="input-field search-input"
          type="text"
          onChange={(e) => { setState({ ...state, SearchText: e.currentTarget.value }); }}
          value={state.SearchText}
        />
        <img
          className="search-input-icon"
          src="/images/search.svg"
          alt=""
        />
      </div>

      <div className="search-select-div ">
        <select
          name="gender"
          id="gender"
          placeholder="Status"
          className="search-select"
          onChange={(e) => setState({ ...state, Status: isNaN(+e.currentTarget.value) ? undefined : +e.currentTarget.value })}
          value={state.Status}
        >
                  <option value={undefined}>Status</option>
                  <option value={SearchStatus.Created}>Created</option>
                  <option value={SearchStatus.Inprogress}>In Progress</option>
                  <option value={SearchStatus.Completed}>Completed</option>
                  <option value={SearchStatus.FinishedWithErrors}>Completed with errors</option>
                  <option value={SearchStatus.NeedMoreInfo}>No result</option>
        </select>
      </div>

          <Col>
              <Row>
                  <div className={dateValidation.isValid ? "search-date-div mx-3" : "search-date-div mx-3 invalid-form-control"}>
                      <label className="date-label">Date </label>
                      <DateRangePicker
                          onChange={onDateChange}
                          value={[state.CreationDateFrom ?? "", state.CreationDateTo ?? ""]}
                      />
                  </div>
              </Row>
              <Row>{!dateValidation.isValid && <div className="invalid-feedback mx-3 col">{dateValidation.message}</div>}  </Row>
          </Col>

      <div className='d-flex justify-content-end'>
        <button className='btn next-btn' onClick={onSearchClick}>Search</button>
      </div>
    </div>
    <div className="table-style mt-4">
      <BootstrapTable
        keyField="searchId"
        data={props.pageData}
        columns={columns}
        pagination={paginationFactory({
          pageStartIndex: 1,
          page: props.page,
          sizePerPage: props.sizePerPage,
          totalSize: props.totalSize,
          firstPageText: "First",
          prePageText: "Back",
          nextPageText: "Next",
          lastPageText: "Last",
          nextPageTitle: "First page",
          prePageTitle: "Pre page",
          firstPageTitle: "Next page",
          lastPageTitle: "Last page",
          paginationTotalRenderer: customTotal,
          onSizePerPageChange: props.onPageSizeChange,
          sizePerPageList: [
            { text: "10", value: 10 },
            { text: "25", value: 25 },
            { text: "50", value: 50 },
            { text: "100", value: 100 },
          ],
        })}
        onTableChange={props.onTableChange}
        filter={filterFactory()}
        bordered={false}
        headerClasses="header-tr"
        remote
        noDataIndication={() => (
          <TailSpin
            wrapperClass="table-spinner"
            visible={props.isLoading}
            height={50}
            width="50"
            color="#FF5733"
            ariaLabel="loading"
          />
        )}
      />
    </div>

  </div>

}

export default SearchListTable;