import React from "react";
import SocialPlatformProfileModel from "../../../../domain/entities/searchoutput/SocialPlatformProfileModel";
import MasonryView from "../../MasonryView";
import SocialResultsWithData from "./SocialResultWithData_new";
import SocialResultsWithNoData from "./SocialResultWithNoData_new";
import SocialSearchResult from "./SocialSearchResult";

export type Props = {
    platforms: SocialPlatformProfileModel[];
    isDeleted: boolean;
}

const SocialResultsList: React.FC<Props> = ({ platforms, isDeleted }: Props) => {
    return <>
        <MasonryView columnCount={3}>
            {
                platforms?.map((p, index) => {
                    const platformIconUrl = p.socialPlatform?.iconUrl ?? "/icons/default-social-icon.svg";
                    const searchResult = p.resultsWithNoData?.find(r => r.url.includes('https://www.facebook.com/search/people?'));

                    if (searchResult) {
                        p.resultsWithNoData = p.resultsWithNoData.filter(r => !r.url.includes('https://www.facebook.com/search/people?'));
                    }

                    return (
                        <React.Fragment key={index}>
                            {p.resultsWithData?.map((r, rIndex) => (
                                <SocialResultsWithData
                                    result={r}
                                    platformIconUrl={platformIconUrl}
                                    platformName={p.socialPlatform?.name}
                                    key={rIndex}
                                    isDeleted={isDeleted}
                                />
                            ))}

                            {p.resultsWithNoData?.length > 0 && (
                                <SocialResultsWithNoData
                                    results={p.resultsWithNoData}
                                    platformIconUrl={platformIconUrl}
                                    platformName={p.socialPlatform?.name}
                                    key={`noData-${index}`}
                                    isDeleted={isDeleted}
                                />
                            )}

                            {searchResult && (
                                <SocialSearchResult
                                    result={searchResult}
                                    platformIconUrl={platformIconUrl}
                                    platformName={p.socialPlatform?.name}
                                    key={`searchResult-${index}`}
                                    isDeleted={isDeleted}
                                />
                            )}
                        </React.Fragment>
                    );
                })
            }
        </MasonryView>
    </>
}

export default SocialResultsList;