import React from "react";
import { Col, Row } from "react-bootstrap";
import useUser from "../../infrastructure/hooks/useUser";

const ContactUs: React.FC = () => {

    const { user } = useUser();

    return (
        <div className="contact-us-page container">
            <div className="contact-us-wrapper">
                {!user.isUserAuthorized() && <Row> <img className="intertel-logo-img" src="/images/INTERTEL-logo-no-tag.png"></img></Row>}
                <Row><span className="contact-us-title">Contact Us</span></Row>
                <Row>
                    <Col className="intertel-url">
                        <Row className="img-wrapper"><img src="/images/contact-url-icon.png" /></Row>
                        <Row><span className="contact-us-section-title">URL</span></Row>
                        <Row><span className="contact-us-data">www.intertelinc.com</span></Row>
                        <Row className="d-block"><a href="https://www.intertelinc.com" target="_blank" rel="noreferrer" className="btn">Visit Us</a></Row>
                    </Col>
                    <Col className="intertel-email">
                        <Row className="img-wrapper"><img src="/images/contact-email-icon.png" /></Row>
                        <Row><span className="contact-us-section-title">Email</span></Row>
                        <Row><span className="contact-us-data">intertel@intertelgo.com</span></Row>
                        <Row className="d-block"><a href="mailto:intertel@intertelgo.com" className="btn">Send Us</a></Row>
                    </Col>
                    <Col className="intertel-phone">
                        <Row className="img-wrapper"><img src="/images/contact-phone-icon.png" /></Row>
                        <Row><span className="contact-us-section-title">Phone</span></Row>
                        <Row><span>800-791-7776</span></Row>
                    </Col>
                </Row>
                {!user.isUserAuthorized() && <Row className="d-block"><a href="/" className="login-btn">Login</a></Row>}
            </div>
        </div>
    );
};

export default ContactUs;
