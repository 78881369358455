import {useSelector } from 'react-redux';
import {
    setAddressVisibility,
    setEducationVisibility,
    setEmailsVisibility,
    setPhonesVisibility,
    setWorkVisibility,
    setRelativesVisibility,
    setInternetSearchesVisibility,
    setSocialResultsVisibility,
} from '../redux/reducer/VisibilityReducer'; 
import VisibilityFlagsModel from '../../domain/entities/searchoutput/VisibilityFlagsModel'; 

const useVisibility = () => {
    const visibilityFlags = useSelector((state: { visibility: VisibilityFlagsModel }) => state.visibility);
    return { visibilityFlags, actions :{
        setAddressVisibility,
        setEducationVisibility,
        setEmailsVisibility,
        setPhonesVisibility,
        setWorkVisibility,
        setRelativesVisibility,
        setInternetSearchesVisibility,
        setSocialResultsVisibility, } };
};

export default useVisibility;
