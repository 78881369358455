import Result from "../../../domain/common/Result";
import { TableChangeState } from "react-bootstrap-table-next";
import PendingRequestModel from "../../../domain/entities/admin/models/PendingRequestModel";
import PendingRequestType from "../../../domain/entities/admin/enums/PendingRequestType";
import PaginationQuery from "../../../domain/common/PaginationQuery";
import PendingUserRequestApi from "../../../data/admin/PendingUserRequestApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import AuthorizedManagerRequestApi from "../../../data/authorizedManagerRequest/AuthorizedManagerRequestApi";
import { useState } from "react";
import { toast } from 'react-toastify';

function usePendingRequestsViewModel() {

    const [pendingRequests, setPendingRequests] = useState<PendingRequestModel[]>([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowError, setIsShowError] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [selectedRequest, setSelectedRequest] = useState<PendingRequestModel>({} as PendingRequestModel);
    const [isShowGlbaDppaDetailsModal, setIsShowGlbaDppaDetailsModal] = useState(false);
    const [isShowViewPermissionDetailsModal, setIsShowViewPermissionDetailsModal] = useState(false);
    const [isShowApprovalModal, setIsShowApprovalModal] = useState(false);
    const [isShowDenialModal, setIsShowDenialModal] = useState(false);
    const [ApprovedDeniedRow, setApprovedDeniedRow] = useState<PendingRequestModel>({} as PendingRequestModel);

    const pendingUserRequestRepo = new PendingUserRequestApi(new HttpClient());
    const authorizedManagerRequestRepo = new AuthorizedManagerRequestApi(new HttpClient());

    const hideApprovalModal = () => setIsShowApprovalModal(false);
    const hideDenialModal = () => setIsShowDenialModal(false);

    const showApprovalModal = (row: PendingRequestModel) => {
        setApprovedDeniedRow(row);
        setIsShowApprovalModal(true);
    }

    const showDenialModal = (row: PendingRequestModel) => {
        setApprovedDeniedRow(row);
        setIsShowDenialModal(true);
    }

    const confirmApproval = () => {
        setIsShowApprovalModal(false);
        return ApproveRequest();
    }

    const confirmDenial = () => {
        setIsShowDenialModal(false);
        return DenyRequest();
    }

    const onEmailClick = (selectedRow: PendingRequestModel) => {
        setSelectedRequest(selectedRow);
        setIsShowViewPermissionDetailsModal(selectedRow.requestType == PendingRequestType.ViewingOtherUsers);
        setIsShowGlbaDppaDetailsModal(selectedRow.requestType == PendingRequestType.GlbaDppaPermissions);
    }

    const hideGlbaDppaDetailsModal = () => setIsShowGlbaDppaDetailsModal(false);

    const hideViewPermissionDetailsModal = () => setIsShowViewPermissionDetailsModal(false);

    const ApproveRequest = async () => {
        try {
            setIsLoading(true);
            setIsShowApprovalModal(false);

            const id = ApprovedDeniedRow.id;
            let result;

            if (ApprovedDeniedRow.requestType == PendingRequestType.ViewingOtherUsers)
                result = await pendingUserRequestRepo.ApproveViewRequest(id);
            else if (ApprovedDeniedRow.requestType == PendingRequestType.GlbaDppaPermissions)
                result = await pendingUserRequestRepo.approveGlbaDppaChangeRequest(id)
            else if (ApprovedDeniedRow.requestType == PendingRequestType.AuthorizedManager)
                result = await authorizedManagerRequestRepo.approveRequest(id);
            else
                result = Result.Fail("Unknown request type");

            setIsLoading(false);
            setIsShowError(!result.isSuccess);
            setErrorMessages([...errorMessages, result.error]);
            
            if (result.isSuccess) {
                setPendingRequests(pendingRequests?.filter(r => !(r.id == id && r.requestType == ApprovedDeniedRow.requestType)) as [PendingRequestModel] ?? []);
                setTotalCount(totalCount - 1);
                notify("Approved");
            }
            return result;
        } catch (e: any) {
            setIsLoading(false);
            setErrorMessages([...errorMessages, e.message]);
            setIsShowError(true);
            return Result.Fail(e.message, 500);
        }
    }

    const DenyRequest = async () => {
        try {
            setIsLoading(true);
            setIsShowDenialModal(false);

            const id = ApprovedDeniedRow.id;
            let result;

            if (ApprovedDeniedRow.requestType == PendingRequestType.ViewingOtherUsers)
                result = await pendingUserRequestRepo.DenyViewRequest(id);
            else if (ApprovedDeniedRow.requestType == PendingRequestType.GlbaDppaPermissions)
                result = await pendingUserRequestRepo.denyGlbaDppaChangeRequest(id)
            else if (ApprovedDeniedRow.requestType == PendingRequestType.AuthorizedManager)
                result = await authorizedManagerRequestRepo.denyRequest(id);
            else
                result = Result.Fail("Unknown request type");

            setIsLoading(false);
            setIsShowError(!result.isSuccess);
            setErrorMessages([...errorMessages, result.error]);

            if (result.isSuccess) {
                setPendingRequests(pendingRequests?.filter(r => !(r.id == id && r.requestType == ApprovedDeniedRow.requestType)) as [PendingRequestModel] ?? []);
                setTotalCount(totalCount - 1);
                notify("Denied");
            }
            return result;
        } catch (e: any) {
            setIsLoading(false);
            setErrorMessages([...errorMessages, e.message]);
            setIsShowError(true);
            return Result.Fail(e.message, 500);
        }
    }

    const notify = (action: string) => {
        toast.success(`${action} successfully.`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            className: "avoid-nav-bar",
            bodyClassName: "toast-message",
        });
    };

    const getData = async () => {
        try {
            setIsLoading(true);
            const model = {} as PaginationQuery;
            model.PageNum = pageIndex;
            model.PageSize = pageSize;
            const pendingRequestsResult = await pendingUserRequestRepo.getPendingUserRequests(model);

            if (pendingRequestsResult.isSuccess) {
                setPendingRequests(pendingRequestsResult.value?.pageData ?? []);
                setTotalCount(pendingRequestsResult.value?.totalCount ?? 0);
            }

            setIsLoading(false);
            setIsShowError(!pendingRequestsResult.isSuccess);
            pendingRequestsResult.error ? setErrorMessages([...errorMessages, pendingRequestsResult.error]) : null;
        } catch (e: any) {
            setIsLoading(false);
            setErrorMessages([...errorMessages, e.message]);
            setIsShowError(true);
            return Result.Fail(e.message, 500);
        }
    };

    const onTableChange = (type: any, newState: TableChangeState<any>) => updateCurrentPageData(newState.page, newState.sizePerPage);

    const onPageSizeChange = (pageSize: number, page: number) => updateCurrentPageData(page, pageSize);

    const updateCurrentPageData = (pageNumber: number, pageSize: number) => {
        setPageIndex(pageNumber - 1);
        setPageSize(pageSize);
    }

    return {
        pendingRequests,
        pageIndex,
        pageSize,
        totalCount,
        isLoading,
        isShowError,
        errorMessages,
        selectedRequest,
        isShowGlbaDppaDetailsModal,
        isShowViewPermissionDetailsModal,
        isShowApprovalModal,
        isShowDenialModal,

        getData,
        confirmDenial,
        confirmApproval,
        onEmailClick,
        showDenialModal,
        showApprovalModal,
        onPageSizeChange,
        onTableChange,
        hideGlbaDppaDetailsModal,
        hideViewPermissionDetailsModal,
        hideApprovalModal,
        hideDenialModal,
        setIsShowError
    };
}

export default usePendingRequestsViewModel;
