import { Subject } from "rxjs";
import Result from "../../../domain/common/Result";
import CaseModel from "../../../domain/entities/cases/CaseModel";
import CaseRepository from "../../../domain/repositories/cases/ICaseRepository";
import IBaseViewModel from "../IBaseViewModel";

export default class CaseViewModel implements IBaseViewModel {
	public id: string;
	public name: string;

	public isLoading: boolean;
	public isShowError: boolean;
	public errorMessages: string[];
	public validation: any;
	private caseRepository: CaseRepository;

	private topic?: string;
	private subject?: Subject<any>;

	public constructor(caseRepository: CaseRepository) {
		this.id = "";
		this.name = "";

		this.validation = {};
		this.isLoading = false;
		this.isShowError = false;
		this.errorMessages = [];

		this.caseRepository = caseRepository;
	}
	onIdQueryChanged(id: string): void {
		this.id = id;
		if (id) {
			this.getDetails();
		}
		this.notifyViewAboutChanges();
	}
	public onNameQueryChanged = (e: React.FormEvent): void => {
		const input = e as React.FormEvent<HTMLInputElement>;
		this.name = input.currentTarget.value;
		this.notifyViewAboutChanges();
	};

	public onClick = async (): Promise<void> => {
		if (!this.validateCaseForm()) {
			this.notifyViewAboutChanges();
			return;
		}

		try {
			const model = {} as CaseModel;
			model.Name = this.name;
			this.isLoading = true;
			this.notifyViewAboutChanges();
			let result = Result.Ok();
			if (!this.id) {
				result = await this.caseRepository.add(model);
			} else {
				model.Id = this.id;
				result = await this.caseRepository.update(model);
			}
			this.isLoading = false;
			this.isShowError = !result.isSuccess;
			this.errorMessages.push(result.error);
			if (result.isSuccess) window.location.href = "/case";
		} catch (e: any) {
			this.isLoading = false;
			this.errorMessages.push(e.message);
			this.isShowError = true;
		}

		this.notifyViewAboutChanges();
	};

	public getDetails = async (): Promise<void> => {
		try {
			this.isLoading = true;
			this.notifyViewAboutChanges();

			const result = await this.caseRepository.caseDetails(this.id ?? "");
			this.name = result.value?.name ?? "";
			this.isLoading = false;
			this.isShowError = !result.isSuccess;
			if (!result.isSuccess) this.errorMessages.push(result.error);
			this.notifyViewAboutChanges();
		} catch (e: any) {
			this.isLoading = false;
			this.errorMessages.push(e.message);
			this.isShowError = true;
			this.notifyViewAboutChanges();
		}
	};

	private validateCaseForm = (): boolean => {
		this.isShowError = false;
		this.errorMessages = [];
		this.validation = {};

		if (!this.name) {
			this.isShowError = true;
			this.validation.name = "Name cannot be empty";
		}

		return !this.isShowError;
	};

	private notifyViewAboutChanges = (): void => {
		const data = {
			id: this.id,
			name: this.name,

			validation: this.validation,
			isLoading: this.isLoading,
			isShowError: this.isShowError,
			errorMessages: this.errorMessages,
		};
		this.subject?.next({ topic: this.topic, data });
	};

	public attachSubject = (subject: Subject<any>, topicName: string): void => {
		this.topic = topicName;
		this.subject = subject;
	};
}
