import { toast } from "react-toastify";
import PendingAccountRequestDetailsModel from "../../../domain/entities/admin/models/PendingAccountRequestDetailsModel";
import CompanyNameModel from "../../../domain/entities/admin/models/CompanyNameModel";
import AccountRequestApi from "../../../data/admin/AccountRequestApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import { useState } from "react";
import CompanyApi from "../../../data/admin/CompanyApi";


function useAccountRequestViewModel() {

    const [accountRequest, setAccountRequest] = useState<PendingAccountRequestDetailsModel>();
    const [companiesNames, setCompaniesNames] = useState<CompanyNameModel[]>([]);
    const [autoDetectedCompanyId, setAutoDetectedCompanyId] = useState<number>(0);
    const [selectedCompanyId, setSelectedCompanyId] = useState<number | undefined>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowError, setIsShowError] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [showAccountRequestApprovalModal, setShowAccountRequestApprovalModal] = useState<boolean>(false);
    const [showAccountRequestDenialModal, setShowAccountRequestDenialModal] = useState<boolean>(false);
    const [denialReason, setDenialReason] = useState<string>('');
    const [denialNote, setDenialNote] = useState<string>('');
    const [actionSuccedded, setActionSuccedded] = useState<boolean>(false);
    const [disabledBtns, setDisabledBtns] = useState<boolean>(true);

    const pendingAccountRequestRepo = new AccountRequestApi(new HttpClient());
    const companyRepo = new CompanyApi(new HttpClient());

    const onDenialReasonChange = (e: React.FormEvent<HTMLTextAreaElement>) => setDenialReason(e.currentTarget.value);
    const onDenialNoteChange = (e: React.FormEvent<HTMLTextAreaElement>) => setDenialNote(e.currentTarget.value);

    const onDenyBtnClick = () => setShowAccountRequestDenialModal(true);

    const onApproveBtnClick = () => setShowAccountRequestApprovalModal(true);

    const hideAccountRequestDenialModal = () => {
        setShowAccountRequestDenialModal(false);
        setDenialNote('');
        setDenialReason('');
    }

    const hideAccountRequestApprovalModal = () => setShowAccountRequestApprovalModal(false);

    const onAccountRequestDeny = () => {
        setShowAccountRequestDenialModal(false);
        setIsLoading(true);

        pendingAccountRequestRepo.DenyAccountRequest({ accountRequestId: accountRequest?.id ?? 0, note: denialNote, reason: denialReason }).then(r => {
            setIsShowError(!r.isSuccess);
            
            if (!r.isSuccess)
                setErrorMessages([...errorMessages, r.error]);
            else {
                setActionSuccedded(true);
                notify('Denied');
            }

            setIsLoading(false);
            setDenialNote('');
            setDenialReason('');
        });
    }

    const onAccountRequestApprove = () => {
        setShowAccountRequestApprovalModal(false);
        setIsLoading(true);
        
        pendingAccountRequestRepo.ApproveAccountRequest(accountRequest?.id ?? 0, selectedCompanyId ? selectedCompanyId : autoDetectedCompanyId).then(r => {
            setIsShowError(!r.isSuccess);

            if (!r.isSuccess)
                setErrorMessages([...errorMessages, r.error]);
            else {
                setActionSuccedded(true);
                notify('Approved');
            }
            setIsLoading(false);
        });
    }

    const onCompanyChanged = (id: number | undefined): void => {
        setSelectedCompanyId(id);
       
        if (!id)
            setDisabledBtns(true);
        else
            setDisabledBtns(false);
    };

    const notify = (action: string) => {
        toast.success(`${action} Successfully`, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            className: "avoid-nav-bar",
            bodyClassName: "toast-message",
        });
    };

    const getData = async (accountRequestId: number) => {
        try {
            setIsLoading(true);
            
            await getAccountRequest(accountRequestId);
            const result = await companyRepo.getCompaniesNames();

            if (result.isSuccess)
                setCompaniesNames(result.value as CompanyNameModel[]);

            setIsLoading(false);
        } catch (e: any) {
            setIsLoading(false);
            setErrorMessages([...errorMessages, e.message]);
            setIsShowError(true);
        }
    };

    const getAccountRequest = async (accountRequestId: number) => {
        try {

            const result = await pendingAccountRequestRepo.getAccountRequest(accountRequestId);

            if (result.isSuccess) {
                setAccountRequest(result.value as PendingAccountRequestDetailsModel ?? {} as PendingAccountRequestDetailsModel);
                const suggestedCompanyId = (result.value as PendingAccountRequestDetailsModel).suggestedCompanyId;
                setAutoDetectedCompanyId(suggestedCompanyId ?? 0);
                setDisabledBtns(suggestedCompanyId ? false : true);
            }      

            setIsShowError(!result.isSuccess);
            setErrorMessages([...errorMessages, result.error]);
        } catch (e: any) {
            setIsLoading(false);
            setErrorMessages([...errorMessages, e.message]);
            setIsShowError(true);
        }
    };

    return {
        accountRequest,
        companiesNames,
        autoDetectedCompanyId,
        isLoading,
        isShowError,
        errorMessages,
        showAccountRequestApprovalModal,
        showAccountRequestDenialModal,
        denialReason,
        denialNote,
        actionSuccedded,
        disabledBtns,

        getData,
        onCompanyChanged,
        onDenyBtnClick,
        onApproveBtnClick,
        hideAccountRequestApprovalModal,
        onAccountRequestApprove,
        hideAccountRequestDenialModal,
        onAccountRequestDeny,
        setIsShowError,
        onDenialReasonChange,
        onDenialNoteChange
    };
}

export default useAccountRequestViewModel;
