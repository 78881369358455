import NameModel from "../../../domain/entities/generic/NameModel";
import SearchResultModel from "../../../domain/entities/searchoutput/SearchResultModel";
import { HeaderInfo } from "../../../presentation/components/search-result/header/Header";
import { SET_Search_Result } from "../actions/actionTypes";

const initialState: SearchResultModel = {
    pictureUrl: "",
    name: {} as NameModel,
    age: "",
    caseName: "",
    caseId: 0,
    headerModel: {} as HeaderInfo,
    addresses: [],
    relatives: [],
    dateOfBirths: [],
    educations: [],
    experiences: [],
    emails: [],
    images: [],
    names: [],
    phones: [],
    socialResults: [],
    tLOTokens: [],
    usernames: [],
    internetSearches: [],

    isLoading: false,
    isDownloading: false,
    isShowError: false,
    isShowPDF: false,
    errorMessages: [],
    activeKey: "",
    isSuccess: false,
    isPrintView: false,
    dataFetched: false,
    showAddModal: false,
    selectedItemId: null,
    searchRequestId: 0,
    isExpiredSearchResult: false,
    displayScrollView: false
}

const reducer = (SearchResult: SearchResultModel = initialState, action: ActionType) => {
    switch (action.type) {
        case SET_Search_Result:
            return { ...SearchResult, ...action.payload };
        default:
            return SearchResult;
    }
}

export default reducer;