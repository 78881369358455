import React, { useContext, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import HomeViewModelContext from "../../../../infrastructure/Contexts/HomeViewModelContext";
import useHomeViewModel from "../../../view-model/searchoutput/HomeViewModel_new";



const AddUserSocialProfileDialog: React.FC = () => {
    const {addUserSocialProfile} = useHomeViewModel();
    const [isShowModal, setIsShowModal] = useState(false);
    const [url, setUrl] = useState("");
    return <>
        <button
            className="add-user-profile-btn"
            onClick={() => { setUrl(""); setIsShowModal(true); }}>
            <span className="add-btn-content">
                <span>Add Profile</span>
                <img src="/images/add.svg" alt="" />
            </span>
        </button>
        <Modal show={isShowModal}>
            <Modal.Body className="modal-card user-social-profile-add-modal">
                <div className="case-modal-header">
                    <h2 className="new-case-title">Add User Social Profile</h2>
                    <img
                        className="modal-close-icon"
                        src="/images/close.svg"
                        alt=""
                        onClick={() => { setIsShowModal(false) }}
                    />
                </div>
                <div className="case-modal-header d-block">
                    <Row className="m-1">
                        <input
                            type="text"
                            placeholder="Social Profile URL"
                            value={url}
                            className="form-control url-text"
                            onChange={e => { setUrl(e.currentTarget.value) }}
                        />
                    </Row>
                    <Row className="flex-row-reverse px-2">
                        <button className="add-user-profile-btn" onClick={async () => {
                            await addUserSocialProfile(url)
                            setIsShowModal(false);
                        }}>
                            Add
                        </button>
                    </Row>
                    <div >
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </>

}

export default AddUserSocialProfileDialog;