export const SET_ADDRESS_VISIBILITY = 'SET_ADDRESS_VISIBILITY';

export const SET_EDUCATION_VISIBILITY = 'SET_EDUCATION_VISIBILITY';

export const SET_EMAILS_VISIBILITY = 'SET_EMAILS_VISIBILITY';

export const SET_PHONES_VISIBILITY = 'SET_PHONES_VISIBILITY';

export const SET_WORK_VISIBILITY = 'SET_WORK_VISIBILITY';

export const SET_RELATIVES_VISIBILITY = 'SET_RELATIVES_VISIBILITY';

export const SET_INTERNET_SEARCHES_VISIBILITY = 'SET_INTERNET_SEARCHES_VISIBILITY';

export const SET_SOCIAL_RESULTS_VISIBILITY = 'SET_SOCIAL_RESULTS_VISIBILITY';
