import React, { useEffect, useState } from "react";
import { Row } from 'react-bootstrap';
import ResultCount from "../ResultCount";
import PDFSocialResultsWithData from "./PDFSocialResultWithData";
import PDFSocialResultsWithNoData from "./PDFSocialResultWithNoData";
import PDFSocialSearchResult from "./PDFSocialSearchResult";
import HelperMethods from "../../../utils/HelperMethods";
import MasonryView from "../../MasonryView";
import SearchResultModel from "../../../../domain/entities/searchoutput/SearchResultModel";
import { RootState } from "../../../../infrastructure/redux/reducer";
import { useSelector } from "react-redux";


const PDFSocialResults: React.FC = () => {
    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    
    return (
        <div className="social-results">
            <Row>
                <ResultCount count={searchResult?.socialResults?.reduce((partialSum, p) => p.resultsWithData.length + p.resultsWithNoData.length + partialSum, 0) ?? 0} text={"Social Results"} />
            </Row>

            <div className="social-results-container">
                {searchResult?.socialResults && < MasonryView columnCount={3}>
                    {HelperMethods.getCopyOf(searchResult?.socialResults).map(p => {

                        let platformIconUrl = p.socialPlatform?.iconUrl ?? "/images/search-results/defaultsocialicon.png";
                        const searchResult = p.resultsWithNoData?.find(r => r.url.includes('https://www.facebook.com/search/people?'));

                        if (platformIconUrl.endsWith('.svg'))
                            platformIconUrl = "/images/search-results/defaultsocialicon.png";


                        if (searchResult)
                            p.resultsWithNoData = p.resultsWithNoData.filter(r => !r.url.includes('https://www.facebook.com/search/people?'));

                        return (
                            <>
                                {p.resultsWithData && p.resultsWithData.length > 0 && p.resultsWithData.map(r =>
                                    <PDFSocialResultsWithData result={r} platformIconUrl={platformIconUrl} platformName={p.socialPlatform?.name} key={Math.random()} />
                                )}

                                {p.resultsWithNoData && p.resultsWithNoData.length > 0 &&
                                    <PDFSocialResultsWithNoData results={p.resultsWithNoData} platformIconUrl={platformIconUrl} platformName={p.socialPlatform?.name} key={Math.random()} />
                                }

                                {searchResult && (
                                    <PDFSocialSearchResult result={searchResult} platformIconUrl={platformIconUrl} platformName={p.socialPlatform?.name} key={Math.random()} />
                                )}
                            </>);
                    })}
                </MasonryView>}

            </div>

        </div>
    );
};

export default PDFSocialResults;
