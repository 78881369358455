import React, { useEffect, useState } from "react";
import { Modal, Nav, Tab } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import ReactTooltip from "react-tooltip";
import SearchOutputApi from "../../../data/searchoutput/SearchOutputApi";
import SocialPlatformApi from "../../../data/searchoutput/SocialPlatformApi";
import NameModel from "../../../domain/entities/generic/NameModel";
import AddressModel from "../../../domain/entities/searchoutput/AddressModel";
import DateOfBirthModel from "../../../domain/entities/searchoutput/DateOfBirthModel";
import EducationModel from "../../../domain/entities/searchoutput/EducationModel";
import EmailModel from "../../../domain/entities/searchoutput/EmailModel";
import ExperienceModel from "../../../domain/entities/searchoutput/ExperienceModel";
import ImageModel from "../../../domain/entities/searchoutput/ImageModel";
import PhoneModel from "../../../domain/entities/searchoutput/PhoneModel";
import RelativeModel from "../../../domain/entities/searchoutput/RelativeModel";
import SocialPlatformProfileModel from "../../../domain/entities/searchoutput/SocialPlatformProfileModel";
import TLOTokenModel from "../../../domain/entities/searchoutput/TLOTokenModel";
import UsernameModel from "../../../domain/entities/searchoutput/UsernameModel";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import { setError } from "../../../infrastructure/redux/actions/error";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import HomeViewModel from "../../view-model/searchoutput/HomeViewModel";
import SearchDetails from "../SearchDetails";
import Addresses from "./Addresses";
import Educations from "./Educations";
import Emails from "./Emails";
import Experiences from "./Experiences";
import MainInfo from "./MainInfo";
import Phones from "./Phones";
import Relatives from "./Relatives";
import SocialResults from "./SocialResults";
import SearchResultLeftMenuLinks from "./SearchResultLeftMenuLinks"
import InternetResultModel from "../../../domain/entities/searchoutput/InternetResultModel";
import InternetSearches from "./InternetSearches";
import VisibilityFlagsModel from "../../../domain/entities/searchoutput/VisibilityFlagsModel";

type SearchOutputHomeState = {
    pictureUrl: string;
    name: NameModel;
    age: string;
    caseName: string;
    caseId: number;
    addresses: AddressModel[];
    relatives: RelativeModel[];
    dateOfBirths: DateOfBirthModel[];
    educations: EducationModel[];
    experiences: ExperienceModel[];
    emails: EmailModel[];
    images: ImageModel[];
    names: NameModel[];
    phones: PhoneModel[];
    socialResults: SocialPlatformProfileModel[];
    tLOTokens: TLOTokenModel[];
    usernames: UsernameModel[];
    internetResults: InternetResultModel[];

    socialMediaTabOpen: boolean;
    selectedSocialMediaTab: string;

    isLoading: boolean;
    isDownloading: boolean;
    isSuccess: boolean;
    isShowError: boolean;
    errorMessages: string[];

    showAddModal: boolean;
};

const SearchOutputHome: React.FC = () => {
    const dispatch = useDispatch();
    const { viewModel, subscription } = useViewModel(HomeViewModel, [
        new SearchOutputApi(new HttpClient()),
        new SocialPlatformApi(new HttpClient()),
    ]);
    const [state, setState] = useState<SearchOutputHomeState>({
        pictureUrl: "",
        name: {} as NameModel,
        age: "",
        caseId: 0,
        caseName: "",
        addresses: [],
        relatives: [],
        dateOfBirths: [],
        educations: [],
        experiences: [],
        emails: [],
        images: [],
        names: [],
        phones: [],
        socialResults: [],
        tLOTokens: [],
        usernames: [],
        internetResults: [],

        socialMediaTabOpen: false,
        selectedSocialMediaTab: "",

        isSuccess: false,
        isLoading: false,
        isDownloading: false,
        isShowError: false,
        errorMessages: [],
        showAddModal: viewModel.showAddModal,
    });

    const { id } = useParams();
    useEffect(() => {
        const subscriber = subscription.subscribe((d) =>
            setState({ ...state, ...d.data })
        );

        if (id) {
            Promise.all([viewModel.loadSearchResult(Number.parseInt(id))]).then(
                (res) => {
                    for (let i = 0; i < res.length; i++) {
                        if (res[i].statusCode != 200) {
                            dispatch(setError({ errorCode: res[i].statusCode }));
                            break;
                        }
                    }
                }
            );
        } else {
            dispatch(
                setError({
                    errorCode: 404,
                })
            );
        }
        return () => {
            subscriber.unsubscribe();
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Social E-Profiler - Search Output</title>
                <meta name="description" content="Social E-Profiler" />
            </Helmet>
            {!state.isLoading && !state.isShowError && (
                <div className="container">
                    <div className="output-main-div">
                        <div className="search-header">
                            <div className="breadcrumb-div">
                                <a href="/case" className="breadcrumb-item active">
                                    Cases
                                </a>
                                <p className="breadcrumb-seperator-item">/</p>
                                <a
                                    href={"/search?caseid=" + state.caseId}
                                    className="breadcrumb-item active"
                                >
                                    {state.caseName}
                                </a>
                                <p className="breadcrumb-seperator-item">/</p>
                                <p className="breadcrumb-item">
                                    {state.name.firstName} {state.name.lastName}
                                </p>
                            </div>

                            <div className="output-header-div">
                                <div className="output-header-text">
                                    <a
                                        href="https://forms.intertelinc.com/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="submit-btn btn"
                                    >
                                        Order Social Canvass
                                    </a>
                                </div>
                                <div className="output-request-details-div">
                                    <p
                                        className="output-request-details-text"
                                        onClick={() => viewModel.openAddModal(id)}
                                    >
                                        View request details
                                    </p>
                                    <img
                                        src="/images/details.svg"
                                        className="output-request-details-text"
                                        alt="img"
                                    />
                                </div>
                            </div>
                        </div>
                        <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="personal-info"
                        >
                            <div className="output-content-div">
                                <div className="output-tabs-div">
                                    <MainInfo
                                        age={state.age}
                                        name={state.name}
                                        pictureUrl={state.pictureUrl}
                                    />
                                    <div className="export-div">
                                        <img
                                            onClick={() => {
                                                if (id) viewModel.downloadExcelReport(+id, {} as VisibilityFlagsModel);
                                            }}
                                            src="/images/csv.svg"
                                            className="export-icon"
                                            alt="img"
                                            data-tip
                                            data-for="csvExport"
                                        />
                                        <ReactTooltip id="csvExport" place="top" effect="solid">
                                            Save as CSV
                                        </ReactTooltip>
                                        <img
                                            src="/images/pdf.svg"
                                            className="export-icon"
                                            alt="img"
                                            data-tip
                                            data-for="pdfExport"
                                        />
                                        <ReactTooltip id="pdfExport" place="top" effect="solid">
                                            Save as PDF
                                        </ReactTooltip>
                                    </div>
                                    <div className="output-tab-container wrapper">
                                        <SearchResultLeftMenuLinks display={{
                                            internetResults: state?.internetResults?.length > 0,
                                            address: state?.addresses?.length > 0,
                                            education: state?.educations?.length > 0,
                                            email: state?.emails?.length > 0,
                                            phone: state?.phones?.length > 0,
                                            socilaMedia: state?.socialResults?.length > 0,
                                            relatives: state?.relatives?.length > 0,
                                            work: state?.experiences?.length > 0
                                        }} />
                                    </div>
                                </div>
                                <div className="output-tab-content-div">
                                    {state?.addresses?.length > 0 && (<Addresses addresses={state.addresses} />)}
                                    {state?.educations?.length > 0 && (<Educations educations={state.educations} />)}
                                    {state?.emails?.length > 0 && (<Emails emails={state.emails} />)}
                                    {state?.phones?.length > 0 && (<Phones phones={state.phones} />)}
                                    {state?.socialResults?.length > 0 && (<SocialResults socialPlatformId={state.selectedSocialMediaTab} socialResults={state.socialResults} />)}
                                    {state?.relatives?.length > 0 && (<Relatives relatives={state.relatives} />)}
                                    {state?.experiences?.length > 0 && (<Experiences experiences={state.experiences} />)}
                                    {state?.internetResults?.length > 0 && (<InternetSearches internetSearches={state.internetResults} />)}
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            )}
            {state.isLoading && (
                <TailSpin
                    wrapperClass="cover-spin"
                    visible={state.isLoading}
                    height={50}
                    width="50"
                    color="#FF5733"
                    ariaLabel="loading"
                />
            )}

            {state.isDownloading && (
                <TailSpin
                    wrapperClass="cover-spin"
                    visible={state.isDownloading}
                    height={50}
                    width="50"
                    color="#FF5733"
                    ariaLabel="loading"
                />
            )}
            <Modal
                show={state.showAddModal}
                onHide={viewModel.closeAddModal}
                size="lg"
            >
                <SearchDetails
                    data={null}
                    onCloseModal={() => null}
                    onRerunSearch={() => null}
                />
            </Modal>
        </>
    );
};
export default SearchOutputHome;
