import React from "react";
import { Control, UseFormGetValues, useFieldArray } from "react-hook-form";
import CustomInput from "./CustomInput";

type CustomInputProps = {
	arrayName: string,
	placeholder: string,
	control: Control<any, any>,
	getValues: UseFormGetValues<any>,
	regExp?: RegExp,
	required?: boolean,
	hasRequiredSymbol?: boolean,
	requiredMessage?: string
};

function CustomInputArray({
	arrayName,
	control,
	placeholder,
	getValues,
	regExp,
	required = false,
	hasRequiredSymbol = false,
	requiredMessage
}: CustomInputProps) {

	const { fields: items, append: appendItem, remove: removeItem } = useFieldArray({ control, name: arrayName });
	const error = control?.getFieldState(arrayName)?.error;
	const appendNewItem = () => { if (!error) appendItem('') }
	const removeExistItem = (index: number) => removeItem(index);

	const isNotDuplicated = (currentValue: string) => {
		const numberOfCurrentValueInArray = getValues(arrayName)
			.filter((value: string) => currentValue.toLowerCase().trim() == value.toLowerCase().trim())?.length;
			return numberOfCurrentValueInArray == 1;
	}
	
	return (
		<>
			{items.map((field, index) => (
				<div className="input-group-meta form-group" key={field.id}>
					<div className="remove-extra-input-div form-floating">
						<CustomInput
							name={`${arrayName}[${index}]`}
							placeholder={placeholder}
							control={control}
							pattern={regExp && { value: regExp, message: `${placeholder} is not valid` }}
							validate={(currentValue: string) => isNotDuplicated(currentValue) || `Duplicated ${placeholder}`}
							required={required}
							hasRequiredSymbol={hasRequiredSymbol}
							requiredMessage={requiredMessage}
						/>
						{(index > 0) && (<span className="search-input-icon bi bi-trash" onClick={() => removeExistItem(index)}></span>)}
					</div>
				</div>
			))}
			<div className="extra-input-add-div" onClick={appendNewItem}>
				<img className="extra-input-add-icon" src="/images/add-input.svg" alt="" />
				<p className="extra-input-add-text">{`Add ${placeholder}`}</p>
			</div>
		</>
	);
}

export default CustomInputArray;
