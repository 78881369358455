import React from "react";
import { Control, Controller, ControllerRenderProps } from "react-hook-form";

type CustomInputProps = {
	name: string;
	placeholder?: string;
	label?: string;
	control: Control<any, any>;
	type?: "text" | "number" | "password" | "email" | "date" | "checkbox";

	pattern?: { value: RegExp, message: string };
	validate?: (value: string) => string | boolean;

	required?: boolean;
	hasRequiredSymbol?: boolean;
	requiredMessage?: string;
	disabled?: boolean;

	maxValue?: { value: number, message: string } | string;
	maxLength?: number;

	minValue?: { value: number, message: string };
	minLength?: { value: number, message: string };

	showError?: boolean;
	onFieldChange?: (value: string) => boolean | void;
};

function CustomInput({
	name,
	control,
	type,
	requiredMessage = "",
	placeholder,
	label,
	onFieldChange,
	required = false,
	minLength,
	maxLength,
	pattern,
	validate,
	maxValue,
	minValue,
	showError = true,
	hasRequiredSymbol = false,
	disabled = false
}: CustomInputProps) {

	const error = control?.getFieldState(name)?.error;

	const onChange = (e: React.ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<any, string>) => {

		if (type == "checkbox") {
			field.onChange(e);
			return;
		}

		field.onChange(
			e.target.value.trim().length > 0
				? e.target.value
				: e.target.value.trim()
		);

		// Callback function
		onFieldChange &&
			onFieldChange(
				e.target.value.trim().length > 0
					? e.target.value
					: e.target.value.trim()
			);
	}

	return (
		<>
			<Controller
				name={name}
				control={control}
				render={({ field }) => (
					<input
						{...field}
						name={field.name}
						type={type ?? "text"}
						placeholder={placeholder ?? ""}
						value={field.value}
						onChange={(e) => onChange(e, field)}
						className={` ${type != "checkbox" ? "form-control" : "checkbox-form-control"}  ${showError && error ? "is-invalid" : ""}`}
						aria-invalid={showError && error ? "true" : "false"}
						autoComplete="new-password"
						max={maxValue && typeof (maxValue) == "string" ? maxValue : undefined}
						maxLength={maxLength}
						id={name}
						checked={type == "checkbox" ? field.value : undefined}
						disabled={disabled}
					/>
				)}
				rules={{
					required: required && requiredMessage,
					minLength: minLength && minLength,
					min: minValue && minValue,
					max: maxValue && typeof (maxValue) == "object" ? maxValue : undefined,
					pattern: pattern && pattern,
					validate: validate && validate,
				}}
			/>
			<label htmlFor={name} className={`${type == 'checkbox' ? "checkbox-label" : "form-label"}`}>{label ?? placeholder}{hasRequiredSymbol && <span className="mandatory-symbol">*</span>}</label>
			{showError && error && <div className="invalid-feedback">{error?.message}</div>}
		</>
	);
}

export default CustomInput;
