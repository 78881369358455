import React, { useEffect, useState } from "react";
import { Row, Col } from 'react-bootstrap';
import ResultCount from "./ResultCount";
import SearchResultModel from "../../../domain/entities/searchoutput/SearchResultModel";
import { RootState } from "../../../infrastructure/redux/reducer";
import { useSelector } from "react-redux";


const PDFWork: React.FC = () => {
    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    
    return (
        <>
            <Row className="d-flex">
                <ResultCount count={searchResult?.experiences?.length ?? 0} text={"Work"} />
            </Row>
            <Row className="work p-0">
                {searchResult?.experiences?.map(e =>
                    <Col className="col-4" key={Math.random()}>
                        <Row className="work-card box-shadow bg-white">
                            <Row className="work-title">
                                <Col className="col-auto img-box px-2">
                                    <img width='30px' height='18px' src="/images/search-results/work.png" />
                                </Col>
                                {e.companyName}
                            </Row>
                            <hr />
                            <Row className="work-data py-2">
                                <Col className="col-auto p-0">
                                    <img src="/images/settings.jfif" />
                                </Col>
                                <Col xs="auto" className="work-data-header p-0">Role:</Col>
                                <Col className="px-1">{e.titleRole ?? <span className="not-available">Not Available</span>}</Col>
                            </Row>
                            <Row className="work-data py-2">
                                <Col className="col-auto p-0">
                                    <img src="/images/employee-title.jfif" />
                                </Col>
                                <Col xs="auto" className="work-data-header p-0">Title:</Col>
                                <Col className="px-1">{e.titleName ?? <span className="not-available">Not Available</span>}</Col>
                            </Row>
                            <Row className="work-data py-2">
                                <Col className="col-auto img-box-clear px-2">
                                    <img width='30px' height='18px' src="/images/search-results/time.png" />
                                </Col>
                                <Col xs="auto" className="work-data-header p-0">From:</Col>
                                <Col className="px-1">{e.startYear ?? <span className="not-available">Not Available</span>} </Col>
                                <Col xs="auto" className="work-data-header p-0">To:</Col>
                                <Col className="px-1">{e.endYear ?? <span className="not-available">Not Available</span>} </Col>
                            </Row>

                        </Row>
                    </Col>)}
            </Row>
        </>
    );
};

export default PDFWork;
