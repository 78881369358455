import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";
import CreateSearch from "./CreateSearch_new";

type SearchComponentState = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  ageRangeFrom: string;
  ageRangeTo: string;
  dateOfLoss: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  employer: string;
  caseId: string;
  newCaseName: string;
  email: string[];
  phone: string[];
  emailFormValues: string[];
  phoneFormValues: string[];

  validation: any;
  isLoading: boolean;
  isShowError: boolean;
  errorMessages: string[];
  isSuccess: boolean;
};

const CreateSearchPage: React.FC = () => {

  return (
    <>
      <Helmet>
        <title>Social E-Profiler - New Search</title>
        <meta name="description" content="Social E-Profiler" />
      </Helmet>
      <CreateSearch />
    </>
  );
};

export default CreateSearchPage;
