import React, { useState } from 'react';
import Question from "../../../domain/entities/usersettings/Question";
import Result from "../../../domain/common/Result";
import GlbaDppaOptionsModel from "../../../domain/entities/auth/models/GlbaDppaOptionsModel";
import { toast } from 'react-toastify';
import UserInfoModel from '../../../domain/entities/admin/models/UserInfoModel';
import UserEventModel from '../../../domain/entities/admin/models/UserEventModel';
import CompanyNameModel from '../../../domain/entities/admin/models/CompanyNameModel';
import UserAccountApi from '../../../data/admin/UserAccountApi';
import { HttpClient } from '../../../infrastructure/utils/fetchInterceptor';
import RegistrationDataApi from '../../../data/registrationData/RegistrationDataApi';
import CompanyApi from '../../../data/admin/CompanyApi';
import UserSettingApi from '../../../data/usersettings/UserSettingApi';
import UserRoleApi from '../../../data/admin/UserRoleApi';
import PersonInfoEditState from '../../models/PersonInfoEditState';
import SelectOption from '../../models/SelectOption';
import SearchApi from '../../../data/search/SearchApi';
import GLBAOption from '../../../domain/entities/usersettings/GLBAOption';
import DPPAOption from '../../../domain/entities/usersettings/DPPAOption';

const SUPER_ADMIN_ROLE_NAME = "Super Admin";

function useUserInfoEditViewModel() {

    const [state, setState] = useState<PersonInfoEditState>({} as PersonInfoEditState);
    
    const [DPPAOptionId, setDPPAOptionId] = React.useState<number>(0);
    const [GLBAOptionId, setGLBAOptionId] = React.useState<number>(0);
    const [accountStatus, setAccountStatus] = React.useState<string>('');
    const [userEvents, setUserEvents] = React.useState<UserEventModel[]>([]);
    const [GLBAOptions, setGLBAOptions] = React.useState<GLBAOption[]>([]);
    const [DPPAOptions, setDPPAOptions] = React.useState<DPPAOption[]>([]);
    const [Companies, setCompanies] = React.useState<CompanyNameModel[]>([]);
    const [Questions, setQuestions] = React.useState<Question[]>([]);
    
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isShowError, setIsShowError] = React.useState<boolean>(false);
    const [errorMessages, setErrorMessages] = React.useState<string[]>([]);
    
    const [showChangeAccountStatus, setShowChangeAccountStatus] = React.useState<boolean>(false);
    const [deactivateReason, setDeactivateReason] = React.useState<string | undefined>('');
    const [deactivateReasonInput, setDeactivateReasonInput] = React.useState<string | undefined>('');
    const [enableSavingStatusChange, setEnableSavingStatusChange] = React.useState<boolean>(false);
    const [lastTosAcceptedDate, setLastTosAcceptedDate] = React.useState<Date | null>(null);

    const [isShowAuthorizedManagerDeletionWarningModal, setIsShowAuthorizedManagerDeletionWarningModal] = React.useState<boolean>(false);

    const userAccountRepo = new UserAccountApi(new HttpClient());
    const registrationDataRepository = new RegistrationDataApi(new HttpClient());
    const companyRepo = new CompanyApi(new HttpClient());
    const userSettingRepository = new UserSettingApi(new HttpClient());
    const userRoleRepo = new UserRoleApi(new HttpClient());
    const searchRepo = new SearchApi(new HttpClient());

    const hideAuthorizedManagerDeletionWarning = () => setIsShowAuthorizedManagerDeletionWarningModal(false);

    const openChangeAccountStatusModal = () => {
        setShowChangeAccountStatus(true);
        setEnableSavingStatusChange(false);
    }

    const closeChangeAccountStatusModal = () => {
        setShowChangeAccountStatus(false);
        setDeactivateReasonInput('');
    }

    const onDeactivateReasonChange = (e: React.FormEvent) => {
        const reason = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        setDeactivateReasonInput(reason);
        if (!reason.replaceAll(' ', ''))
            setEnableSavingStatusChange(false);
        else
            setEnableSavingStatusChange(true);
    }

    const deactivateAccount = (adminFName: string, adminLName: string) => {
        setShowChangeAccountStatus(false);
        setIsLoading(true);

        userAccountRepo.DeactivateUserAccount(state.userId ?? '', deactivateReasonInput || '').then(r => {
            setIsLoading(false);

            if (r.isSuccess) {
                setAccountStatus('Inactive');
                setDeactivateReason(deactivateReasonInput);
                notify();
            }

            setIsShowError(!r.isSuccess);
            userEvents.push({ typeId: 4, typeName: "Deactivation", date: new Date(), userId: state.userId ?? '', id: 0, details: JSON.stringify({ Reason: deactivateReasonInput }), adminFirstName: adminFName, adminLastName: adminLName });
            setUserEvents([...userEvents]);

        }).catch(_ => {
            setIsLoading(false);
            setIsShowError(true);
        });
    }

    const activateAccount = (adminFName: string, adminLName: string) => {
        setShowChangeAccountStatus(false);
        setIsLoading(true);

        userAccountRepo.ActivateUserAccount(state.userId ?? '').then(r => {
            setIsLoading(false);

            if (r.isSuccess) {
                setAccountStatus('Active');
                setDeactivateReason('');
                setDeactivateReasonInput('');
                userEvents.push({ typeId: 3, typeName: "Activation", date: new Date(), userId: state.userId ?? '', id: 0, details: undefined, adminFirstName: adminFName, adminLastName: adminLName })
                setUserEvents([...userEvents]);
                notify();
            }

            setIsShowError(!r.isSuccess);
        }).catch(_ => {
            setIsLoading(false);
            setIsShowError(true);
        });
    }

    const notify = () => {
        toast.success("Updated successfully.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            className: "avoid-nav-bar",
            bodyClassName: "toast-message",
        });
    };

    const confirmSave = (data: PersonInfoEditState, event: any) => {
        const continueBtn = event?.nativeEvent?.submitter?.name == "continueBtn";
        if (data.isAuthorizedManager != state.isAuthorizedManager && !data.isAuthorizedManager) {
            if (!continueBtn) {
                setIsShowAuthorizedManagerDeletionWarningModal(true);
                return true;
            }
        }
        return false;
    }

    const onSave = async (data: PersonInfoEditState, event: any) => {
        try {

            if (confirmSave(data, event))
                return;

            const model = {} as UserInfoModel;
            model.userId = data.userId ?? '';
            if (data.firstName != state.firstName)
                model.firstName = data.firstName;
            if (data.lastName != state.lastName)
                model.lastName = data.lastName;
            if (data.phoneNumber != state.phoneNumber)
                model.phoneNumber = data.phoneNumber?.replaceAll(/[.|(|)|\-| ]/g, "");
            if (data.alternativeFirstName != state.alternativeFirstName)
                model.alternativeFirstName = data.alternativeFirstName;
            if (data.alternativeLastName != state.alternativeLastName)
                model.alternativeLastName = data.alternativeLastName;
            if (data.alternativeEmail != state.alternativeEmail)
                model.alternativeEmail = data.alternativeEmail;
            if (data.alternativePhoneNumber != state.alternativePhoneNumber)
                model.alternativePhoneNumber = data.alternativePhoneNumber?.replaceAll(/[.|(|)|\-| ]/g, "");
            if (data.secondaryEmail != state.secondaryEmail)
                model.secondaryEmail = data.secondaryEmail;
            if (data.managerName != state.managerName)
                model.managerName = data.managerName;
            if (data.employerName != state.employerName)
                model.employerName = data.employerName;
            if (data.employerAddress != state.employerAddress)
                model.employerAddress = data.employerAddress;
            if (data.employerCity != state.employerCity)
                model.employerCity = data.employerCity;
            if (data.selectedState.label != state.employerState)
                model.employerState = data.selectedState.label;
            if (data.employerZIPCode != state.employerZIPCode)
                model.employerZIPCode = data.employerZIPCode;
            if (DPPAOptionId != state.dppaOptionId)
                model.dppaOptionId = DPPAOptionId;
            if (GLBAOptionId != state.glbaOptionId)
                model.glbaOptionId = GLBAOptionId;
            if (data.selectedCompany?.value != state.selectedCompany?.value)
                model.companyId = data.selectedCompany?.value;
            if (data.selectedQuestion?.value != state.selectedQuestion?.value)
                model.securityQuestionId = data.selectedQuestion?.value;
            if (data.securityQuestionAnswer != state.securityQuestionAnswer)
                model.securityQuestionAnswer = data.securityQuestionAnswer;
            if (data.isAuthorizedManager != state.isAuthorizedManager)
                model.isAuthorizedManager = data.isAuthorizedManager ?? false;
            if (data.claimOffice != state.claimOffice)
                model.claimOffice = data.claimOffice;


            setErrorMessages([]);
            setIsShowAuthorizedManagerDeletionWarningModal(false);
            setIsLoading(true);
            setIsShowError(false);

            let result;

            if (data.isAuthorizedManager != state.isAuthorizedManager && !data.isAuthorizedManager) {
                result = await userSettingRepository.DeleteAllUserSearchRequestPermissions(state.userId ?? '');
                !result.isSuccess ? setIsShowError(true) : null;
            }

            if (data.isSuperAdmin != state.isSuperAdmin) {
                if (!data.isSuperAdmin)
                    result = await userRoleRepo.DeleteRole({ userId: state.userId ?? '', roleName: SUPER_ADMIN_ROLE_NAME });
                else
                    result = await userRoleRepo.AddRole({ userId: state.userId ?? '', roleName: SUPER_ADMIN_ROLE_NAME });

                !result.isSuccess ? setIsShowError(true) : null;
            }

            if (Object.keys(model).length > 1) {
                result = await userAccountRepo.UpdateUserInfo(model);
                !result.isSuccess ? setIsShowError(true) : null;
            }

            setIsLoading(false);

            if (!isShowError) {
                notify();
                setState({ ...state, ...model, isSuperAdmin: data.isSuperAdmin, selectedQuestion: data.selectedQuestion, selectedState: data.selectedState, selectedCompany: data.selectedCompany });
            }

        } catch (e: any) {
            setIsLoading(false);
            setIsShowError(true);
            setErrorMessages([...errorMessages, e.message]);
        }
    }

    const getData = async (userId: string) => {
        try {
            setIsLoading(true);

            let userState = {} as PersonInfoEditState;
            let questionList: SelectOption[] = [];
            let companyList: SelectOption[] = [];

            const roleResult = await userRoleRepo.IsUserHasRole({ userId: userId, roleName: SUPER_ADMIN_ROLE_NAME });
            const isSuperAdmin = roleResult.isSuccess ? roleResult.value as boolean : false;

            const stateResult = await searchRepo.getStates();
            const stateList = stateResult?.map(c => ({ value: c.id, label: c.name } as SelectOption)) ?? [];

            const questionsResult = await registrationDataRepository.Questions();
            if (questionsResult.isSuccess) {
                questionList = (questionsResult?.value as Question[])?.map(q => ({ value: q.id, label: q.text } as SelectOption));
                setQuestions(questionsResult.value ?? []);
            }
           
            const companiesResult = await companyRepo.getCompaniesNames();
            if (companiesResult.isSuccess) {
                companyList = (companiesResult?.value as CompanyNameModel[])?.map(c => ({ value: c.id, label: c.name } as SelectOption));
                setCompanies(companiesResult.value as CompanyNameModel[]);
            }
            
            const glbaDppaResult = await registrationDataRepository.GetGlbaDppaData();
            if (glbaDppaResult.isSuccess) {
                const value: GlbaDppaOptionsModel = glbaDppaResult.value as GlbaDppaOptionsModel;
                setGLBAOptions(value.glbaOptions);
                setDPPAOptions(value.dppaOptions);
            }

            const userDataResult = await userAccountRepo.GetUserInfo(userId);
            if (userDataResult.isSuccess) {

                const user = userDataResult.value as UserInfoModel;
                userState = {
                    ...user as unknown as PersonInfoEditState,
                    selectedState: stateList?.find(s => s.label == user.employerState) ?? {} as SelectOption,
                    selectedQuestion: questionList?.find(q => q.value == user.securityQuestionId) ?? {} as SelectOption,
                    selectedCompany: companyList?.find(c => c.value == user.companyId) ?? {} as SelectOption,
                };

                setDPPAOptionId(user.dppaOptionId ?? 0);
                setGLBAOptionId(user.glbaOptionId ?? 0);
                setAccountStatus(user.accountStatus || '');
                setUserEvents(user.userEvents || []);
                setLastTosAcceptedDate(user.lastTosAcceptedDate);
            }

            setState(prevState => ({ ...prevState, ...userState, isSuperAdmin: isSuperAdmin, questionList: questionList, companyList: companyList, stateList: stateList }));
            setIsLoading(false);
            setIsShowError(!roleResult.isSuccess || !userDataResult.isSuccess || !questionsResult.isSuccess || !glbaDppaResult.isSuccess || !companiesResult.isSuccess);
            setErrorMessages([...errorMessages, roleResult.error, userDataResult.error, questionsResult.error, glbaDppaResult.error, companiesResult.error]);

        } catch (e: any) {
            setIsLoading(false);
            setIsShowError(true);
            setErrorMessages([...errorMessages, e.message]);
            return Result.Fail(e.message, 500);
        }
    };

    const onGlbaChange = (id: number) => setGLBAOptionId(id);
    const onDppaChange = (id: number) => setDPPAOptionId(id);

    return {
        state,
        DPPAOptionId,
        GLBAOptionId,
        isLoading,
        isShowError,
        errorMessages,
        GLBAOptions,
        DPPAOptions,
        deactivateReason,
        deactivateReasonInput,
        accountStatus,
        showChangeAccountStatus,
        userEvents,
        lastTosAcceptedDate,
        isShowAuthorizedManagerDeletionWarningModal,
        enableSavingStatusChange,
        Companies,
        Questions,

        getData,
        openChangeAccountStatusModal,
        onGlbaChange,
        onDppaChange,
        onDeactivateReasonChange,
        closeChangeAccountStatusModal,
        deactivateAccount,
        activateAccount,
        setIsShowError,
        hideAuthorizedManagerDeletionWarning,
        onSave
    };

}

export default useUserInfoEditViewModel;
