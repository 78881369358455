import React, { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { useParams } from "react-router";
import BackArrowSvgIcon from "../../components/svg-icons/BackArrow";
import LinkedToCompany from "../../components/person-info-edit/LinkedToCompany";
import useAccountRequestViewModel from "../../view-model/admin/AccountRequestViewModel_new";


const AccountRequest: React.FC = () => {

    const { id } = useParams();

    const {
        accountRequest,
        companiesNames,
        autoDetectedCompanyId,
        isLoading,
        isShowError,
        errorMessages,
        showAccountRequestApprovalModal,
        showAccountRequestDenialModal,
        denialReason,
        denialNote,
        actionSuccedded,
        disabledBtns,

        getData,
        onCompanyChanged,
        onDenyBtnClick,
        onApproveBtnClick,
        hideAccountRequestApprovalModal,
        onAccountRequestApprove,
        hideAccountRequestDenialModal,
        onAccountRequestDeny,
        setIsShowError,
        onDenialReasonChange,
        onDenialNoteChange
    } = useAccountRequestViewModel();

    useEffect(() => {
        getData(id ? +id : 0);
    }, []);

    return (
        <div className="container">
            <div className="account-info-details">
                <Row><span className="admin-page-title"><a href="/admin/pendingRegistration"><BackArrowSvgIcon /></a>&nbsp;&nbsp;Account Request</span></Row>
                <Row className="account-request-section">
                    <Row className="header-section">
                        <Col md="auto" className="p-0 section-title">Personal Information</Col>
                        <Col><hr /></Col>
                    </Row>
                    <Row className="info-section">
                        <Row className="titile-row">
                            <Col><span className="account-request-title">First Name</span></Col>
                            <Col><span className="account-request-title">Last Name</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{accountRequest?.firstName}</span></Col>
                            <Col><span className="value">{accountRequest?.lastName}</span></Col>
                        </Row>
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Phone</span></Col>
                            <Col><span className="account-request-title">Email</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{accountRequest?.phoneNumber}</span></Col>
                            <Col><span className="value">{accountRequest?.email}</span></Col>
                        </Row>
                    </Row>
                </Row>
                <Row className="account-request-section">
                    <Row className="header-section">
                        <Col md="auto" className="p-0 section-title">Alternative Contact</Col>
                        <Col><hr /></Col>
                    </Row>
                    <Row className="info-section">
                        <Row className="titile-row">
                            <Col><span className="account-request-title">First Name</span></Col>
                            <Col><span className="account-request-title">Last Name</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{accountRequest?.alternativeFirstName || '--'}</span></Col>
                            <Col><span className="value">{accountRequest?.alternativeLastName || '--'}</span></Col>
                        </Row>
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Phone</span></Col>
                            <Col><span className="account-request-title">Email</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{accountRequest?.alternativePhoneNumber || '--'}</span></Col>
                            <Col><span className="value">{accountRequest?.alternativeEmail || '--'}</span></Col>
                        </Row>
                    </Row>
                </Row>
                <Row className="account-request-section">
                    <Row className="header-section">
                        <Col md="auto" className="p-0 section-title">Account/Password Recovery</Col>
                        <Col><hr /></Col>
                    </Row>
                    <Row className="info-section">
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Security Question</span></Col>
                            <Col><span className="account-request-title">Answer</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{accountRequest?.securityQuestion}</span></Col>
                            <Col><span className="value">{accountRequest?.answer}</span></Col>
                        </Row>
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Secondary Email</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{accountRequest?.secondaryEmail}</span></Col>
                        </Row>
                    </Row>
                </Row>
                <Row className="account-request-section">
                    <Row className="header-section">
                        <Col md="auto" className="p-0 section-title">Company Information</Col>
                        <Col><hr /></Col>
                    </Row>
                    <Row className="info-section">
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Manager Name</span></Col>
                            <Col><span className="account-request-title">Employer Name</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{accountRequest?.managerName}</span></Col>
                            <Col><span className="value">{accountRequest?.employerName}</span></Col>
                        </Row>
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Employer Address</span></Col>
                            <Col><span className="account-request-title">Employer City</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{accountRequest?.employerAddress}</span></Col>
                            <Col><span className="value">{accountRequest?.employerCity}</span></Col>
                        </Row>
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Employer State</span></Col>
                            <Col><span className="account-request-title">Employer Address Zip Code</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{accountRequest?.employerState}</span></Col>
                            <Col><span className="value">{accountRequest?.employerZIPCode}</span></Col>
                        </Row>
                        <Row className="titile-row">
                            <Col><span className="account-request-title">Claim Office</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{accountRequest?.claimOffice}</span></Col>
                        </Row>
                    </Row>
                </Row>
                <Row className="account-request-section">
                    <Row className="header-section">
                        <Col md="auto" className="p-0 section-title">GLBA/DPPA</Col>
                        <Col><hr /></Col>
                    </Row>
                    <Row className="info-section">
                        <Row className="titile-row">
                            <Col><span className="account-request-title">GLBA</span></Col>
                            <Col><span className="account-request-title">DPPA</span></Col>
                        </Row>
                        <Row className="data-row">
                            <Col><span className="value">{accountRequest?.glbaOption}</span></Col>
                            <Col><span className="value">{accountRequest?.dppaOption}</span></Col>
                        </Row>
                    </Row>
                </Row>
                <LinkedToCompany selectedCompanyId={autoDetectedCompanyId} onChange={onCompanyChanged} disabled={actionSuccedded} companyNames={companiesNames} />
                <Row className="account-request-btns d-flex">
                    <button disabled={actionSuccedded} className="btn previous-btn" onClick={() => onDenyBtnClick()}>Deny</button>
                    <button disabled={actionSuccedded || disabledBtns} className="btn next-btn" onClick={() => onApproveBtnClick()}>Approve</button>
                </Row>
            </div>
            <Modal show={showAccountRequestApprovalModal}>
                <Modal.Body className="modal-card approve-account-modal-card">
                    <div className="approve-account-modal">
                        <Row className="approve-modal-header">
                            <Col className="p-0"><span className="cancel-modal-title">Approve Account</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={hideAccountRequestApprovalModal}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="cancel-modal-body p-0">Are you sure you want to approve this account?</p>
                        </Row>
                        <Row className="modal-btn-row">
                            <Col md="auto" className="p-0"><a onClick={hideAccountRequestApprovalModal} className="btn previous-btn">Cancel</a></Col>
                            <Col className="p-0"><a onClick={onAccountRequestApprove} className="btn next-btn">Approve</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showAccountRequestDenialModal}>
                <Modal.Body className="modal-card deny-account-modal-card">
                    <div className="deny-account-modal">
                        <Row className="approve-modal-header">
                            <Col ><span className="cancel-modal-title">Deny Account</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={hideAccountRequestDenialModal}>x</span></Col>
                        </Row>
                        <Row><span className="row-title">Denial Reason</span></Row>
                        <Row><textarea className="form-control" id="denialReason" value={denialReason} onChange={onDenialReasonChange} /></Row>
                        <Row><span className="row-title">Note</span></Row>
                        <Row><textarea className="form-control" id="denialNote" value={denialNote} onChange={onDenialNoteChange} /></Row>
                        <Row className="deny-modal-btn-row">
                            <Col md="auto" className="p-0"><a onClick={hideAccountRequestDenialModal} className="btn previous-btn">Cancel</a></Col>
                            <Col className="p-0"><a onClick={onAccountRequestDeny} className="btn next-btn">Confirm Denial</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
            <TailSpin
                wrapperClass="cover-spin"
                visible={isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
            <Modal show={isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setIsShowError(false)}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setIsShowError(false)} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default AccountRequest;