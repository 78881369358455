import { Col, Modal, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import BackArrowSvgIcon from "../../components/svg-icons/BackArrow";
import { TailSpin } from "react-loader-spinner";
import GlbaDppaOptions from "../../components/GlbaDppaOptions";
import LinkedToCompany from "../../components/person-info-edit/LinkedToCompany_new";
import TermsAndConditionsAndPrivacyPolicy from "../../components/person-info/TermsAndConditionsAndPrivacyPolicy";
import UserEventsHistory from "./UserEventsHistory";
import useUser from "../../../infrastructure/hooks/useUser";
import useUserInfoEditViewModel from "../../view-model/admin/UserInfoEditViewModel_new";
import CompanyInformation from "../../components/person-info-edit/CompanyInformation_new";
import AccountPasswordRecovery from "../../components/person-info-edit/AccountPasswordRecovery_new";
import AlternativeContact from "../../components/person-info-edit/AlternativeContact_new";
import PersonalInformation from "../../components/person-info-edit/PersonalInformation_new";
import { useForm } from "react-hook-form";


const UserInfoEdit: React.FC = () => {

    const {
        state,
        DPPAOptionId,
        GLBAOptionId,
        isLoading,
        isShowError,
        GLBAOptions,
        DPPAOptions,
        deactivateReasonInput,
        accountStatus,
        showChangeAccountStatus,
        userEvents,
        lastTosAcceptedDate,
        Companies,
        Questions,
        isShowAuthorizedManagerDeletionWarningModal,
        enableSavingStatusChange,

        getData,
        openChangeAccountStatusModal,
        onGlbaChange,
        onDppaChange,
        onDeactivateReasonChange,
        closeChangeAccountStatusModal,
        deactivateAccount,
        activateAccount,
        setIsShowError,
        hideAuthorizedManagerDeletionWarning,
        onSave
    } = useUserInfoEditViewModel();

    const { id } = useParams();
    const { user } = useUser();

    const { control, handleSubmit, reset, formState: { errors, isValid }, watch, setValue } = useForm({ defaultValues: state, mode: "onTouched" });

    useEffect(() => { reset(state) }, [state]);
    useEffect(() => { getData(id || '') }, []);

    let currentDeactivateReason = "";

    const activationEvents = userEvents.filter(e => e.typeId == 3 || e.typeId == 4);
    if (activationEvents.length > 0) {
        const lastEvent = activationEvents[activationEvents.length - 1];
        const lastActivationDetails = JSON.parse(lastEvent?.details ?? "{}");
        currentDeactivateReason = lastActivationDetails["Reason"];
    }

   
    return <div className="container user-info">
        <Row className="header">
            <Col><a href="/admin/user"> <BackArrowSvgIcon /></a> {state.firstName}&apos;s Profile</Col>
            <Col md="auto" className="p-0">
                <Row>
                    {currentDeactivateReason && <Col className="inactive-account-reason">Reason: {currentDeactivateReason}</Col>}
                    <Col
                        md="auto"
                        onClick={openChangeAccountStatusModal}
                        title="Edit"
                        className={accountStatus == 'Active' ? "account-status active-account-status" : "account-status inactive-account-status"}>
                        {accountStatus == 'Active' ? 'Active' : 'Inactive'}
                    </Col>
                </Row>
            </Col>
        </Row>
        <form id="update-account-form" onSubmit={handleSubmit(onSave)}>
            <PersonalInformation
                editableEmail={false}
                isAuthorizedManagerEnabled={true}
                isAdminEnabled={true}
                control={control}
            />
            <AlternativeContact
                control={control}
            />
            <AccountPasswordRecovery
                state={state}
                control={control}
                watch={watch}
                setValue={setValue}
            />
            <CompanyInformation
                state={state}
                control={control}
            />
            <LinkedToCompany state={state} control={control} />
            <GlbaDppaOptions glbaSelectedOption={GLBAOptionId} dppaSelectedOption={DPPAOptionId} onChangeGlba={onGlbaChange} onChangeDppa={onDppaChange} />
            <UserEventsHistory userEvents={userEvents} GLBAOptions={GLBAOptions} DPPAOptions={DPPAOptions} companies={Companies} questions={Questions} />
            <TermsAndConditionsAndPrivacyPolicy DateOfAcceptance={lastTosAcceptedDate} />
            <div className="save-btn"><button disabled={!isValid} className="btn next-btn">Save</button></div>
        </form>

        <Modal show={showChangeAccountStatus}>
            <Modal.Body className={(accountStatus == 'Active' ? "deactivate " : "activate ") + "modal-card change-account-status-modal-card"}>
                <div className="deny-account-modal">
                    <Row className="change-account-status-modal-header">
                        <Col className="p-0"><span className="cancel-modal-title">Account Status</span></Col>
                        <Col md="auto" className="close-modal-icon"><span onClick={() => closeChangeAccountStatusModal()}>x</span></Col>
                    </Row>
                    <Row>
                        <Col><input disabled={accountStatus == 'Active'} checked type="radio" /><span className="status-name">Active</span></Col>
                        <Col><input disabled={accountStatus != 'Active'} checked type="radio" /><span className="status-name">Inactive</span></Col>
                    </Row>
                    {accountStatus != 'Active' && <Row>Are you sure you want to activate this account?</Row>}
                    {accountStatus == 'Active' &&
                        <Row>
                            <Row className="p-0"><span className="row-title">Reason<span className="mandatory-symbol">*</span></span></Row>
                            <Row><textarea value={deactivateReasonInput} className="form-control" onChange={onDeactivateReasonChange} maxLength={30} /></Row>
                        </Row>
                    }
                    <Row className="deny-modal-btn-row">
                        <Col md="auto" className="p-0"><a onClick={() => closeChangeAccountStatusModal()} className="btn previous-btn">Cancel</a></Col>
                        {accountStatus == 'Active' && <Col className="p-0"><button onClick={() => deactivateAccount(user.firstName, user.lastName)} disabled={!enableSavingStatusChange} className="btn next-btn">Save</button></Col>}
                        {accountStatus != 'Active' && <Col className="p-0"><button onClick={() => activateAccount(user.firstName, user.lastName)} className="btn next-btn">Yes</button></Col>}
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
        <Modal show={isShowAuthorizedManagerDeletionWarningModal}>
            <Modal.Body className="modal-card warning-modal-card authorized-manager-warning-modal">
                <div>
                    <Row className="warning-modal-header">
                        <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                        <Col ><span className="cancel-modal-title">Warning</span></Col>
                        <Col md="auto" className="close-modal-icon"><span onClick={hideAuthorizedManagerDeletionWarning}>x</span></Col>
                    </Row>
                    <Row>
                        <p className="p-0 warning-modal-body">You have unchecked&nbsp;&apos;Authorized Manager&apos;.&nbsp;If you continue,&nbsp;this user will lose all access to other users&apos;&nbsp;searches. Do you wish to continue?</p>
                    </Row>
                    <Row className="warning-modal-btn-row">
                        <Col md="auto" className="p-0"><a onClick={hideAuthorizedManagerDeletionWarning} className="btn previous-btn">No, I will change my update</a></Col>
                        <Col className="p-0"><button form="update-account-form" name="continueBtn" className="btn next-btn">Yes, I want to continue</button></Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
        <TailSpin
            wrapperClass="cover-spin"
            visible={isLoading}
            height={50}
            width="50"
            color="#FF5733"
            ariaLabel="loading"
        />
        <Modal show={isShowError}>
            <Modal.Body className="modal-card warning-modal-card error-modal">
                <div>
                    <Row className="warning-modal-header">
                        <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                        <Col ><span className="cancel-modal-title">Server Error</span></Col>
                        <Col md="auto" className="close-modal-icon"><span onClick={() => setIsShowError(false)}>x</span></Col>
                    </Row>
                    <Row>
                        <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                    </Row>
                    <Row className="warning-modal-btn-row">
                        <Col className="p-0"><a onClick={() => setIsShowError(false)} className="btn warning-btn">Try Again Later</a></Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    </div>
}

export default UserInfoEdit;