import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import AuthApi from "../../data/auth/AuthApi";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";
import SuccessfulSetPassword from "../components/SuccessfulSetPassword";
import FormValidator from "../utils/FormValidator";


const SetPassword: React.FC = () => {

    const [state, setState] = useState({ password: '', confirmPassword: '', validation: { password: '', confirmPassword: '' }, valid: false });
    const [displayState, setDisplayState] = useState({ isLoading: false, isShowError: false, displayForm: true, displaySuccess: false, error: '' })

    const searchParams = window.location.href.split('&');
    const email = searchParams[0].split('=')[1];
    const token = searchParams[1].split('=')[1];

    const onChangePassword = (e: React.FormEvent) => {
        const val = (e.currentTarget as HTMLInputElement).value;

        if (FormValidator.isValidPassword(val) && val == state.confirmPassword)
            setState({ ...state, password: val, validation: { password: '', confirmPassword: '' }, valid: true });

        else if (!val.replaceAll(' ', ''))
            setState({ ...state, password: val, validation: { password: 'Password can not be empty', confirmPassword: '' }, valid: false });
        
        else if (!FormValidator.isValidPassword(val))
            setState({ ...state, password: val, validation: { password: 'Password must be 8 characters at least, contains: upper case, lower case, special chacter and digit', confirmPassword: state.validation.confirmPassword }, valid: false });  

        else if (FormValidator.isValidPassword(val))
            setState({ ...state, password: val, validation: { password: '', confirmPassword: state.validation.confirmPassword }, valid: false });
    }

    const onChangeConfirmPassword = (e: React.FormEvent) => {
        const val = (e.currentTarget as HTMLInputElement).value;

        if (state.password == val && FormValidator.isValidPassword(state.password))
            setState({ ...state, confirmPassword: val, validation: { password: '', confirmPassword: '' }, valid: true });

        else if (!val.replaceAll(' ', ''))
            setState({ ...state, confirmPassword: val, validation: { password: state.validation.password, confirmPassword: 'Confirm Password can not be empty' }, valid: false });

        if (val.replaceAll(' ', '') && state.password != val)
            setState({ ...state, confirmPassword: val, validation: { password: state.validation.password, confirmPassword: 'Confirm Password must be equal to Password' }, valid: false }); 
    }

    const notifySuccess = () => {
        toast.success("Your password is set successfully", {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            bodyClassName: "toast-message",
        });
    };

    const submit = () => {
        const repo = new AuthApi(new HttpClient());
        setDisplayState({ ...displayState, isLoading: true });

        repo.setPassword({ email: email, token: token, password: state.password }).then(r => {

            if (!window.location.pathname.includes('register')) {
                if (r.isSuccess) {
                    setDisplayState({ ...displayState, isLoading: false, isShowError: false, error: '' });
                    setState({ ...state, valid: false });
                    notifySuccess();
                    setTimeout(() => window.location.href = '/login', 3000);
                }
                else
                    setDisplayState({ ...displayState, isLoading: false, isShowError: true, error: (r.value as any)?.detail });
            }
            else {
                if (r.isSuccess)
                    setDisplayState({ isLoading: false, displaySuccess: true, displayForm: false, isShowError: false, error: '' });
                else
                    setDisplayState({ ...displayState, isLoading: false, isShowError: true, error: (r.value as any)?.detail });
            }
        })
    }

    const resetIsShowError = () => setDisplayState({ ...displayState, isShowError: false });

    return <>
        {displayState.displayForm &&
            <div className="registration successful-register main-page set-password-wrapper">
                <div className="section-wrapper">
                    <p><b>Email: {email}</b></p>
                    <div className="input-group-meta form-group mb-30 form-floating col">
                        <input
                            type="password"
                            placeholder="Password"
                            id="password"
                            onChange={onChangePassword}
                            value={state.password}
                            className={`form-control ${state.validation.password ? "is-invalid" : ""}`}
                        />
                        <label className="form-label">Password<span className="mandatory-symbol">*</span></label>
                        {state.validation.password && (
                            <div className="invalid-feedback">
                                {state.validation.password}
                            </div>
                        )}
                    </div>
                    <div className="input-group-meta form-group mb-30 form-floating col">
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            id="confirmPassword"
                            onChange={onChangeConfirmPassword}
                            value={state.confirmPassword}
                            className={`form-control ${state.validation.confirmPassword ? "is-invalid" : ""}`}
                        />
                        <label className="form-label">Confirm Password<span className="mandatory-symbol">*</span></label>
                        {state.validation.confirmPassword && (
                            <div className="invalid-feedback">
                                {state.validation.confirmPassword}
                            </div>
                        )}
                    </div>
                    <button disabled={!state.valid} className="btn next-btn" onClick={submit}>Submit</button>
                    <div className="align-center"><a className="app-link" href="/login">Login</a></div>
                </div >
            </div>
        }
        {displayState.displaySuccess && <SuccessfulSetPassword/>}
        
        <TailSpin
            wrapperClass="cover-spin"
            visible={displayState.isLoading}
            height={50}
            width="50"
            color="#FF5733"
            ariaLabel="loading"
        />
        <Modal show={displayState.isShowError}>
            <Modal.Body className="modal-card warning-modal-card error-modal">
                <div>
                    <Row className="warning-modal-header">
                        <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                        <Col ><span className="cancel-modal-title">Server Error</span></Col>
                        <Col md="auto" className="close-modal-icon"><span onClick={resetIsShowError}>x</span></Col>
                    </Row>
                    <Row>
                        <p className="p-0 warning-modal-body">{displayState.error}</p>
                    </Row>
                    <Row className="warning-modal-btn-row">
                        <Col className="p-0"><a onClick={resetIsShowError} className="btn warning-btn">Try Again Later</a></Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    </>;
}

export default SetPassword;