import React from "react";
import { Control, Controller, ControllerRenderProps } from "react-hook-form";
import Select from "react-select";
import SelectOption from "../models/SelectOption";

type CustomSelectProps = {
	name: string;
	control: Control<any, any>;
	placeholder: string;
	label?: string;
	options: SelectOption[];

	required?: boolean;
	hasRequiredSymbol?: boolean;
	requiredMessage?: string;

	validate?: (value: string) => string | boolean;
	onChange?: () => void;

	showError?: boolean;
	isDisabled?: boolean;
	isClearable?: boolean;
};

function CustomSelect({
	name,
	control,
	options,
	requiredMessage = "",
	required = false,
	validate,
	showError = true,
	isDisabled = false,
	isClearable = true,
	hasRequiredSymbol = false,
	label,
	placeholder,
	onChange
}: CustomSelectProps) {

	const error = control?.getFieldState(name)?.error;

	const onChangeAction = (value: number, field: ControllerRenderProps<any,string>) => {
		onChange && onChange();
		field.onChange(value);
	}

	return (
		<>
			<Controller
				name={name}
				control={control}
				render={({ field }) => (
					<Select
						{...field}
						placeholder={placeholder}
						options={options}
						isClearable={isClearable}
						className="form-select form-control"
						isDisabled={isDisabled}
						onChange={(value) => onChangeAction(value, field)}
					/>
				)}
				rules={{
					required: required && requiredMessage,
					validate: validate && validate
				}}
			/>
			{label && <label className="form-label domain-label">{label}{hasRequiredSymbol && <span className="mandatory-symbol">*</span>}</label>}
			{showError && error && <div className="invalid-feedback">{error?.message}</div>}
		</>
	);
}

export default CustomSelect;
