import Result from "../../domain/common/Result";
import UserSocialProfileModel from "../../domain/entities/search/UserSocialProfileModel";
import SocialPlatformProfileModel from "../../domain/entities/searchoutput/SocialPlatformProfileModel";
import ISocialResultRepository from "../../domain/repositories/searchoutput/ISocialResultRepository";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";

export default class SocialResultApi implements ISocialResultRepository {
    httpClient: HttpClient;
    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }
    softDelete(id: number): Promise<Result> {
        const requestOptions = {
            method: 'DELETE'
        };
        return this.httpClient.httpFetch(`/api/socialresult/${id}`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }
    restore(id: number): Promise<Result> {
        const requestOptions = {
            method: 'POST',
        };
        return this.httpClient.httpFetch(`/api/socialresult/restore/${id}`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }
    fetchDeleted(searchId: number): Promise<Result<SocialPlatformProfileModel[] | null>> {
        const requestOptions = {
            method: "GET",
        };
        return this.httpClient
            .httpFetch(`/api/socialresult?searchId=${searchId}&isDeleted=true`, requestOptions)
            .then(async (res) => {
                if (res.status === 200) {
                    const data = await res.json();
                    return Result.OkWithValue<SocialPlatformProfileModel[]>(data); // Expecting an array here
                }
                return Result.FailWithValue<SocialPlatformProfileModel[]>( // Note the type change to array
                    "Server Error",
                    [], 
                    res.status
                );
            })
            .catch((e) => {
                return Result.FailWithValue<SocialPlatformProfileModel[]>(
                    e.message,
                    [],
                    500
                );
            });
    }

    AddUserSocialProfile(model: UserSocialProfileModel): Promise<Result<SocialPlatformProfileModel>> {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model),
        };

        return this.httpClient.httpFetch(`/api/socialresult`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<SocialPlatformProfileModel>(await res.json());
            return Result.Fail<SocialPlatformProfileModel>('Server Error', res.status);
        }).catch(e => {
            return Result.Fail<SocialPlatformProfileModel>(e.message, 500);
        });
    }
}