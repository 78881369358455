import { Row, Col, Modal } from 'react-bootstrap';
import React, { useContext, useState } from "react";
import SocialPlatformProfileModel from '../../../../domain/entities/searchoutput/SocialPlatformProfileModel';
import SocialResultModel from '../../../../domain/entities/searchoutput/SocialResultModel';
import HomeViewModelContext from '../../../../infrastructure/Contexts/HomeViewModelContext';


const SocialResultsModal: React.FC<{
    isDeleteAction: boolean;
    result: SocialResultModel
}> = ({ isDeleteAction, result }) => {
    const vm = useContext(HomeViewModelContext);

    const [isShowModal, setShowModal] = useState(false);
    const onConfirm = () => 
        {
            if(isDeleteAction)
                vm?.removeSocialResult(result.id);
            else
                vm?.restoreSocialResult(result.id);
        }

    return (
        <>
            {isDeleteAction ? (
                <i title="Remove" className="bi bi-trash" onClick={() => setShowModal(true)}></i>
            ) : (
                <i title="Restore" className="bi bi-arrow-counterclockwise" onClick={() => setShowModal(true)}></i>
            )}


            <Modal show={isShowModal}>
                <Modal.Body className="modal-card remove-email-modal-card">
                    <div className="approve-account-modal">
                        <Row className="email-modal-header">
                            <Col className="p-0"><span className="cancel-modal-title">Warning</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setShowModal(false)}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="cancel-modal-body p-0">
                                {isDeleteAction
                                    ? "Clicking 'Remove' will temporarily delete all data related to this Social Result. Do you wish to proceed?"
                                    : "Clicking 'Restore' will restore this Social Result. Do you wish to proceed?"}
                            </p>
                        </Row>
                        <Row className="modal-btn-row">
                            <Col md="auto" className="p-0"><a onClick={() => setShowModal(false)} className="btn previous-btn">No</a></Col>
                            <Col className="p-0"><a onClick={onConfirm} className="btn next-btn">Yes</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SocialResultsModal;
