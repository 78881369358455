import { TableChangeState } from "react-bootstrap-table-next";
import UserViewPermissionModel from "../../../domain/entities/admin/models/UserViewPermissionModel";
import UserViewPermissionsPaginationQueryModel from "../../../domain/entities/admin/models/UserViewPermissionsPaginationQueryModel";
import { toast } from "react-toastify";
import UserInfoModel from "../../../domain/entities/admin/models/UserInfoModel";
import PageResult from "../../../domain/common/PageResult";
import { useEffect, useState } from "react";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import UserAccountApi from "../../../data/admin/UserAccountApi";
import PendingUserRequestApi from "../../../data/admin/PendingUserRequestApi";

export const Approved = 'Approved';
export const Denied = 'Denied';

function useUserViewPermissionsViewModel() {

    const [userId, setUserId] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [viewPermissions, setViewPermissions] = useState<UserViewPermissionModel[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowError, setIsShowError] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const pendingAccountRequestRepo = new PendingUserRequestApi(new HttpClient());
    const userAccountRepo = new UserAccountApi(new HttpClient());

    const onToggle = async (requestId: number) => {
        setIsLoading(true);
        
        const result = await pendingAccountRequestRepo.ToggleSearchRequestViewRequest(requestId);

        if (result.isSuccess) {
            const vp = viewPermissions.find(v => v.requestId == requestId);
            if (vp) {
                vp?.requestStatus == Approved ? (vp.requestStatus = Denied) : (vp.requestStatus = Approved);
                setViewPermissions(viewPermissions);
            }
            notify();
        }

        setIsShowError(!result.isSuccess);
        setIsLoading(false);
    }

    const notify = () => {
        toast.success(`Updated Successfully`, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            className: "avoid-nav-bar",
            bodyClassName: "toast-message",
        });
    };

    const getData = async (curretUserId: string) => {
        try {
            setUserId(curretUserId);
            setIsLoading(true);

            const model = {} as UserViewPermissionsPaginationQueryModel;
            model.PageNum = pageIndex;
            model.PageSize = pageSize;
            model.UserId = curretUserId;

            const result = await pendingAccountRequestRepo.getUserViewPermissions(model);
            const userInfoResult = await userAccountRepo.GetUserInfo(curretUserId ?? "");

            if (userInfoResult.isSuccess) {
                const userModel = userInfoResult.value as UserInfoModel;
                setUserName(`${userModel.firstName} ${userModel.lastName}`);
            }

            if (result.isSuccess) {
                const res = result.value as PageResult<UserViewPermissionModel>;
                setViewPermissions(res?.pageData as [] ?? []);
                setTotalCount(res?.totalCount ?? 0);
            }

            setIsLoading(false);
            setIsShowError(!result.isSuccess || !userInfoResult.isSuccess);
            setErrorMessages([result.error, userInfoResult.error, result.error]);
        } catch (e: any) {
            setIsLoading(false);
            setErrorMessages([...errorMessages, e.message]);
            setIsShowError(true);
        }
    };

    const onTableChange = (type: any, newState: TableChangeState<any>) => updateCurrentPageData(newState.page, newState.sizePerPage);

    const onPageSizeChange = (pageSize: number, page: number) => updateCurrentPageData(page, pageSize);

    const updateCurrentPageData = (pageNumber: number, pageSize: number) => {
        setPageIndex(pageNumber - 1);
        setPageSize(pageSize);
    }

    return {
        userId,
        userName,
        viewPermissions,
        pageIndex,
        pageSize,
        totalCount,
        isLoading,
        isShowError,
        errorMessages,

        onToggle,
        getData,
        onTableChange,
        onPageSizeChange,
        setIsShowError
    }
}

export default useUserViewPermissionsViewModel;
