import TermsAndConditionsAndPrivacyPolicyVersionModel from "../../../domain/entities/appSettings/models/TermsAndConditionsAndPrivacyPolicyVersionModel";
import { toast } from "react-toastify";
import { useState } from "react";
import AppSettingsApi from "../../../data/appSettings/AppSettingsApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";

function useAppSettingsViewModel() {

    const [lastVersionDate, setLastVersionDate] = useState<any>(null);
    const [isShowNewVersionModal, setIsShowNewVersionModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowError, setIsShowError] = useState<boolean>(false);

    const appSettingsRepo = new AppSettingsApi(new HttpClient());

    const getData = async () => {
        try {
            setIsLoading(true);

            const result = await appSettingsRepo.getLastTermsAndConditionsAndPrivacyPolicyVersion();
            if (result.isSuccess)
                setLastVersionDate(((result.value || {}) as TermsAndConditionsAndPrivacyPolicyVersionModel).creationDate);

            setIsShowError(!result.isSuccess);
            setIsLoading(false);
        } catch (e: any) {
            setIsShowError(false);
            setIsLoading(false);
        }
    };

    const hideNewVersionModal = () => setIsShowNewVersionModal(false);
    const showNewVersionModal = () => setIsShowNewVersionModal(true);

    const addNewVersion = async () => {
        setIsLoading(true);
        setIsShowNewVersionModal(false);
        const result = await appSettingsRepo.addNewTermsAndConditionsAndPrivacyPolicyVersion();
        if (result.isSuccess)
            setLastVersionDate(new Date());

        setIsLoading(false);
        setIsShowError(!result.isSuccess);
        notify();
    }

    const notify = () => {
        toast.success(`Added Successfully`, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            className: "avoid-nav-bar",
            bodyClassName: "toast-message",
        });
    };

    return {
        lastVersionDate,
        isShowNewVersionModal,
        isLoading,
        isShowError,

        getData,
        hideNewVersionModal,
        showNewVersionModal,
        addNewVersion,
        setIsShowError
    }
}

export default useAppSettingsViewModel;
