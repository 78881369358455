import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import ResultCount from "../ResultCount";
import MasonryView from "../../MasonryView";
import PDFEmailBreaches from "./PDFEmailBreaches";
import { RootState } from "../../../../infrastructure/redux/reducer";
import { useSelector } from "react-redux";
import SearchResultModel from "../../../../domain/entities/searchoutput/SearchResultModel";


const PDFEmails: React.FC = () => {
    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
   
    return (
        <Fragment>
            <Row><ResultCount count={searchResult?.emails?.length ?? 0} text={"Emails"} /></Row>
            <div className="emails container-fluid p-0">
                {searchResult?.emails && < MasonryView columnCount={2}>
                    {searchResult?.emails?.map(emailData => {

                        const nameExist = emailData.gHuntOutput?.name.firstName || emailData.gHuntOutput?.name.lastName;
                        const name = nameExist ? `${emailData.gHuntOutput?.name.firstName ?? ''} ${emailData.gHuntOutput?.name.lastName ?? ''}` : 'Not Available';

                        return (
                            <div key={Math.random()}>
                                <Col className="email-card box-shadow bg-white">
                                    <Row className="m-0 p-0 align-items-center">
                                        <Col className="p-0">
                                            <img className="image p-0" src={emailData.gHuntOutput?.profilePictureUrl ?? "/images/person.png"} onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = "/images/email/person.png";
                                            }} />
                                            <span className="header-text">{emailData.email}</span></Col>
                                        <Col xs={"auto"} className="header-text gaia p-0"><span className="title id-title">GAIA ID:&nbsp;</span>
                                            {emailData.gHuntOutput?.gaiaId ? <span className="gaia-val">{emailData.gHuntOutput?.gaiaId}</span> : <span className="not-available">Not Available</span>}
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className="data-row container-fluid p-0 mb-0" >
                                        <Col className="col-auto img-box-clear px-0 mx-2"><img width='30px' height='30px' src="/images/search-results/new-person.png" /></Col>
                                        <Col>
                                            <span className="title">Name:&nbsp;</span><span className={nameExist ? 'val' : 'not-available'}>{name}</span>
                                        </Col>
                                        <Col className="p-0"><img width='30px' height='18px' src="/images/search-results/google-maps-old.png" /><span className="title map-title m-0">Map:&nbsp;</span>
                                            {emailData.gHuntOutput?.gMapReviewsUrl && (<a target="_blank" rel="noreferrer" className="view" href={"https://" + emailData.gHuntOutput.gMapReviewsUrl}>View</a>)}
                                            {!emailData.gHuntOutput?.gMapReviewsUrl && <span className="not-available">Not Available</span>}
                                        </Col>
                                        <Col md="auto" className="p-0"><img width='30px' height='18px' src="/images/search-results/google.png" /><span className="title">Google Search:&nbsp;</span><a className="view" target="_blank" rel="noreferrer" href={'https://www.google.com/search?q="' + emailData.email?.split('@')[0] + '"'}>View</a></Col>
                                    </Row>
                                    <Row className="data-row d-table">
                                        <Col md="auto" className="icon d-table-cell"><img width='30px' height='25px' src="/images/search-results/connected.png" /></Col>
                                        <Col md="auto" className="title p-0 d-table-cell">Connected Sites:&nbsp;</Col>
                                        <Col md="auto" className={emailData.connectedSites ? "val p-0 connected-sites d-table-cell" : "not-available d-table-cell p-0"}>{emailData.connectedSites ? emailData.connectedSites : "Not Available"}</Col>
                                    </Row>
                                    <Row className="data-row m-0" >
                                        <PDFEmailBreaches breaches={emailData.emailBreaches} />
                                    </Row>
                                </Col>
                            </ div >
                        )
                    }
                    )}
                </MasonryView>}
            </div>
        </Fragment>
    );
};

export default PDFEmails;
