import React from 'react';
import { Row } from "react-bootstrap";

const SuccessfulRegistrationRequest = () => {

    return <>
        <div className="registration successful-register main-page">
            <div className="section-wrapper">
                <Row><img src="/images/successful.svg" width="56px" height="56px" /></Row>
                <Row><span className="congrats">Congratulations</span></Row>
                <Row><span className="info">You have been registered successfully</span></Row>
                <Row><span className="detailed-info">Your registration request will be reviewed within 48 hours</span></Row>
                <Row className="d-block"><a href="/" className="btn next-btn">Go to app &nbsp;&nbsp;&nbsp; <span>&rarr;</span></a></Row>
            </div >
        </div>
    </>;
}

export default SuccessfulRegistrationRequest;