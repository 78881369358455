import React from "react";
import { Col, Row } from "react-bootstrap";
import CustomInput from "../CustomInput";
import { Control } from "react-hook-form";
import { emailRegex, phoneRegex } from "../../../infrastructure/lookups/RegEx";
import PersonInfoEditState from "../../models/PersonInfoEditState";

type Props = {
    control: Control<PersonInfoEditState, any>
}

const AlternativeContact: React.FC<Props> = ({ control }: Props) => {

    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Alternative Contact</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="alternativeFirstName"
                    placeholder="First Name"
                    control={control}
                />
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="alternativeLastName"
                    placeholder="Last Name"
                    control={control}
                />
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="alternativePhoneNumber"
                    placeholder="Phone"
                    control={control}
                    pattern={{ value: phoneRegex, message: "Phone is not valid" }}
                />
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="alternativeEmail"
                    placeholder="Email"
                    control={control}
                    pattern={{ value: emailRegex, message: "Email is not valid" }}
                />
            </Col>
        </Row>
    </>
};

export default AlternativeContact;
