import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import CaseListViewModel from "../view-model/cases/CaseListViewModel";
import BootstrapTable, {
	ColumnDescription,
	TableChangeState,
} from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Helmet } from "react-helmet";
import ErrorAlert from "../components/ErrorAlert";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import useViewModel from "../../infrastructure/hooks/useViewModel";
import CaseApi from "../../data/cases/CaseApi";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";
import { Modal } from "react-bootstrap";
import CreateCase from "./CreateCase";
import { useLocation } from "react-router";
import { setError } from "../../infrastructure/redux/actions/error";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import SearchFilterModel from "../../domain/entities/search/SearchFilterModel ";
import ListCaseModel from "../../domain/entities/cases/ListCaseModel";

type CaseListState = {
	pageSize: number;
	pageNumber: number;
	totalCount: number;
	pageData: ListCaseModel[];
	showAddModal: boolean;
	selectedItemId: string | null;

	isLoading: boolean;
	isShowError: boolean;
	errorMessages: Array<string>;

	sortDirection?: number;
	sort: number | null;
	filter: SearchFilterModel;
	searchText: string;
};

const CaseList: React.FC = () => {
	const dispatch = useDispatch();
	const { viewModel, subscription } = useViewModel(CaseListViewModel, [
		new CaseApi(new HttpClient()),
	]);

	const [state, setState] = useState<CaseListState>({
		pageSize: viewModel.pageSize,
		pageNumber: viewModel.pageNumber,
		totalCount: viewModel.totalCount,
		pageData: viewModel.pageData,
		showAddModal: viewModel.showAddModal,
		selectedItemId: viewModel.selectedItemId,
		isLoading: viewModel.isLoading,
		isShowError: viewModel.isShowError,
		errorMessages: viewModel.errorMessages,
		sortDirection: viewModel.sortDirection,
		sort: viewModel.sort,
		filter: viewModel.filter,
		searchText: viewModel.searchText,
	});

	const search = useLocation().search;

	const getPageWrap = async (pageNumber: number) => {
		const result = await viewModel.getPage(--pageNumber);
		if (result.statusCode != 200) {
			dispatch(
				setError({
					errorCode: result.statusCode,
				})
			);
		}
	};

	useEffect(() => {
		const subscriber = subscription.subscribe((d: any) =>
			setState({
				...state,
				...d.data,
			})
		);

		const urlPageNumber = new URLSearchParams(search).get("pg") || "";

		getPageWrap(parseInt(urlPageNumber.toString()) || 1);

		return () => {
			subscriber.unsubscribe();
		};
	}, []);

	const columns: ColumnDescription[] = [
		{
			dataField: "name",
			text: "Name",
			formatter: (cell, row) => (
				<a className="username-link" href={"/search?caseid=" + row.caseId}>
					{row.name}
				</a>
			),
		},
		{
			dataField: "searchRequestNumber",
			text: "Associated Searches",
		},
		{
			dataField: "",
			text: "Creation Date",
			formatter: (cell, row) => new Date(row.creationDate).toLocaleDateString(),
		},
		{
			dataField: "",
			text: "Actions",
			formatter: (cell, row) => (
				<div className="actions-td">
					<a
						href="#"
						key={"edit-" + row.id}
						onClick={() => viewModel.openAddModal(row.caseId)}
						data-tip
						data-for="edit"
					>
						<img className="search-input-icon" src="/images/edit.svg" alt="" />
						<ReactTooltip id="edit" place="top" effect="solid">
							Edit
						</ReactTooltip>
					</a>
					<a href={"/search?caseid=" + row.caseId} data-tip data-for="details">
						<i className="bi bi-eye"></i>
						<ReactTooltip id="details" place="top" effect="solid">
							Details
						</ReactTooltip>
					</a>
				</div>
			),
		},
	];

	const onTableChange = (type: any, newState: TableChangeState<any>) => {
		viewModel.getPage(--newState.page);
		let oldUrl =
			window.location.protocol +
			"//" +
			window.location.host +
			window.location.pathname;
		oldUrl += "?pg=" + ++newState.page;
		window.history.pushState({ path: oldUrl }, "", oldUrl);
	};

	const onPageSizeChange = (pageSize: number, page: number) => {
		viewModel.pageSize = pageSize;
		getPageWrap(page);
	};

	const customTotal = (from: any, to: any, size: any) => (
		<span className="react-bootstrap-table-pagination-total">
			Showing {from} to {to} of {size} Results
		</span>
	);

	return (
		<div className="container">
			<Helmet>
				<title>Social E-Profiler - Case List </title>
				<meta name="description" content="Social E-Profiler" />
			</Helmet>
			{state.isShowError && (
				<div className="row mt-5">
					<ErrorAlert
						headerMessage="ListingErrorHeaderMessage"
						bodyMessage="ListingErrorBodyMessage"
					/>
				</div>
			)}
			{!state.isShowError && (
				<div className="row mt-5">
					<div className="row title-div mb-5">
						<h2 className="table-page-title title ms-1">Cases</h2>
						<button
							className="add-btn"
							onClick={() => viewModel.openAddModal(null)}
						>
							<span className="add-btn-content">
								<p className="add-btn-text">New Case</p>
								<img src="/images/add.svg" className="add-btn-icon" alt="" />
							</span>
						</button>
					</div>

					<div className="div-filter mb-5">
						<div className="search-input-div">
							<input
								autoComplete="new-password"
								placeholder="Search for Case Name"
								className="input-field search-input"
								type="text"
								onChange={viewModel.onSearchTextQueryChanged}
								value={state.searchText}
							/>
							<img
								className="search-input-icon"
								src="/images/search.svg"
								alt=""
							/>
						</div>
						<div className="search-date-div">
							<label className="date-label">Date </label>
							<DateRangePicker
								onChange={viewModel.onDatesQueryChanged}
								value={[
									state.filter.CreationDateFrom ?? "",
									state.filter.CreationDateTo ?? "",
								]}
							/>
						</div>
						<div className="search-sort-div">
							<select
								name="sort"
								id="sort"
								placeholder="sort"
								className="search-select"
								onChange={viewModel.onSortQueryChanged}
								value={state.sortDirection}
							>
								<option value={undefined}>Sort</option>
								<option value={0}>Ascending</option>
								<option value={1}>Descending </option>
							</select>
						</div>
					</div>
					<div className="table-style mt-4">
						<BootstrapTable
							keyField="caseId"
							data={state.pageData}
							columns={columns}
							pagination={paginationFactory({
								pageStartIndex: 1,
								page: state.pageNumber + 1,
								sizePerPage: state.pageSize,
								totalSize: state.totalCount,
								firstPageText: "First",
								prePageText: "Back",
								nextPageText: "Next",
								lastPageText: "Last",
								nextPageTitle: "First page",
								prePageTitle: "Pre page",
								firstPageTitle: "Next page",
								lastPageTitle: "Last page",
								paginationTotalRenderer: customTotal,
								onSizePerPageChange: onPageSizeChange,
								sizePerPageList: [
									{ text: "10", value: 10 },
									{ text: "25", value: 25 },
									{ text: "50", value: 50 },
									{ text: "100", value: 100 },
								],
							})}
							noDataIndication={() => (
								<TailSpin
									wrapperClass="table-spinner"
									visible={state.isLoading}
									height={50}
									width="50"
									color="#FF5733"
									ariaLabel="loading"
								/>
							)}
							onTableChange={onTableChange}
							filter={filterFactory()}
							bordered={false}
							headerClasses="header-tr"
							remote
						/>
					</div>

					<Modal show={state.showAddModal} onHide={viewModel.closeAddModal}>
						<Modal.Body className="modal-card">
							<CreateCase
								id={state.selectedItemId}
								closeModal={viewModel.closeAddModal}
							/>
						</Modal.Body>
					</Modal>
				</div>
			)}
		</div>
	);
};

export default CaseList;
