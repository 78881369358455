import Result from "../../../domain/common/Result";
import SearchListPaginationQuery from "../../../domain/entities/search/SearchListPaginationQuery";
import SearchListFiltrationModel from "../../../domain/entities/search/SearchListFiltrationModel";
import SearchDetailsModel from "../../../domain/entities/search/SearchDetailsModel";
import { useEffect, useState } from "react";
import UserAccountApi from "../../../data/admin/UserAccountApi";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import SearchApi from "../../../data/search/SearchApi";
import SearchListItemModel from "../../../domain/entities/search/SearchListItemModel";
import PaginationModel from "../../../domain/entities/generic/PaginationModel";

function useUserSearchListViewModel() {

    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageData, setPageData] = useState<SearchListItemModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowError, setIsShowError] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const [searchText, setSearchText] = useState<string>("");
    const [sort, setSort] = useState<string>();
    const [sortDirection, setSortDirection] = useState<string>();
    const [name, setName] = useState<string>("");
    const [searchDetails, setSearchDetails] = useState<SearchDetailsModel | null>(null);
    const [userId, setUserId] = useState<string | undefined>();
    const [caseDetailsName, setCaseDetailsName] = useState<string | null>();
    const [searchQuery, setSearchQuery] = useState<SearchListFiltrationModel>(new SearchListFiltrationModel());

    const userAccountRepo = new UserAccountApi(new HttpClient());
    const searchRepo = new SearchApi(new HttpClient());

    useEffect(() => { if (userId) getPage(0) }, [searchQuery]);
    useEffect(() => { if (userId) getPage() }, [sort, sortDirection]);

    const openAddModal = (id: string | null) => {
        setShowAddModal(true);
        setSelectedItemId(id === null ? 0 : +id);
        getSearchDetails(id === null ? 0 : +id);
    };

    const closeAddModal = () => setShowAddModal(false);

    const init = async (currentUserId: string): Promise<any> => {
        try {
            const userInfoResult = await userAccountRepo.GetUserFullName(currentUserId?.toString() ?? "");
            if (userInfoResult.isSuccess) {
                setName(userInfoResult.value ?? "");
                return userInfoResult
            }
            else {
                setIsLoading(false);
                setErrorMessages(["error in user data fetching"]);
                setIsShowError(true);
                return userInfoResult
            }
        } catch (e: any) {
            setIsLoading(false);
            setErrorMessages([...errorMessages, e.message]);
            setIsShowError(true);
        }
    }

    const onSearchChanged = (search: SearchListFiltrationModel) => setSearchQuery(search);

    const updateSorting = (sortField: string, sortDirection: string) => {
        setSort(sortField);
        setSortDirection(sortDirection);
    }

    const getPage = async (_pageNumber = pageNumber, _pageSize = pageSize, currentUserId = userId): Promise<Result<PaginationModel<SearchListItemModel>>> => {
        try {
            const model = {} as SearchListPaginationQuery;
            model.PageNum = _pageNumber;
            model.PageSize = _pageSize;
            model.SearchText = searchText;
            model.UserId = currentUserId;
            model.SearchText = searchQuery.SearchText;
            model.Status = searchQuery.Status;
            model.CreationDateFrom = searchQuery.CreationDateFrom;
            model.CreationDateTo = searchQuery.CreationDateTo
            model.SortField = sort;
            model.SortDirection = sortDirection;

            setIsLoading(true);

            const result = await searchRepo.GetUserSearchRequests(model);

            if (result.isSuccess) {
                setPageData(result?.value?.pageData || []);
                setTotalCount(result?.value?.totalCount || 0);
            }

            setIsLoading(false);
            setIsShowError(!result.isSuccess);
            setErrorMessages([...errorMessages, result.error]);
            return result;
        } catch (e: any) {
            setIsLoading(false);
            setErrorMessages([...errorMessages, e.message]);
            setIsShowError(true);
            return Result.Fail(e.message, 500);
        }
    };

    const getSearchDetails = async (id: number) => {
        try {
            setIsLoading(true);

            const result = await searchRepo.getSearchDetail(id);
            setIsShowError(!result.isSuccess);
            setIsLoading(false);
            setSearchDetails(result.value as SearchDetailsModel);
        }
        catch (e: any) {
            setIsLoading(false);
            setErrorMessages([...errorMessages, e.message]);
            setIsShowError(true);
        }
    }

    return {
        pageSize,
        pageNumber,
        totalCount,
        pageData,
        isLoading,
        isShowError,
        errorMessages,
        showAddModal,
        name,
        searchDetails,

        openAddModal,
        closeAddModal,
        init,
        onSearchChanged,
        getPage,
        updateSorting,
        setPageSize,
        getSearchDetails,
        setUserId,
        setIsShowError,
        setPageNumber
    }
}

export default useUserSearchListViewModel;
