import React, { useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import useVisibility from "../../../infrastructure/hooks/useVisibility";
import AddressSvgIcon from "../svg-icons/AddressSvgIcon";
import HideTabToggle from "./HideTabToggle";
import ResultCount from "./ResultCount";
import SearchResultModel from "../../../domain/entities/searchoutput/SearchResultModel";
import { RootState } from "../../../infrastructure/redux/reducer";


const Addresses: React.FC = () => {
    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    const dispatch = useDispatch();
    const { visibilityFlags, actions } = useVisibility();

    const handleToggle = () => {
        dispatch(actions.setAddressVisibility(!visibilityFlags.isAddressHidden));
    };

    useEffect(() => {
        if (searchResult?.dataFetched && searchResult?.addresses?.length === 0)
            dispatch(actions.setAddressVisibility(true));
    }, [searchResult?.addresses]);

    return (
        <>
            <Row className="my-3 mx-2 result-header">
                <Col >
                    <ResultCount count={searchResult?.addresses?.length ?? 0} text={searchResult?.isPrintView ? "Addresses" : null} />
                </Col>
                <Col md="auto">
                    {!searchResult?.isPrintView && <HideTabToggle isHidden={searchResult?.addresses?.length == 0 ? true : visibilityFlags.isAddressHidden} onToggle={handleToggle} />}
                </Col>
            </Row>
            <hr />
            <Row className="addresses">
                <Col className="address-list">
                    {searchResult?.addresses?.map(a =>
                        <Row className="address-card box-shadow" key={Math.random()}>
                            <Col className="col-auto img-box">
                                <AddressSvgIcon />
                            </Col>
                            <Col className="p-0 text">
                                {a.addressLine}
                            </Col>
                        </Row>
                    )}
                </Col>
                <Col xs={8}>
                    <img className="col-12 my-1" src={`https://maps.googleapis.com/maps/api/staticmap?
                zoom=15&size=600x300&maptype=roadmap&${searchResult?.addresses
                            ?.map((a) => "markers=color:orange|size:small|" + encodeURI(a.addressLine))
                            .join("&").replaceAll("#", "")}&key=AIzaSyDQRfEFtUUxMHTbo2oOj6GIN6mh1_syEK8`}
                    />
                </Col>
            </Row>
        </>
    );
};

export default Addresses;

