import React, { Children } from "react";
import { Col, Row } from 'react-bootstrap';

type Props = {
    count: number;
    text: string | null;
    children?: any | undefined;
}

const ResultCount: React.FC<Props> = (props: Props) => {
    return (
        <Row className="result-count mx-1">
            <Row>
                <Col md="auto" className="p-0 align-self-center">{props.text ?? "Results Found"}&nbsp;</Col>
                <Col md="auto" className="p-0 count align-self-center">({props.count})</Col>
            </Row>
        </Row>
    );
};

export default ResultCount;
