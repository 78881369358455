import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PDFRequestDetail from "./PDFRequestDetail";
import { RootState } from "../../../../infrastructure/redux/reducer";
import { useSelector } from "react-redux";
import SearchResultModel from "../../../../domain/entities/searchoutput/SearchResultModel";


const PDFRequestDetails: React.FC = () => {

    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    
    return (
        <Row className="request-details-info">
            <Row className="title">
                <Col md={"auto"} className="request-details p-0">Request Details</Col>
            </Row>
            <Row className="details p-0">
                <PDFRequestDetail title="Address" value={searchResult?.headerModel?.address}><img width='30px' height='18px' src="/images/search-results/home.png" /></PDFRequestDetail>
                <PDFRequestDetail title="Phone" value={searchResult?.headerModel?.phone}><img width='30px' height='18px' src="/images/search-results/phone.png" /></PDFRequestDetail>
                <PDFRequestDetail title="Email" value={searchResult?.headerModel?.email}><img width='30px' height='18px' src="/images/search-results/email.png" /></PDFRequestDetail>
            </Row>
        </Row>
    );
};

export default PDFRequestDetails;