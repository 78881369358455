import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Nav, Row } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import useVisibility from "../../infrastructure/hooks/useVisibility";
import { setError } from "../../infrastructure/redux/actions/error";
import PDFSearchResults from "../components/search-result-pdf/search-result-pdf_new";
import Addresses from "../components/search-result/Addresses_new";
import Education from "../components/search-result/Education_new";
import InternetSearches from "../components/search-result/InternetSearches_new";
import Phones from "../components/search-result/Phones_new";
import Relatives from "../components/search-result/Relatives_new";
import SocialResults from "../components/search-result/SocialResults/SocialResults_new";
import Work from "../components/search-result/Work_new";
import Emails from "../components/search-result/emails/Emails_new";
import Header from "../components/search-result/header/Header_new";
import AddressSvgIcon from "../components/svg-icons/AddressSvgIcon";
import EducationSvgIcon from "../components/svg-icons/EducationSvgIcon";
import EmailSvgIcon from "../components/svg-icons/EmailSvgIcon";
import InternetSearchSvgIcon from "../components/svg-icons/InternetSearchSvgIcon";
import PhoneSvgIcon from "../components/svg-icons/PhoneSvgIcon";
import RelativesSvgIcon from "../components/svg-icons/RelativesSvgIcon";
import SocialMediaSvgIcon from "../components/svg-icons/SocialMediaSvgIcon";
import WorkSvgIcon from "../components/svg-icons/WorkSvgIcon";
import useHomeViewModel from "../view-model/searchoutput/HomeViewModel_new";
import { RootState } from "../../infrastructure/redux/reducer";
import { setSearchResult } from "../../infrastructure/redux/actions/searchResult";
import SearchResultModel from "../../domain/entities/searchoutput/SearchResultModel";

const SearchResult: React.FC = () => {

    const {
        scrollHandler,
        scrollToTop,
        loadSearchResult
    } = useHomeViewModel();

    const { id } = useParams();
    const dispatch = useDispatch();
    const searchResultStoreValue: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    const [searchResult, setSearchResultState] = useState(searchResultStoreValue);
    useEffect(() => { setSearchResultState(searchResultStoreValue) }, [searchResultStoreValue]);
    const socialMediaCount = searchResult?.socialResults?.reduce((partialSum, p) => p.resultsWithData.length + p.resultsWithNoData.length + partialSum, 0) ?? 0
    const { visibilityFlags } = useVisibility();

    useEffect(() => {
        dispatch(setSearchResult({ activeKey: "addressHistory" }));
        window.addEventListener('scroll', scrollHandler);
        loadSearchResult(Number.parseInt(id ?? '0'));
    }, []);

    return (
        <>
            {searchResult.isExpiredSearchResult ? <div className="title text-center">This search result is expired</div> :
                <>
                    {searchResult.displayScrollView && window.innerWidth >= 768 && <div className={"scroll-view-background box-shadow " + (searchResult.isPrintView ? "printable-view" : '')}></div>}
                    {!searchResult.isLoading && !searchResult.isShowError && (
                        <>
                            <Container className="search-result">
                                <Row className={"header-component " + (searchResult.displayScrollView && window.innerWidth >= 768 ? "scroll-view" : '')}>
                                    <Header />
                                </Row>
                                <Row>
                                    {!searchResult.isPrintView && (
                                        <Tab.Container id="tabs" activeKey={searchResult.activeKey} onSelect={r => dispatch(setSearchResult({ activeKey: r as string }))}>
                                            <Nav variant="pills">
                                                <Row className="container-fluid bg-white p-0 box-shadow nav-row">
                                                    <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="addressHistory"><AddressSvgIcon />Address History <span className="nav-item-span">({searchResult?.addresses?.length ?? 0})</span></Nav.Link></Nav.Item></Col>
                                                    <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="work"><WorkSvgIcon />Work <span className="nav-item-span">({searchResult?.experiences?.length ?? 0})</span></Nav.Link></Nav.Item></Col>
                                                    <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="education"><EducationSvgIcon />Education <span className="nav-item-span">({searchResult?.educations?.length ?? 0})</span></Nav.Link></Nav.Item></Col>
                                                    <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="relatives"><RelativesSvgIcon />Relatives <span className="nav-item-span">({searchResult?.relatives?.length ?? 0})</span></Nav.Link></Nav.Item></Col>
                                                    <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="phones"><PhoneSvgIcon />Phones <span className="nav-item-span">({searchResult?.phones?.length ?? 0})</span></Nav.Link></Nav.Item></Col>
                                                    <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="emails"><EmailSvgIcon />Emails <span className="nav-item-span">({searchResult?.emails?.length ?? 0})</span></Nav.Link></Nav.Item></Col>
                                                    <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="socialMedia" ><SocialMediaSvgIcon />Social Media <span className="nav-item-span">({socialMediaCount})</span></Nav.Link></Nav.Item></Col>
                                                    <Col md="auto"><Nav.Item><Nav.Link onClick={scrollToTop} eventKey="internetSearches"><InternetSearchSvgIcon />Internet Searches <span className="nav-item-span">({searchResult?.internetSearches?.length ?? 0})</span></Nav.Link></Nav.Item></Col>
                                                </Row>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="addressHistory"><Addresses /></Tab.Pane>
                                                <Tab.Pane eventKey="work"><Work /></Tab.Pane>
                                                <Tab.Pane eventKey="education"><Education /></Tab.Pane>
                                                <Tab.Pane eventKey="relatives"><Relatives /></Tab.Pane>
                                                <Tab.Pane eventKey="phones"><Phones /></Tab.Pane>
                                                <Tab.Pane eventKey="emails"><Emails /></Tab.Pane>
                                                <Tab.Pane eventKey="socialMedia"><SocialResults /></Tab.Pane>
                                                <Tab.Pane eventKey="internetSearches"><InternetSearches /></Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>)}

                                    {searchResult.isPrintView && (
                                        <>
                                            {!visibilityFlags.isAddressHidden && <Row className="p-0 printable-view-section"><Addresses /></Row>}
                                            {!visibilityFlags.isWorkHidden && < Row className="p-0 printable-view-section"><Work /></Row>}
                                            {!visibilityFlags.isEducationHidden && < Row className="p-0 printable-view-section"><Education /></Row>}
                                            {!visibilityFlags.isRelativesHidden && <Row className="p-0 printable-view-section"><Relatives /></Row>}
                                            {!visibilityFlags.isPhonesHidden && <Row className="p-0 printable-view-section"><Phones /></Row>}
                                            {!visibilityFlags.isEmailsHidden && <Row className="p-0 printable-view-section"><Emails /> </Row>}
                                            {!visibilityFlags.isSocialResultsHidden && <Row className="p-0 printable-view-section"><SocialResults /></Row>}
                                            {!visibilityFlags.isInternetSearchesHidden && <Row className="printable-view-section"><InternetSearches /></Row>}
                                        </>
                                    )}
                                </Row>
                                {searchResult.displayScrollView && <button className="slide-up-btn" onClick={scrollToTop} ><img src="/images/slide-up-orange.png" /></button>}
                            </Container>
                            {searchResult.isShowPDF && (<div className="pdf-container">
                                <PDFSearchResults />
                            </div>)}
                        </>)
                    }

                    {searchResult.isLoading && (
                        <TailSpin
                            wrapperClass="cover-spin"
                            visible={searchResult.isLoading}
                            height={50}
                            width="50"
                            color="#FF5733"
                            ariaLabel="loading"
                        />
                    )}

                    {searchResult.isDownloading && (
                        <TailSpin
                            wrapperClass="cover-spin"
                            visible={searchResult.isDownloading}
                            height={50}
                            width="50"
                            color="#FF5733"
                            ariaLabel="loading"
                        />
                    )}

                    <Modal show={searchResult.isShowError}>
                        <Modal.Body className="modal-card warning-modal-card error-modal">
                            <div>
                                <Row className="warning-modal-header">
                                    <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                                    <Col ><span className="cancel-modal-title">Server Error</span></Col>
                                    <Col md="auto" className="close-modal-icon"><span onClick={() => dispatch(setSearchResult({ isShowError: false }))}>x</span></Col>
                                </Row>
                                <Row>
                                    <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                                </Row>
                                <Row className="warning-modal-btn-row">
                                    <Col className="p-0"><a onClick={() => dispatch(setSearchResult({ isShowError: false }))} className="btn warning-btn">Try Again Later</a></Col>
                                </Row>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            }
        </>
    );
};

export default SearchResult;
