import React from 'react';
import { Col, Modal, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { TailSpin } from "react-loader-spinner";
import { useEffect } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import GlbaDppaChangeRequestDetails from "../../components/admin/GlbaDppaChangeRequestDetails";
import ViewPermissionRequestDetails from "../../components/admin/ViewPermissionRequestDetails";
import PendingRequestType from '../../../domain/entities/admin/enums/PendingRequestType';
import { useDispatch } from 'react-redux';
import { SetPendingRequestsCount } from '../../../infrastructure/redux/actions/SetPendingRequestsCount';
import usePendingRequestsViewModel from '../../view-model/admin/PendingRequestsViewModel_new';


const PendingRequests: React.FC = () => {

    const {
        pendingRequests,
        pageIndex,
        pageSize,
        totalCount,
        isLoading,
        isShowError,
        errorMessages,
        selectedRequest,
        isShowGlbaDppaDetailsModal,
        isShowViewPermissionDetailsModal,
        isShowApprovalModal,
        isShowDenialModal,

        getData,
        confirmDenial,
        confirmApproval,
        onEmailClick,
        showDenialModal,
        showApprovalModal,
        onPageSizeChange,
        onTableChange,
        hideGlbaDppaDetailsModal,
        hideViewPermissionDetailsModal,
        hideApprovalModal,
        hideDenialModal,
        setIsShowError
    } = usePendingRequestsViewModel();

    useEffect(() => { getData() }, [pageSize, pageIndex]);
    useEffect(() => { dispatch(SetPendingRequestsCount(totalCount)) }, [totalCount]);
    const dispatch = useDispatch();

    const columns: ColumnDescription[] = [
        {
            dataField: "requestType",
            text: "Request Type",
            formatter: (cell, row, i) => (
                <>
                    {row.requestType == PendingRequestType.ViewingOtherUsers &&
                        <span className="username-link" onClick={() => onEmailClick(row)}>Viewing other users</span>
                    }
                    {row.requestType == PendingRequestType.GlbaDppaPermissions &&
                        <span className="username-link" onClick={() => onEmailClick(row)}>GLBA / DPPA Permissions</span>
                    }
                    {row.requestType == PendingRequestType.AuthorizedManager && <span className="link-color">Authorized Manager</span>}
                </>
            ),
        },
        {
            dataField: "email",
            text: "Email",
        },
        {
            dataField: "employerName",
            text: "Employer Name",
        },
        {
            dataField: "requestDate",
            text: "Request Date",
            formatter: (cell, row) => new Date(row.requestDate).toLocaleDateString(),
        },
        {
            dataField: "id",
            text: "Actions",
            formatter: (cell, row, i) => (
                <div className="account-request-btns d-flex">
                    <button className="btn previous-btn" onClick={() => showDenialModal(row)}> Deny</button >
                    <button className="btn next-btn" onClick={() => showApprovalModal(row)}> Approve</button>
                </div>
            ),
        },
    ];


    return (
        <div className="container">
            <Helmet>
                <title>Social E-Profiler - Pending Requests</title>
                <meta name="description" content="Social E-Profiler" />
            </Helmet>
            <div className="row mt-5">
                <div className="admin-page-title">Pending Requests</div>
                <div className="table-style mt-4">
                    <BootstrapTable
                        keyField="searchId"
                        columns={columns}
                        data={pendingRequests}
                        bordered={false}
                        headerClasses="header-tr"
                        remote
                        pagination={paginationFactory({
                            pageStartIndex: 1,
                            page: pageIndex + 1,
                            sizePerPage: pageSize,
                            totalSize: totalCount,
                            firstPageText: "First",
                            prePageText: "Back",
                            nextPageText: "Next",
                            lastPageText: "Last",
                            nextPageTitle: "First page",
                            prePageTitle: "Pre page",
                            firstPageTitle: "Next page",
                            lastPageTitle: "Last page",
                            onSizePerPageChange: onPageSizeChange,
                            sizePerPageList: [
                                { text: "10", value: 10 },
                                { text: "25", value: 25 },
                                { text: "50", value: 50 },
                                { text: "100", value: 100 },
                            ]
                        })}
                        onTableChange={onTableChange}
                    />
                </div>
            </div>
            <Modal show={isShowGlbaDppaDetailsModal}>
                <Modal.Body className="modal-card user-details-modal-card">
                    <Row className="cancel-modal-header">
                        <Col ><span className="modal-title">Request Info</span></Col>
                        <Col md="auto" className="close-modal-icon"><span onClick={hideGlbaDppaDetailsModal}>x</span></Col>
                    </Row>
                    <GlbaDppaChangeRequestDetails glbaDppaChangeRequest={selectedRequest} />
                </Modal.Body>
            </Modal>
            <Modal show={isShowViewPermissionDetailsModal}>
                <Modal.Body className="modal-card user-details-modal-card">
                    <Row className="cancel-modal-header">
                        <Col ><span className="modal-title">Request Info</span></Col>
                        <Col md="auto" className="close-modal-icon"><span onClick={hideViewPermissionDetailsModal}>x</span></Col>
                    </Row>
                    <ViewPermissionRequestDetails searchRequestViewRequest={selectedRequest} />
                </Modal.Body>
            </Modal>
            <Modal show={isShowApprovalModal}>
                <Modal.Body className="modal-card approve-account-modal-card">
                    <div className="approve-account-modal">
                        <Row className="approve-modal-header">
                            <Col className="p-0"><span className="cancel-modal-title">Approve Request</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={hideApprovalModal}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="cancel-modal-body p-0">Are you sure you want to approve this request?</p>
                        </Row>
                        <Row className="modal-btn-row">
                            <Col md="auto" className="p-0"><a onClick={hideApprovalModal} className="btn previous-btn">Cancel</a></Col>
                            <Col className="p-0"><a onClick={confirmApproval} className="btn next-btn">Approve</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={isShowDenialModal}>
                <Modal.Body className="modal-card approve-account-modal-card">
                    <div className="approve-account-modal">
                        <Row className="approve-modal-header">
                            <Col className="p-0"><span className="cancel-modal-title">Deny Request</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={hideDenialModal}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="cancel-modal-body p-0">Are you sure you want to deny this request?</p>
                        </Row>
                        <Row className="deny-modal-btn-row">
                            <Col md="auto" className="p-0"><a onClick={hideDenialModal} className="btn previous-btn">Cancel</a></Col>
                            <Col className="p-0"><a onClick={confirmDenial} className="btn next-btn">Confirm Denial</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
            <TailSpin
                wrapperClass="cover-spin"
                visible={isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
            <Modal show={isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setIsShowError(false)}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setIsShowError(false)} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default PendingRequests;
