import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { Helmet } from "react-helmet";
import ErrorAlert from "../components/ErrorAlert";
import { TableChangeState } from "react-bootstrap-table-next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setError } from "../../infrastructure/redux/actions/error";
import SearchDetails from "./SearchDetails";
import { Modal, Col } from "react-bootstrap";
import SearchListTable from "../components/SearchListTable";
import { setReRunSearch } from "../../infrastructure/redux/actions/reRunSearch";
import { SearchHistoryRefreshRate } from "../../../config";
import useSearchListViewModel from "../view-model/search/SearchListViewModel_new";
import BackArrowSvgIcon from "../components/svg-icons/BackArrow";

const SearchList: React.FC = () => {
    const dispatch = useDispatch();

    const {
        pageSize,
        pageNumber,
        totalCount,
        pageData,
        isLoading,
        isShowError,
        errorMessages,
        showAddModal,
        searchDetails,
        currentCaseId,
        caseDetailsName,

        openAddModal,
        closeAddModal,
        onCaseIdQueryChanged,
        onSearchChanged,
        getSearchDetails,
        getCaseDetails,
        anyInprogressRequestExists,
        getCurrentPage,
        updateSorting,
        setPageSize,
        setPageNumber
    } = useSearchListViewModel();

    const search = useLocation().search;
    const caseId = new URLSearchParams(search).get("caseid");
    const navigate = useNavigate();

    const [loaderEnabled, setLoaderEnabled] = useState(true);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);

    useEffect(() => {
        const urlPageNumber = new URLSearchParams(search).get("pg") || "1";
        let pageNum = parseInt(urlPageNumber);
        setLoaderEnabled(true);
        getPage(--pageNum);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (anyInprogressRequestExists()) {
                setLoaderEnabled(false);
                getPage(pageNumber);
            }
            else
                clearInterval(intervalId);
        }, SearchHistoryRefreshRate * 1000);

        return () => { clearInterval(intervalId); };
    });

    useEffect(() => { dispatch(setReRunSearch(searchDetails as unknown as ReRunSearchState)) }, [searchDetails]);

    const getPage = async (pageNumber: number) => {
        if (caseId) {
            onCaseIdQueryChanged(caseId);
            const result = await getCaseDetails(caseId);
            if (result.statusCode != 200) {
                dispatch(
                    setError({
                        errorCode: result.statusCode,
                    })
                );
            }
        }
        const result = await getCurrentPage(pageNumber);
        if (result.statusCode != 200) {
            dispatch(
                setError({
                    errorCode: result.statusCode,
                })
            );
        }
    };

    const onTableChange = (type: any, newState: TableChangeState<any>) => {
        if (type === "pagination") {
            setLoaderEnabled(true);
            setPageNumber(newState.page - 1);
            getCurrentPage(newState.page - 1, newState.sizePerPage);
            let oldUrl =
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname;
            if (caseId) {
                oldUrl += `?caseid=${caseId}&pg=${++newState.page}`;
            } else {
                oldUrl += "?pg=" + ++newState.page;
            }
            window.history.pushState({ path: oldUrl }, "", oldUrl);
        }
        else if (type === "sort") {
            updateSorting(newState.sortField, newState.sortOrder);
        }
    };

    const onPageSizeChange = (pageSize: number) => {
        setPageSize(pageSize);
        setLoaderEnabled(true);
    };

    const rerunSearch = async (id: number) => {
        await getSearchDetails(id);
        navigate('/');
    }

    const backToPreviousPage = () => {
        history.back();
    }

    return (
        <div className="container">
            <Helmet>
                <title>Social E-Profiler - Search List</title>
                <meta name="description" content="Social E-Profiler" />
            </Helmet>
            {isShowError && (
                <div className="row mt-5">
                    <ErrorAlert
                        headerMessage="ListingErrorHeaderMessage"
                        bodyMessage="ListingErrorBodyMessage"
                    />
                </div>
            )}
            {!isShowError && (
                <div className="row mt-5">
                    {caseDetailsName && (
                        <div className="breadcrumb-div listing">
                            <a href="/case" className="breadcrumb-item active">
                                Cases
                            </a>
                            <p className="breadcrumb-seperator-item">/</p>
                            <p className="breadcrumb-item">{caseDetailsName}</p>
                        </div>
                    )}
                    <div className="row title-div">
                        {caseDetailsName && <Col md="auto" className="my-1 cursor-pointer"><span onClick={backToPreviousPage}><BackArrowSvgIcon /></span></Col>}
                        <h2 className={caseDetailsName ? "table-page-title title col my-3 p-0" : "table-page-title title mx-3"}>Search History </h2>
                        <a
                            className="add-btn"
                            href={
                                "/" +
                                (currentCaseId ? "?caseid=" + currentCaseId : "")
                            }
                        >
                            <span className="add-btn-content">
                                <p className="add-btn-text">New Search</p>
                                <img src="/images/add.svg" className="add-btn-icon" alt="" />
                            </span>
                        </a>
                    </div>

                    {
                        <SearchListTable
                            resultsUrl="/search-result"
                            isLoading={isLoading && loaderEnabled}
                            pageData={pageData}
                            onOpenSearchDetails={(id) => openAddModal(id)}
                            page={pageNumber + 1}
                            sizePerPage={pageSize}
                            totalSize={totalCount}
                            onPageSizeChange={onPageSizeChange}
                            onTableChange={onTableChange}
                            onSearch={onSearchChanged}
                            onRerunSearch={rerunSearch} />
                    }
                    <Modal show={showAddModal} onHide={closeAddModal} size="lg">
                        <div className="searc-details-modal">
                            <Modal.Body>
                                <SearchDetails data={searchDetails} onCloseModal={closeAddModal} onRerunSearch={rerunSearch} />
                            </Modal.Body>
                        </div>
                    </Modal>
                </div>
            )}
            <TailSpin
                wrapperClass="cover-spin"
                visible={isLoading && loaderEnabled}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
        </div>
    );
};
export default SearchList;
