import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import BootstrapTable, {
    ColumnDescription
} from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import usePendingRegistrationsViewModel from "../../view-model/admin/PendingRegistrationsViewModel_new";
import { Link } from "react-router-dom";


const PendingRegistrations: React.FC = () => {

    const {
        requests,
        pageIndex,
        pageSize,
        totalCount,
        isLoading,
        isShowError,
        errorMessages,

        getData,
        onPageSizeChange,
        onTableChange
    } = usePendingRegistrationsViewModel();

    useEffect(() => {
        getData();
    }, [pageIndex, pageSize]);


    const columns: ColumnDescription[] = [
        {
            dataField: "email",
            text: "Email",
            formatter: (cell, row, i) => (
                <Link to={`/admin/pendingRegistration/${row.id}`} className="username-link">{row.email}</Link>
            ),
        },
        {
            dataField: "signupDate",
            text: "Registration Date",
            formatter: (cell, row) => new Date(row.signupDate).toLocaleDateString(),
        },
        {
            dataField: "firstName",
            text: "First Name",
        },
        {
            dataField: "lastName",
            text: "Last Name",
        },
        {
            dataField: "employerName",
            text: "Employer Name",
        }
    ];


    return (
        <div className="container">
            <Helmet>
                <title>Social E-Profiler - Pending Registrations</title>
                <meta name="description" content="Social E-Profiler" />
            </Helmet>
            <div className="row mt-5 pending-registration-container">
                <div className="admin-page-title">Pending Registrations</div>
                <div className="table-style mt-4">
                    <BootstrapTable
                        keyField="searchId"
                        columns={columns}
                        data={requests}
                        bordered={false}
                        headerClasses="header-tr"
                        remote
                        pagination={paginationFactory({
                            pageStartIndex: 1,
                            page: pageIndex + 1,
                            sizePerPage: pageSize,
                            totalSize: totalCount,
                            firstPageText: "First",
                            prePageText: "Back",
                            nextPageText: "Next",
                            lastPageText: "Last",
                            nextPageTitle: "First page",
                            prePageTitle: "Pre page",
                            firstPageTitle: "Next page",
                            lastPageTitle: "Last page",
                            onSizePerPageChange: onPageSizeChange,
                            sizePerPageList: [
                                { text: "10", value: 10 },
                                { text: "25", value: 25 },
                                { text: "50", value: 50 },
                                { text: "100", value: 100 },
                            ]
                        })}
                        onTableChange={onTableChange}
                    />
                </div>
            </div>
        </div>
    );
};
export default PendingRegistrations;
