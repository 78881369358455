import React from "react";
import { Col, Row } from "react-bootstrap";
import CustomInput from "../CustomInput";
import { emailRegex } from "../../../infrastructure/lookups/RegEx";
import { CompanyChildComponentProps } from "../../views/admin/AddUpdateCompany_new";


const SalesRepresentative: React.FC<CompanyChildComponentProps> = ({ control }) => {


    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Ontellus Sales Representative</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating">
                <CustomInput
                    name="salesRepresentitaveName"
                    placeholder="Name"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Name can not be empty"
                />
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="salesRepresentitaveEmail"
                    placeholder="Email"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Email can not be empty"
                    pattern={{ value: emailRegex, message: "Email is not valid" }}
                />
            </Col>
        </Row>
    </>
};

export default SalesRepresentative;
