import React, { useContext, useState } from "react";
import { Button, Col, Modal, Row } from 'react-bootstrap';
import SocialResultModel from "../../../../domain/entities/searchoutput/SocialResultModel";
import HomeViewModelContext from "../../../../infrastructure/Contexts/HomeViewModelContext";
import InternetSearchSvgIcon from "../../svg-icons/InternetSearchSvgIcon";
import SocialResultsModal from "./SocialResultsModal";

export type Props = {
    result: SocialResultModel;
    platformIconUrl: string;
    platformName: string;
    isDeleted?: boolean;
}

const SocialSearchResult: React.FC<Props> = ({ result, platformIconUrl, platformName, isDeleted }: Props) => {
    const [isShowRemoveModal, showRemoveModal] = useState(false);
    const [selectedSocialResultId, setSelectedSocialResultId] = useState(0);
    const [isShowRestoreModal, showRestoreModal] = useState(false);
    const vm = useContext(HomeViewModelContext);


    return (
        <Row className="social-result-card box-shadow bg-white">
            <Row className="m-0 p-0 align-items-center">
                <Col className="p-0"></Col>
                <Col xs={"auto"} className="header-text gaia p-0">
                    <SocialResultsModal isDeleteAction={!isDeleted} result={result}/>
                </Col>
            </Row>
            <Row className="my-2">
                <Col className={`platform col-auto ${platformName?.replaceAll(" ", "-")}`}>
                    <img
                        className="platform-icon p-0 me-1"
                        src={platformIconUrl}
                        alt="img"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = "/icons/default-social-icon.svg";
                        }}
                    />
                    {platformName ?? result.url.split('.com')[0]}
                </Col>
            </Row>
            <hr />
            <Row className="justify-content-center">
                <Button href={result.url} target="_blank" className="social-view-button py-1 facebook-search">
                    <InternetSearchSvgIcon />
                    Search Facebook For Subject
                </Button>
            </Row>
        </Row>
    );
};

export default SocialSearchResult;
