import React, { useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import useVisibility from "../../../infrastructure/hooks/useVisibility";
import TimeSvgIcon from "../svg-icons/TimeSvgIcon";
import WorkSvgIcon from "../svg-icons/WorkSvgIcon";
import HideTabToggle from "./HideTabToggle";
import ResultCount from "./ResultCount";
import SearchResultModel from "../../../domain/entities/searchoutput/SearchResultModel";
import { RootState } from "../../../infrastructure/redux/reducer";


const Work: React.FC = () => {

    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    const dispatch = useDispatch();
    const { visibilityFlags, actions } = useVisibility();

    const handleToggle = () => {
        dispatch(actions.setWorkVisibility(!visibilityFlags.isWorkHidden));
    };

    useEffect(() => {
        if (searchResult?.dataFetched && searchResult?.experiences?.length === 0)
            dispatch(actions.setWorkVisibility(true));
    }, [searchResult?.experiences]);

    return (
        <>
            <Row className="my-3 mx-2 result-header">
                <Col>
                    <ResultCount count={searchResult?.experiences?.length ?? 0} text={searchResult?.isPrintView ? "Work" : null} />
                </Col>
                <Col md="auto">
                    {!searchResult?.isPrintView && <HideTabToggle isHidden={searchResult?.experiences?.length == 0 ? true : visibilityFlags.isWorkHidden} onToggle={handleToggle} />}
                </Col>
            </Row>
            <hr />
            <Row className="work p-0">
                {searchResult?.experiences?.map(e =>
                    <Col className="col-4" key={Math.random()}>
                        <Row className="work-card box-shadow bg-white">
                            <Row className="work-title">
                                <Col className="col-auto img-box mt-2">
                                    <WorkSvgIcon />
                                </Col>
                                {e.companyName}
                            </Row>
                            <hr />
                            <Row className="work-data">
                                <Col className="col-auto p-0">
                                    <img src="/images/settings.jfif" />
                                </Col>
                                <Col xs="auto" className="work-data-header p-0">Role:</Col>
                                <Col className="px-1">{e.titleRole ?? <span className="not-available">Not Available</span>}</Col>
                            </Row>
                            <Row className="work-data">
                                <Col className="col-auto p-0">
                                    <img src="/images/employee-title.jfif" />
                                </Col>
                                <Col xs="auto" className="work-data-header p-0">Title:</Col>
                                <Col className="px-1">{e.titleName ?? <span className="not-available">Not Available</span>}</Col>
                            </Row>
                            <Row className="work-data">
                                <Col className="col-auto img-box-clear mt-2">
                                    <TimeSvgIcon />
                                </Col>
                                <Col xs="auto" className="work-data-header p-0">From:</Col>
                                <Col className="px-1">{e.startYear ?? <span className="not-available">Not Available</span>} </Col>
                                <Col xs="auto" className="work-data-header p-0">To:</Col>
                                <Col className="px-1">{e.endYear ?? <span className="not-available">Not Available</span>} </Col>
                            </Row>

                        </Row>
                    </Col>)}
            </Row>
        </>
    );
};

export default Work;
