import React, { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import SocialPlatformProfileModel from "../../../../domain/entities/searchoutput/SocialPlatformProfileModel";
import useVisibility from "../../../../infrastructure/hooks/useVisibility";
import HideTabToggle from "../HideTabToggle";
import ResultCount from "../ResultCount";
import Filters from "./Filters";
import SocialResultsList from "./SocialResultsList_new";
import { TailSpin } from "react-loader-spinner";
import SearchResultModel from "../../../../domain/entities/searchoutput/SearchResultModel";
import { RootState } from "../../../../infrastructure/redux/reducer";
import useHomeViewModel from "../../../view-model/searchoutput/HomeViewModel_new";
import AddUserSocialProfileDialog from "./AddUserSocialProfileDialog_new";


const SocialResults: React.FC = () => {

    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    const { fetchDeletedSocialResults } = useHomeViewModel();
    const [platformsToDisplay, setPlatformsToDisplay] = useState(searchResult?.socialResults);
    const dispatch = useDispatch();
    const [deletedResults, setDeletedResults] = useState<SocialPlatformProfileModel[] | null>(null);
    const [isLoadingDeletedResults, setIsLoadingDeletedResults] = useState<boolean>(false);
    const { visibilityFlags, actions } = useVisibility();

    const handleToggle = () => {
        dispatch(actions.setSocialResultsVisibility(!visibilityFlags.isSocialResultsHidden));
    };

    const changeDisplayedData = (filtered: SocialPlatformProfileModel[]) => setPlatformsToDisplay(filtered);

    const loadDeletedResults = async () => {
        setIsLoadingDeletedResults(true);
        const fetchedDeletedResults = await fetchDeletedSocialResults(searchResult?.searchRequestId ?? 0);

        if (fetchedDeletedResults?.isSuccess)
            setDeletedResults(fetchedDeletedResults.value);
        else
            setDeletedResults(null);

        setIsLoadingDeletedResults(false);
    };

    const handleTabSwitch = (key: string | null) => {
        if (key === "deletedSocialResults" && deletedResults == null) {
            loadDeletedResults();
        }
    };

    useEffect(() => {
        if (searchResult?.dataFetched && platformsToDisplay?.reduce((partialSum, p) => p.resultsWithData.length + p.resultsWithNoData.length + partialSum, 0) === 0) {
            dispatch(actions.setSocialResultsVisibility(true));
        }
    }, [platformsToDisplay]);

    return (
        <div className="social-results">
            <Row>
                <Row className="my-3 mx-2 result-header">
                    <Col md="auto" className="py-2">
                        <ResultCount count={platformsToDisplay?.reduce((partialSum, p) => p.resultsWithData.length + p.resultsWithNoData.length + partialSum, 0) ?? 0} text={searchResult?.isPrintView ? "Social Results" : null} />
                    </Col>
                    <Col>
                        <AddUserSocialProfileDialog />
                    </Col>
                    <Col md="auto" className="">
                        <Filters searchRequestId={searchResult?.searchRequestId ?? 0} platforms={searchResult?.socialResults ?? []} onFilterChange={changeDisplayedData} />
                    </Col>
                    <Col md="auto">
                        {!searchResult?.isPrintView && <div className="my-2"> <HideTabToggle isHidden={platformsToDisplay?.reduce((partialSum, p) => p.resultsWithData.length + p.resultsWithNoData.length + partialSum, 0) == 0 ? true : visibilityFlags.isSocialResultsHidden} onToggle={handleToggle} /> </div>}
                    </Col>
                </Row>
                <hr />
                <div className="social-results-container">
                    <Tab.Container id="social-results-tabs" defaultActiveKey={"activeSocialResults"} onSelect={handleTabSwitch}>
                        <Nav variant="pills" className="social-nav mb-3">
                            <Nav.Item>
                                <Nav.Link eventKey="activeSocialResults" className="rounded me-3 px-3">
                                    Active Results
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="deletedSocialResults" className="rounded me-3 px-3">
                                    Deleted Results
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="activeSocialResults">
                                <SocialResultsList platforms={platformsToDisplay ?? []} isDeleted={false} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="deletedSocialResults">
                                {!isLoadingDeletedResults && <SocialResultsList platforms={deletedResults ?? []} isDeleted={true} />}
                                {isLoadingDeletedResults && (
                                    <TailSpin
                                        wrapperClass="cover-spin"
                                        visible={isLoadingDeletedResults}
                                        height={50}
                                        width="50"
                                        color="#FF5733"
                                        ariaLabel="loading"
                                    />
                                )}
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </Row>
        </div>
    );
};

export default SocialResults;
