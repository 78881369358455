import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Col, Form, Modal, Row } from "react-bootstrap";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { TailSpin } from "react-loader-spinner";
import BackArrowSvgIcon from "../../components/svg-icons/BackArrow";
import useUserViewPermissionsViewModel, { Approved } from "../../view-model/admin/UserViewPermissionsViewModel_new";


const UserViewPermissions: React.FC = () => {

    const { id } = useParams();

    const {
        userId,
        userName,
        viewPermissions,
        pageIndex,
        pageSize,
        totalCount,
        isLoading,
        isShowError,
        errorMessages,

        onToggle,
        getData,
        onTableChange,
        onPageSizeChange,
        setIsShowError
    } = useUserViewPermissionsViewModel();

    useEffect(() => {
        getData(id ?? '');
    }, [pageSize, pageIndex]);

    const columns: ColumnDescription[] = [
        {
            dataField: "requestedUsersEmails",
            text: "Users Emails to View"
        },
        {
            dataField: "requestDate",
            text: "Request Date",
            formatter: (cell, row) => <span>{new Date(row.requestDate).toLocaleDateString()}</span>
        },
        {
            dataField: "requestStatus",
            text: "Actions",
            formatter: (cell, row) => (<>
                <Form.Switch className="status-toggle" checked={row?.requestStatus == Approved} onChange={() => onToggle(row.requestId)} />
                <span>Approved</span>
            </>)
        }
    ];


    return (
        <div className="container">
            <div>
                <Row>
                    <Col><div className="admin-page-title"><a href="/admin/user"> <BackArrowSvgIcon /> </a> {userName}&apos;s Search Requests View Permissions</div></Col>
                </Row>
                <div className="table-style mt-4">
                    <BootstrapTable
                        keyField="requestId"
                        columns={columns}
                        data={viewPermissions}
                        bordered={false}
                        headerClasses="header-tr"
                        remote
                        pagination={paginationFactory({
                            pageStartIndex: 1,
                            page: pageIndex + 1,
                            sizePerPage: pageSize,
                            totalSize: totalCount,
                            firstPageText: "First",
                            prePageText: "Back",
                            nextPageText: "Next",
                            lastPageText: "Last",
                            nextPageTitle: "First page",
                            prePageTitle: "Pre page",
                            firstPageTitle: "Next page",
                            lastPageTitle: "Last page",
                            onSizePerPageChange: onPageSizeChange,
                            sizePerPageList: [
                                { text: "10", value: 10 },
                                { text: "25", value: 25 },
                                { text: "50", value: 50 },
                                { text: "100", value: 100 },
                            ]
                        })}
                        onTableChange={onTableChange}
                    />
                </div>
            </div>
            <TailSpin
                wrapperClass="cover-spin"
                visible={isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
            <Modal show={isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setIsShowError(false)}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setIsShowError(false)} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );

}

export default UserViewPermissions;