import React from "react";
import { Col, Row } from "react-bootstrap";
import CustomInput from "../CustomInput";
import { CompanyChildComponentProps } from "../../views/admin/AddUpdateCompany_new";
import CustomSelect from "../CustomSelect";
import CustomInputArray from "../CustomInputArray";
import { UseFormGetValues } from "react-hook-form";


const MainInfo: React.FC<CompanyChildComponentProps> = ({ state, control, getValues, displayNetsuiteExternalId }) => {

    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Main Information</Col>
            <Col><hr /></Col>
        </Row>

        {displayNetsuiteExternalId &&
            <Row className="row-style">
                <Col className="mb-30 readonly-property">
                    Netsuite External ID: {state?.netsuiteExternalId}
                </Col>
            </Row>
        }

        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="name"
                    placeholder="Name"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Name can not be empty"
                />
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomSelect
                    name="selectedStatus"
                    control={control}
                    placeholder="Status"
                    label="Status"
                    options={state?.statusList ?? []}
                    isClearable={false}
                />
            </Col>
        </Row>

        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating">
                <CustomInput
                    name="address"
                    placeholder="Address"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Address can not be empty"
                />
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="city"
                    placeholder="City"
                    control={control}
                />
            </Col>
        </Row>

        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomSelect
                    name="companySelectedState"
                    control={control}
                    placeholder="Select State"
                    label="State"
                    options={state?.stateList ?? []}
                />
            </Col>

            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="zipCode"
                    placeholder="Zip Code"
                    control={control}
                    maxLength={5}
                />
            </Col>
        </Row>

        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating">
                <CustomInput
                    type="number"
                    name="pricePerSearch"
                    placeholder="Price/Search ($)"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Price/Search can not be empty"
                />
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomSelect
                    name="selectedInvoiceType"
                    control={control}
                    placeholder="Invoice Type"
                    label="Invoice Type"
                    options={state?.invoiceTypeList ?? []}
                    isClearable={false}
                />
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInputArray
                    arrayName="domains"
                    control={control}
                    placeholder="Domain"
                    getValues={getValues as UseFormGetValues<any>}
                    required
                    hasRequiredSymbol
                    requiredMessage="Domain can not be empty"
                />
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="employerIdentificationNumber"
                    placeholder="Employer Identification Number"
                    control={control}
                />
                <Row>
                    <Col className="input-group-meta form-group mb-30 form-floating first-col-input checkbox-wrapper">
                        <Col md="auto" className="p-0">
                            <CustomInput
                                type="checkbox"
                                name="signedContract"
                                control={control}
                                label="Signed Contract"
                            />
                        </Col>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>
};

export default MainInfo;
