import React, { useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import useVisibility from "../../../infrastructure/hooks/useVisibility";
import AddressSvgIcon from "../svg-icons/AddressSvgIcon";
import EducationSvgIcon from "../svg-icons/EducationSvgIcon";
import TimeSvgIcon from "../svg-icons/TimeSvgIcon";
import HideTabToggle from "./HideTabToggle";
import ResultCount from "./ResultCount";
import SearchResultModel from "../../../domain/entities/searchoutput/SearchResultModel";
import { RootState } from "../../../infrastructure/redux/reducer";


const Education: React.FC = () => {

    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    const dispatch = useDispatch();
    const { visibilityFlags, actions } = useVisibility();
    const handleToggle = () => {
        dispatch(actions.setEducationVisibility(!visibilityFlags.isEducationHidden));
    };
    useEffect(() => {
        if (searchResult?.dataFetched && searchResult?.educations?.length === 0)
            dispatch(actions.setEducationVisibility(true));
    }, [searchResult?.educations]);

    return (
        <>
            <Row className="my-3 mx-2 result-header">
                <Col>
                    <ResultCount count={searchResult?.educations?.length ?? 0} text={searchResult?.isPrintView ? "Education" : null} />
                </Col>
                <Col md="auto">
                    {!searchResult?.isPrintView && <HideTabToggle isHidden={searchResult?.educations?.length == 0 ? true : visibilityFlags.isEducationHidden} onToggle={handleToggle} />}
                </Col>
            </Row>
            <hr />
            <Row className="p-0 education">
                {searchResult?.educations?.map(e =>
                    <Col className="col-4" key={Math.random()}>
                        <Row className="education-card box-shadow bg-white m-0">
                            <Row className="education-title">
                                <Col className="col-auto img-box mt-2">
                                    <EducationSvgIcon />
                                </Col>
                                {e.schoolName}
                            </Row>
                            <hr />
                            <Row className="education-data">
                                <Col className="col-auto img-box-clear mt-2">
                                    <AddressSvgIcon />
                                </Col>
                                <Col xs="auto" className="education-data-header p-0">
                                    Location:
                                </Col>
                                <Col xs={8} className="px-1">
                                    {e.location ?? <span className="not-available">Not Available</span>}
                                </Col>

                            </Row>
                            <Row className="education-data">
                                <Col className="col-auto img-box-clear mt-2">
                                    <TimeSvgIcon />
                                </Col>
                                <Col xs="auto" className="education-data-header p-0">From:</Col>
                                <Col className="px-1">{e.startYear ?? <span className="not-available">Not Available</span>} </Col>
                                <Col xs="auto" className="education-data-header p-0">To:</Col>
                                <Col className="px-1">{e.endYear ?? <span className="not-available">Not Available</span>} </Col>
                            </Row>

                        </Row>
                    </Col>)}
            </Row>
        </>
    );
};

export default Education;
