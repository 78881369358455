import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import useVisibility from "../../../infrastructure/hooks/useVisibility";
import MasonryView from "../MasonryView";
import InternetSearchSvgIcon from "../svg-icons/InternetSearchSvgIcon";
import HideTabToggle from "./HideTabToggle";
import ResultCount from "./ResultCount";
import SearchResultModel from "../../../domain/entities/searchoutput/SearchResultModel";
import { RootState } from "../../../infrastructure/redux/reducer";


const InternetSearches: React.FC = () => {

    const searchResult: SearchResultModel = useSelector((state: RootState) => state.searchResult);
    const dispatch = useDispatch();
    const { visibilityFlags, actions } = useVisibility();

    const handleToggle = () => {
        dispatch(actions.setInternetSearchesVisibility(!visibilityFlags.isInternetSearchesHidden));
    };

    useEffect(() => {
        if (searchResult?.dataFetched && searchResult?.internetSearches?.length === 0) {
            dispatch(actions.setInternetSearchesVisibility(true));
        }
    }, [searchResult?.internetSearches]);

    return (
        <Fragment>
            <Row className="my-3 mx-2 result-header">
                <Col>
                    <ResultCount count={searchResult?.internetSearches?.length ?? 0} text={searchResult?.isPrintView ? "Internet Searches" : null} />
                </Col>
                <Col md="auto">
                    {!searchResult?.isPrintView && <HideTabToggle isHidden={searchResult?.internetSearches?.length == 0 ? true : visibilityFlags.isInternetSearchesHidden} onToggle={handleToggle} />}
                </Col>
            </Row>
            <hr />
            <div className="searches p-0">
                {searchResult?.internetSearches && < MasonryView columnCount={3}>
                    {searchResult?.internetSearches?.map(search =>
                        <div key={Math.random()}>
                            <Col className="search-card box-shadow">
                                <Row>
                                    <Col md="auto" className="img-box ml-3 m-0"><InternetSearchSvgIcon /></Col>
                                    <Col md="auto" className="val url url-text"><a href={search.url}>{search.url}</a></Col>
                                </Row>
                                <hr />
                                <Row className="data-row">
                                    <Col md="auto" className="title">Title:</Col>
                                    <Col md="auto" className="val">{search.searchTitle}</Col>
                                </Row>
                                <Row className="data-row">
                                    <Col md="auto" className="title">Brief:</Col>
                                    <Col md="auto" className="val">{search.searchContent ?? <span className="not-available">Not Available</span>}</Col>
                                </Row>
                            </Col>
                        </div >
                    )}
                </MasonryView>}
            </div>
        </Fragment>
    );
};

export default InternetSearches;
