import React, { useState } from 'react';
import RegisterModel from "../../../domain/entities/auth/structures/RegisterModel";
import FormValidator from "../../utils/FormValidator";
import Result from "../../../domain/common/Result";
import GlbaDppaOptionsModel from "../../../domain/entities/auth/models/GlbaDppaOptionsModel";
import LocalStorageService from "../../utils/LocalStorageService";
import RegistrationEmailDataModel from '../../../domain/entities/auth/structures/RegistrationEmailDataModel';
import AuthApi from '../../../data/auth/AuthApi';
import RegistrationDataApi from '../../../data/registrationData/RegistrationDataApi';
import { HttpClient } from '../../../infrastructure/utils/fetchInterceptor';
import { UseFormSetValue } from 'react-hook-form';
import SearchApi from '../../../data/search/SearchApi';
import PersonInfoEditState from '../../models/PersonInfoEditState';

const DPPA_DEFAULT_ID = 8;
const GLBA_DEFAULT_ID = 7;

function useRegisterViewModel() {

    const [state, setState] = useState<PersonInfoEditState>({} as PersonInfoEditState);

    const [DPPAOptionId, setDPPAOptionId] = useState<number>(DPPA_DEFAULT_ID);
    const [GLBAOptionId, setGLBAOptionId] = useState<number>(GLBA_DEFAULT_ID);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowError, setIsShowError] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [showGlbaDppaWarningModal, setShowGlbaDppaWarningModal] = useState<boolean>(false);
    const [glbaDppaSelectedOptionWarning, setGlbaDppaSelectedOptionWarning] = useState<any>('');
    const [GLBAOptions, setGLBAOptions] = useState<{ id: number, text: string }[]>();
    const [DPPAOptions, setDPPAOptions] = useState<{ id: number, text: string }[]>();

    const authRepository = new AuthApi(new HttpClient());
    const registrationDataRepository = new RegistrationDataApi(new HttpClient());
    const searchApi = new SearchApi(new HttpClient());

    const [displayState, setDisplayState] = useState({ acceptTerms: true, accountInfo: false, glbaDppa: false });

    const onAcceptTermsNext = () => setDisplayState({ acceptTerms: false, accountInfo: true, glbaDppa: false });
    const onAccountInfoNext = () => setDisplayState({ acceptTerms: false, accountInfo: false, glbaDppa: true });
    const onAccountInfoPrev = () => setDisplayState({ acceptTerms: true, accountInfo: false, glbaDppa: false });
    const onGlbaDppaPrevious = () => setDisplayState({ acceptTerms: false, accountInfo: true, glbaDppa: false });

    const resetIsShowError = () => setIsShowError(false);
    const hideCancelModal = () => setShowCancelModal(false);
    const cancelRegistration = () => window.location.href = '/';
    const onCancel = () => setShowCancelModal(true);
    const hideGlbaDppaWarningModal = () => setShowGlbaDppaWarningModal(false);

    const buildGlbaDppaWarningMessage = () => {
        const glbaOptionText = GLBAOptionId == GLBA_DEFAULT_ID ? GLBAOptions?.filter(o => o.id == GLBA_DEFAULT_ID)?.at(0)?.text : '';
        const dppaOptionText = DPPAOptionId == DPPA_DEFAULT_ID ? DPPAOptions?.filter(o => o.id == DPPA_DEFAULT_ID)?.at(0)?.text : '';

        if (glbaOptionText && dppaOptionText)
            setGlbaDppaSelectedOptionWarning(<span>&nbsp; &quot;{glbaOptionText} &quot; &nbsp; and &nbsp; &quot;{dppaOptionText} &quot; &nbsp; options &nbsp;</span>);
        else
            setGlbaDppaSelectedOptionWarning(glbaOptionText ? <span> &nbsp; &quot;{glbaOptionText}&quot; &nbsp; option &nbsp;</span> : <span> &nbsp; &quot;{dppaOptionText} &quot; &nbsp; option &nbsp;</span>);
    }

    const confirmRegister = (data: PersonInfoEditState, event: any) => {
        const continueBtn = event?.nativeEvent?.submitter?.name == "continueBtn";
        if (GLBAOptionId == GLBA_DEFAULT_ID || DPPAOptionId == DPPA_DEFAULT_ID) {
            if (!continueBtn) {
                buildGlbaDppaWarningMessage();
                setShowGlbaDppaWarningModal(true);
                return true;
            }
        }
        return false;
    }

    const register = async (data: PersonInfoEditState, event: any) => {
        try {

            if (confirmRegister(data, event))
                return;

            if(showGlbaDppaWarningModal)
                hideGlbaDppaWarningModal();

            const model = data as unknown as RegisterModel;

            model.phoneNumber = data.phoneNumber.replaceAll(/[.|(|)|\-| ]/g, "");
            model.alternativeEmail = !data.alternativeEmail ? undefined : data.alternativeEmail;
            model.alternativePhoneNumber = !data.alternativePhoneNumber ? undefined : data.alternativePhoneNumber?.replaceAll(/[.|(|)|\-| ]/g, "");
            model.secondaryEmail = !data.secondaryEmail ? undefined : data.secondaryEmail;  
            model.employerState = data.selectedState.label;  
            model.dppaOptionId = DPPAOptionId;
            model.glbaOptionId = GLBAOptionId;
            model.securityQuestionId = data.selectedQuestion.value;
            model.acceptedToSPPVersion = (data.isTermsOfServiceChecked ? + LocalStorageService.getTermsOfServiceVersion() : '') + ',' + (data.isPrivacyPolicyChecked ? LocalStorageService.getPrivacyPolicyVersion() : '');

            setErrorMessages([]);
            setIsLoading(true);

            const result = await authRepository.register(model);

            setIsLoading(false);
            setIsShowError(!result.isSuccess);

            if (!result.isSuccess) {
                if (result.error)
                    setErrorMessages([...errorMessages, result.error]);
                else {
                    if (!result.value)
                        setErrorMessages(["Failed to connect server."]);
                    if (typeof result.value == "string")
                        setErrorMessages([result.value]);
                    else
                        setErrorMessages(["Server Error."]);
                }
            }
            setIsSuccess(result.isSuccess);
        } catch (e: any) {
            setIsLoading(false);
            setErrorMessages([e.message]);
            setIsShowError(true);
        }
    }

    const getData = async () => {
        try {
            setIsLoading(true);

            const questionsResult = await registrationDataRepository.Questions();
            if (questionsResult.isSuccess)
                setState(prevState => ({ ...prevState, questionList: questionsResult?.value?.map(q => ({ value: q.id, label: q.text })) ?? []}));

            const stateResult = searchApi.getStates();
            setState(prevState => ({ ...prevState, stateList: stateResult.map(s => ({ value: s.id, label: s.name })) }));

            const glbaDppaResult = await registrationDataRepository.GetGlbaDppaData();
            if (glbaDppaResult.isSuccess) {
                const value: GlbaDppaOptionsModel = glbaDppaResult.value ?? {} as GlbaDppaOptionsModel;
                setGLBAOptions(value.glbaOptions);
                setDPPAOptions(value.dppaOptions);
            }

            setIsLoading(false);
            setIsShowError(!questionsResult.isSuccess || !glbaDppaResult.isSuccess);
            setErrorMessages([questionsResult.error, glbaDppaResult.error]);
        } catch (e: any) {
            setIsLoading(false);
            setErrorMessages([e.message]);
            setIsShowError(true);
            return Result.Fail(e.message, 500);
        }
    }

    const onEmailChange = async (email: string, setValue: UseFormSetValue<PersonInfoEditState>) => {
        if (FormValidator.isValidEmail(email)) {
            const result = await authRepository.getRegistrationEmailData(email) as Result<RegistrationEmailDataModel>;
            if (result.isSuccess) {
                setValue("isClaimOfficeRequired", result.value?.requiresClaimOffice ?? false);
                if (!result.value?.emailExists && result.value?.companyExists)
                    setValue("emailNotExistAndCompanyExists", true);
                else
                    setValue("emailNotExistAndCompanyExists", false);
            }
        }
    }

    const onGlbaChanged = (id: number) => setGLBAOptionId(id);
    const onDppaChanged = (id: number) => setDPPAOptionId(id);

    return {
        state,
        DPPAOptionId,
        GLBAOptionId,
        isLoading,
        isSuccess,
        isShowError,
        errorMessages,
        GLBAOptions,
        DPPAOptions,
        showCancelModal,
        showGlbaDppaWarningModal,
        glbaDppaSelectedOptionWarning,

        resetIsShowError,
        hideCancelModal,
        cancelRegistration,
        onCancel,
        register,
        getData,
        onEmailChange,
        hideGlbaDppaWarningModal,
        onGlbaChanged,
        onDppaChanged,

        displayState,
        onAcceptTermsNext,
        onAccountInfoNext,
        onAccountInfoPrev,
        onGlbaDppaPrevious
    };
}

export default useRegisterViewModel;
