import React from "react";
import { Col, Row } from "react-bootstrap";
import CustomInput from "../CustomInput";
import { emailRegex, phoneRegex } from "../../../infrastructure/lookups/RegEx";
import { CompanyChildComponentProps } from "../../views/admin/AddUpdateCompany_new";


const BillingPointOfContact: React.FC<CompanyChildComponentProps> = ({ control }) => {


    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Billing Point of Contact</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="same-as-account-poc">
            <Col className="input-group-meta form-group form-floating first-col-input checkbox-wrapper">
                <Col md="auto">
                    <CustomInput
                        type="checkbox"
                        name="billingPocSameAsAccountPoc"
                        control={control}
                        label="Same as Account POC"
                    />
                </Col>
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="billingPointOfContact.firstName"
                    placeholder="First Name"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="First Name can not be empty"
                />
            </Col>

            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="billingPointOfContact.lastName"
                    placeholder="Last Name"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Last Name can not be empty"
                />
            </Col>
        </Row>

        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <CustomInput
                    name="billingPointOfContact.phone"
                    placeholder="Phone"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Phone can not be empty"
                    pattern={{ value: phoneRegex, message: "Phone is not valid" }}
                />
            </Col>

            <Col className="input-group-meta form-group mb-30 form-floating">
                <CustomInput
                    name="billingPointOfContact.email"
                    placeholder="Email"
                    control={control}
                    required
                    hasRequiredSymbol
                    requiredMessage="Email can not be empty"
                    pattern={{ value: emailRegex, message: "Email is not valid" }}
                />
            </Col>
        </Row>
    </>
};

export default BillingPointOfContact;
