import Result from "../../domain/common/Result";
import SearchOutputModel from "../../domain/entities/searchoutput/SearchOutputModel";
import VisibilityFlagsModel from "../../domain/entities/searchoutput/VisibilityFlagsModel";
import SearchOutputRepository from "../../domain/repositories/searchoutput/ISearchOutputRepository";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";

export default class SearchOutputApi implements SearchOutputRepository {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }
    downloadPdfReport(id: number): Promise<Result<Blob>> {
        const requestOptions = {
            method: "GET",
            responseType: "blob",
        };
        return this.httpClient
            .httpFetch(`/api/searchoutput/DownloadPdf/${id}`, requestOptions)
            .then(async (res) => {
                if (res.status === 200)
                    return Result.OkWithValue<Blob>(await res.blob());
                return Result.FailWithValue<Blob>(
                    "Server Error",
                    new Blob(),
                    res.status
                );
            })
            .catch((e) => {
                return Result.FailWithValue<Blob>(e.message, new Blob(), 500);
            });
    }

    downloadExcelReport(
        id: number,
        flags: VisibilityFlagsModel
    ): Promise<Result<Blob>> {
        const requestOptions = {
            method: "GET",
            responseType: "blob" as const,
        };

        const queryParams = new URLSearchParams({
            isAddressHidden: String(flags.isAddressHidden),
            isEducationHidden: String(flags.isEducationHidden),
            isPhonesHidden: String(flags.isPhonesHidden),
            isWorkHidden: String(flags.isWorkHidden),
            isRelativesHidden: String(flags.isRelativesHidden),
            isEmailsHidden: String(flags.isEmailsHidden),
            isInternetSearchesHidden: String(flags.isInternetSearchesHidden),
            isSocialResultsHidden: String(flags.isSocialResultsHidden),
        }).toString();

        return this.httpClient
            .httpFetch(
                `/api/searchoutput/DownloadSearchOutputExcelFile/${id}?${queryParams}`,
                requestOptions
            )
            .then(async (res) => {
                if (res.status === 200)
                    return Result.OkWithValue<Blob>(await res.blob());
                return Result.FailWithValue<Blob>(
                    "Server Error",
                    new Blob(),
                    res.status
                );
            })
            .catch((e) => {
                return Result.FailWithValue<Blob>(e.message, new Blob(), 500);
            });
    }

    get(id: number): Promise<Result<SearchOutputModel>> {
        const requestOptions = {
            method: "GET",
        };

        return this.httpClient
            .httpFetch(`/api/searchoutput/get/${id}`, requestOptions)
            .then(async (res) => {
                if (res.status === 200)
                    return Result.OkWithValue<SearchOutputModel>(await res.json());
                return Result.FailWithValue<SearchOutputModel>(
                    "Server Error",
                    {} as SearchOutputModel,
                    res.status
                );
            })
            .catch((e) => {
                return Result.FailWithValue<SearchOutputModel>(
                    e.message,
                    {} as SearchOutputModel,
                    500
                );
            });
    }

    softDeleteEmail(id: number): Promise<Result<string>> {
        const requestOptions = {
            method: 'DELETE'
        };
        return this.httpClient.httpFetch(`/api/email/${id}`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<string>(await res.json());
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }
}
