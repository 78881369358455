import VisibilityFlagsModel from '../../../domain/entities/searchoutput/VisibilityFlagsModel';
import * as VisibiltyActions from '../actions/VisibiltyActions';

const initialState: VisibilityFlagsModel = {
    isAddressHidden: false,
    isEducationHidden: false,
    isPhonesHidden: false,
    isWorkHidden: false,
    isRelativesHidden: false,
    isEmailsHidden: false,
    isInternetSearchesHidden: false,
    isSocialResultsHidden: false,
};

const visibilityReducer = (state = initialState, action: { type: string; payload?: boolean }): VisibilityFlagsModel => {

    switch (action.type) {
        case VisibiltyActions.SET_ADDRESS_VISIBILITY:
            return { ...state, isAddressHidden: action.payload ?? state.isAddressHidden };

        case VisibiltyActions.SET_EDUCATION_VISIBILITY:
            return { ...state, isEducationHidden: action.payload ?? state.isEducationHidden };
        case VisibiltyActions.SET_EMAILS_VISIBILITY:
            return { ...state, isEmailsHidden: action.payload ?? state.isEmailsHidden };
        case VisibiltyActions.SET_PHONES_VISIBILITY:
            return { ...state, isPhonesHidden: action.payload ?? state.isPhonesHidden };

        case VisibiltyActions.SET_WORK_VISIBILITY:
            return { ...state, isWorkHidden: action.payload ?? state.isWorkHidden };
        case VisibiltyActions.SET_RELATIVES_VISIBILITY:
            return { ...state, isRelativesHidden: action.payload ?? state.isRelativesHidden };
        case VisibiltyActions.SET_INTERNET_SEARCHES_VISIBILITY:
            return { ...state, isInternetSearchesHidden: action.payload ?? state.isInternetSearchesHidden };

        case VisibiltyActions.SET_SOCIAL_RESULTS_VISIBILITY:
            return { ...state, isSocialResultsHidden: action.payload ?? state.isSocialResultsHidden };

        default:
            return state;
    }
};

export const setAddressVisibility = (isHidden: boolean) => ({
    type: VisibiltyActions.SET_ADDRESS_VISIBILITY,
    payload: isHidden,
});

export const setEducationVisibility = (isHidden: boolean) => ({
    type: VisibiltyActions.SET_EDUCATION_VISIBILITY,
    payload: isHidden,
});

export const setEmailsVisibility = (isHidden: boolean) => ({
    type: VisibiltyActions.SET_EMAILS_VISIBILITY,
    payload: isHidden,
});

export const setPhonesVisibility = (isHidden: boolean) => ({
    type: VisibiltyActions.SET_PHONES_VISIBILITY,
    payload: isHidden,
});

export const setWorkVisibility = (isHidden: boolean) => ({
    type: VisibiltyActions.SET_WORK_VISIBILITY,
    payload: isHidden,
});

export const setRelativesVisibility = (isHidden: boolean) => ({
    type: VisibiltyActions.SET_RELATIVES_VISIBILITY,
    payload: isHidden,
});

export const setInternetSearchesVisibility = (isHidden: boolean) => ({
    type: VisibiltyActions.SET_INTERNET_SEARCHES_VISIBILITY,
    payload: isHidden,
});

export const setSocialResultsVisibility = (isHidden: boolean) => ({
    type: VisibiltyActions.SET_SOCIAL_RESULTS_VISIBILITY,
    payload: isHidden,
});

export default visibilityReducer;
