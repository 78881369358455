import React, { useState } from "react";
import { Row, Col } from 'react-bootstrap';
import SocialResultModel from "../../../../domain/entities/searchoutput/SocialResultModel";
import SocialResultsModal from "./SocialResultsModal_new";

export type Props = {
    results: SocialResultModel[];
    platformIconUrl: string;
    platformName: string;
    isDeleted?: boolean;
}

const SocialResultsWithNoData: React.FC<Props> = ({ results, platformIconUrl, platformName, isDeleted }: Props) => {

    const [isShowModal, setShowModal] = useState(false);
    const [selectedSocialResultId, setSelectedSocialResultId] = useState(0);
    const [actionType, setActionType] = useState<"remove" | "restore">("remove");

    const showModal = (id: number, type: "remove" | "restore") => {
        setShowModal(true);
        setSelectedSocialResultId(id);
        setActionType(type);
    }

    return (
        <Row className="social-result-card box-shadow bg-white">
            {results[0].rulesBasedValidation && (
                <Row className="m-0 p-0 align-items-center">
                    <Col className="p-0">
                        <span className="social-identifiers-header col-auto p-0 me-1">Identifiers:</span>
                        <span className="social-identifiers col-auto p-0">{results[0].rulesBasedValidation}</span>
                    </Col>
                </Row>
            )}

            <Row className="my-2">
                <Col className={`platform col-auto ${platformName?.replaceAll(" ", "-")}`}>
                    <img
                        className="platform-icon p-0 me-1"
                        src={platformIconUrl}
                        alt="img"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = "/icons/default-social-icon.svg";
                        }}
                    />
                    {platformName ?? results[0].url.split('.com')[0]}
                </Col>
            </Row>

            {results.map(r => (
                <Row className="social-result-no-data my-2" key={r.id}>
                    <Col>
                        <a href={r.url.startsWith("http") ? r.url : "https://" + r.url} target="_blank" rel="noopener noreferrer">{r.url}</a>
                    </Col>
                    <Col xs={"auto"} className="header-text gaia p-0">
                        <SocialResultsModal isDeleteAction={!isDeleted} result={r} />
                    </Col>
                </Row>
            ))}
        </Row>
    );
}

export default SocialResultsWithNoData;
